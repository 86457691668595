import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EmsSub from './EMS/EmsSub';
import Emsmain from './EMS/EmsMain';
import EnergyMeter from './EMS/EnergyMeter';
import EMSGroups from './EMS/EmsGroups';
import SubGroup from './EMS/subgroup';
import EmsSubGroup from './EMS/EmsSubGroup';
import Reports from './EMS/Reports';
import Alerts from './EMS/Alerts';
import UsersGroups from './EMS/UsersGroups';
import Settings from './EMS/Settings';
import Groupportal from './EMS/Groupportal';
import PeakTimings from './EMS/PeakTimings';
import Productionreport from './EMS/Productionreport';
import Alertssettings from './EMS/Alertssettings';
import MachineList from './EMS/Machinelist';
import MachineGroups from './EMS/Machinegroups';
import ProductionDetails from './EMS/Productiondetails';
import AlertReports from './EMS/Alertreport';
import ReportCard from './EMS/Reportcard';
import ProtectedRoute from './ProtectedRoute';
import EnergyData from './EMS/EnergyData';

const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="/EMS/dashboard" element={<ProtectedRoute><Emsmain /></ProtectedRoute>} />
      <Route path="/EMS/emssub" element={<ProtectedRoute><EmsSub /></ProtectedRoute>} />
      <Route path="/EMS/EmsSubGroup" element={<ProtectedRoute><EmsSubGroup /></ProtectedRoute>} />
      <Route path="/EMS/energymeter" element={<ProtectedRoute><EnergyMeter /></ProtectedRoute>} />
      <Route path="/EMS/energydata" element={<ProtectedRoute><EnergyData /></ProtectedRoute>} />
      <Route path="/EMS/report" element={<ProtectedRoute><ReportCard /></ProtectedRoute>} />
      <Route path="/EMS/emsgroups" element={<ProtectedRoute><EMSGroups /></ProtectedRoute>} />
      <Route path="/EMS/group-portal" element={<ProtectedRoute><Groupportal /></ProtectedRoute>} />
      <Route path="/EMS/subgroup" element={<ProtectedRoute><SubGroup /></ProtectedRoute>} />
      <Route path="/EMS/machine" element={<ProtectedRoute><MachineList /></ProtectedRoute>} />
      <Route path="/EMS/groups" element={<ProtectedRoute><MachineGroups /></ProtectedRoute>} />
      <Route path="/EMS/productiondetails" element={<ProtectedRoute><ProductionDetails /></ProtectedRoute>} />
      <Route path="/EMS/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
      <Route path="/EMS/productionreports" element={<ProtectedRoute><Productionreport /></ProtectedRoute>} />
      <Route path="/EMS/alertreports" element={<ProtectedRoute><AlertReports /></ProtectedRoute>} />
      <Route path="/EMS/alerts" element={<ProtectedRoute><Alerts /></ProtectedRoute>} />
      <Route path="/EMS/alertssettings" element={<ProtectedRoute><Alertssettings /></ProtectedRoute>} />
      <Route path="/EMS/usersgroups" element={<ProtectedRoute><UsersGroups /></ProtectedRoute>} />
      <Route path="/EMS/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
      <Route path="/EMS/peaktimings" element={<ProtectedRoute><PeakTimings /></ProtectedRoute>} />
    </Routes>
  );
};

export default RoutesComponent;
