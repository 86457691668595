import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseURL from '../assets/contants/BaseURL';
import '../scss/Resetform.scss';

function ResetTokenForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [token, setResetToken] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email || !token) {
      navigate('/resetpassword');
      return;
    }

    const requestBody = {
      email,
      token,
    };

    try {
      fetch(`${BaseURL}Userauth/validate-reset-token/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
        .then(response => {
          if (!response.ok) {
            setError('Invalid token or email!');
            throw new Error('Reset failed');
          }
          return response.json();
        })
        .then(data => {
          if (data.message === "Valid token") {
            sessionStorage.setItem('reset_token', token);
            console.log(token)
            navigate('/resetpassword');
          } else {
            setError('Invalid token');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  const navigateToSignIn = () => {
    navigate('/login'); 
  };

  const containerStyle = {
    maxWidth: '500px', 
    width:'500px', 
    margin: '50px auto', 
    padding:'20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#fff',
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#0073e6',
    marginBottom: '20px',
  };

  const inputStyle = {
    width: '70%',
    padding: '12px 12px 12px 35px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    outline: 'none',
    position: 'relative',
  };

  const submitBtnStyle = {
    width: '50%',
    padding: '12px',
    backgroundColor: '#f0c14b',
    color: '#111',
    border: '1px solid #a88734',
    borderRadius: '50px', 
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '20px',
  };

  const switchScreenStyle = {
    marginTop: '20px',
    fontSize: '14px',
    color: '#555',
  };

  const signInTextStyle = {
    color: '#0073e6', 
    cursor: 'pointer', 
    textDecoration: 'underline',
  };

//   const backgroundStyle = {
//     backgroundImage: 'url("https://images.unsplash.com/photo-1517336714731-489689fd1ca8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8MTY0MjY1Mzg4Ng&ixlib=rb-1.2.1&q=80&w=1080")',
//     backgroundSize: 'cover',
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   };

  return (
    <div className="live-background">
      <div style={containerStyle}>
        <h2 style={headerStyle}>Reset Token</h2>

        {error && <p style={{ color: 'red', fontSize: '14px', marginTop: '10px' }}>{error}</p>}

        <form onSubmit={handleSubmit}>

         
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="text"
              placeholder="Enter reset token"
              style={inputStyle}
              value={token}
              onChange={(e) => setResetToken(e.target.value)}
              required
            />
            <i className="fa fa-lock" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>

          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="email"
              placeholder="Enter your email"
              style={inputStyle}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <i className="fa fa-user" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>


          <button type="submit" style={submitBtnStyle}>Submit</button>
        </form>

        <div style={switchScreenStyle}>
          <span onClick={navigateToSignIn} style={signInTextStyle}>Sign In</span>
        </div>
      </div>
    </div>
  );
}

export default ResetTokenForm;
