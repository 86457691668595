import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/dashboard.scss';
import Header from '../components/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup, faSitemap } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import Spinner from '../components/Spinner'

const Groupportal = () => {
    const navigate = useNavigate();
    const [groupData, setGroupData] = useState([]);
    const [mainMeterData, setMainMeterData] = useState(null);
    // const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    // const itemsPerPage = 10;

    useEffect(() => {
        const fetchMainMeterData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BaseURL}data/group-meter-details/`);
                const data = response.data;
                setMainMeterData({
                    meter_name: data['Meter Name'],
                    meter_id: data['Meter ID'],
                    total_meters: data['Total Meter'],
                    ems_groups: data['EMS Groups'],
                    total_kWh: data['Total kWh'],
                    total_cost: data['Total Cost'],
                    total_co2: data['Total CO2'],
                    group_names: data['Group Names'],
                    group_color: data['group_color'],
                });
            } catch (error) {
                console.error("There was an error fetching the group-meter-details data!", error);
            } finally {
                setLoading(false);
            }
        };

        fetchMainMeterData();
    }, []);

    useEffect(() => {
        const fetchGroupData = async () => {
            const today = new Date();
            const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
            const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
            try {
                const response = await axios.post(`${BaseURL}data/group-table/`, {
                    date: formattedDate,
                });
                setGroupData(response.data.groups || []);
            } catch (error) {
                console.error('Error fetching group data:', error);
            }
        };
        if (mainMeterData) {
            fetchGroupData();
        }
    }, [mainMeterData]);

    // const paginate = (array, pageNumber) => {
    //     const startIndex = (pageNumber - 1) * itemsPerPage;
    //     const endIndex = startIndex + itemsPerPage;
    //     return array.slice(startIndex, endIndex);
    // };

    // const handlePageChange = (direction) => {
    //     setCurrentPage((prevPage) => {
    //         const totalPages = Math.ceil((mainMeterData?.group_names?.length || 0) / itemsPerPage);
    //         const newPage = Math.max(1, Math.min(prevPage + direction, totalPages));
    //         return newPage;
    //     });
    // };

    const handleMeterClick = (group_name) => {
        navigate("/EMS/EmsSubGroup", { state: { group_name } });
        window.scrollTo(0, 0);
    };

    const handleMainClick = (type) => {
        if (type === "meters") {
            navigate("/EMS/energymeter");
        } else if (type === "groups") {
            navigate("/EMS/emsgroups");
        }
    };

    const renderWidget = (title, value, index, icon, onClick) => (
        <div
            key={value}
            onClick={onClick}
            style={{
                width: '150px',
                height: '80px',
                margin: '10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                borderRadius: '15px',
                border: '1px solid #ddd',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                position: 'relative',
                textAlign: 'left',
                padding: '15px',
                transition: 'all 0.3s ease',
                cursor: 'pointer'
            }}
            className="widgetss"
        >
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                {icon ? (
                    <FontAwesomeIcon icon={icon} size="3x" style={{ color: '#fff' }} />
                ) : (
                    <div style={{ width: '3rem', height: '3rem' }} />
                )}
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <h4 style={{ margin: 0, fontSize: '0.9rem', fontWeight: 'bold', color: '#fff' }}>{title}</h4>
                    <p style={{ fontSize: '1rem', color: '#fff', fontWeight: '400' }}>{value}</p>
                </div>
            </div>
        </div>
    );

    const renderMachineWidgets = (index) => {
        if (!mainMeterData) return null;
        return (
            <div
                key={index}
                className="machine-widgets"
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '20px',
                }}
            >
                {/* {renderWidget('Meter Name:', mainMeterData.meter_name, null, faTachometerAlt, null, () => handleMainClick())}
                {renderWidget('Meter ID:', mainMeterData.meter_id, index + 1, faIdCard, null, () => handleMainClick())} */}
                {renderWidget('Total Meter:', mainMeterData.total_meters, 1, faLayerGroup, () => handleMainClick('meters'))}
                {renderWidget('EMS Groups:', mainMeterData.ems_groups, 2, faSitemap, () => handleMainClick('groups'))} 
                {/* {renderWidget('kWh (R):', mainMeterData.total_kWh, index + 2, faSun, null, () => handleMainClick())}
                {renderWidget('Cost:', `₹ ${mainMeterData.total_cost}`, index + 3, faIndianRupeeSign, null, () => handleMainClick())}
                {renderWidget('Tons:', mainMeterData.total_co2, index + 4, faHome, null, () => handleMainClick())} */}
            </div>
        );
    };
    const renderWidgets = () => {
        const allGroups = mainMeterData?.group_names || [];
        const allColors = mainMeterData?.group_color || [];
    
        // Create a map to group by color
        const colorGroups = {};
    
        allGroups.forEach((groupName, index) => {
            const group = groupData.find((g) => g.group_name === groupName);
            const groupColor = allColors[index] || '#0093ff';
    
            // Use a default color if the group color is undefined or white
            const effectiveColor = (groupColor === '#FFFFFF' || !groupColor) ? '#0093ff' : groupColor;
    
            // Group by color
            if (!colorGroups[effectiveColor]) {
                colorGroups[effectiveColor] = [];
            }
            colorGroups[effectiveColor].push({ groupName, group, totalKwh: group?.total_kwh, totalCost: group?.total_cost });
        });
    
        // Create an array of colors with defined colors first
        const sortedGroups = Object.entries(colorGroups)
            .filter(([color]) => color !== '#0093ff') // Exclude the default color for now
            .flatMap(([color, groups]) => groups) // Flatten the groups with defined colors
            .concat(colorGroups['#0093ff'] || []); // Add the default color groups at the end
    
        return (
            <div style={{ marginBottom: '20px' }}>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '20px',
                        justifyContent: 'center',
                    }}
                >
                    {sortedGroups.map(({ groupName, group }, index) => {
                        const groupColor = allColors[allGroups.indexOf(groupName)] || '#0093ff';
                        const totalKwh = group ? group.total_kwh : null;
                        const totalCost = group ? group.total_cost : null;
    
                        return (
                            <div
                                key={index}
                                onClick={() => handleMeterClick(groupName)}
                                style={{
                                    width: '400px',
                                    height: '90px',
                                    background: groupColor === '#FFFFFF' || !groupColor ? '#0093ff' : groupColor,
                                    borderRadius: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '20px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    textAlign: 'center',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    cursor: 'pointer',
                                }}
                                className="widget"
                            >
                                <h4
                                    style={{
                                        margin: '5px 0',
                                        color: '#fff',
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                        fontSize: '14px',
                                    }}
                                >
                                    {groupName}
                                </h4>
                                <p
                                    style={{
                                        margin: '0',
                                        color: '#fff',
                                        fontWeight: '600',
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                        fontSize: '12px',
                                    }}
                                >
                                    Total KWh: {totalKwh === null ? (
                                        <span className="table-spinner"></span>
                                    ) : (
                                        totalKwh
                                    )}
                                </p>
                                <p
                                    style={{
                                        margin: '0',
                                        color: '#fff',
                                        fontWeight: '600',
                                        textShadow: '1px 1px 2px rgba(0,0,0,0.5)',
                                        fontSize: '12px',
                                    }}
                                >
                                    Total Cost: Rs {totalCost === null ? (
                                        <span className="table-spinner"></span>
                                    ) : (
                                        totalCost
                                    )}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };



    return (
        <div className="dashboard" style={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif" }}>
            <Header title="GROUP PORTAL" />
            <div className="content-container" style={{ padding: '20px' }}>
                <div className="card" style={{ background: '#fff', borderRadius: '10px' }}>
                    {loading ? (
                        <div className="loading-spinner">
                            <Spinner />
                        </div>
                    ) : (
                        <>
                            {renderMachineWidgets()}
                            <h3 className="dashboard-subtitle">Groups</h3>
                            <div className="group-card">
                                {renderWidgets()}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Groupportal;
