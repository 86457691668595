import React, { useState, useEffect,useRef } from 'react';
import '../scss/Emsmain.scss';
import Chart from 'react-apexcharts';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdFactory } from "react-icons/md";
import Header from '../components/header'
import BaseURL from '../assets/contants/BaseURL';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSpeedometer from "react-d3-speedometer";
import axios from 'axios';
import { Line } from "react-chartjs-2";
import ExcelJS from 'exceljs';
import html2canvas from 'html2canvas';
import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(...registerables, zoomPlugin);

const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const getStatusColor = (status) => {
    if (status === 'Inactive') return '#f61612'; 
    if (status === 'Active') return '#4ded4f'; 
    return 'yellow';
};
const EmsSub = () => {
    const location = useLocation();
    const { meterId, groupName } = location.state || {};
    const navigate = useNavigate();
    const [meterOptions, setMeterOptions] = useState([]);
    const [meterIds, setMeterIds] = useState([]);
    const [selectedMeter, setSelectedMeter] = useState('');
    const [selectedMeterId, setSelectedMeterId] = useState('');
    const [energyData, setEnergyData] = useState({
        today: { kWh: 0, cost: 0, co2: 0.0 },
        yesterday: { kWh: 0, cost: 0, co2: 0.0 },
        current_month: { kWh: 0, cost: 0, co2: 0.0 },
        last_month: { kWh: 0, cost: 0, co2: 0.0 },
    });
    const [details, setDetails] = useState({});
    const [vpn, setVpn] = useState({});
    const [vpp, setVpp] = useState({});
    const [iavg, setIavg] = useState({});
    const [pfAvg, setPfAvg] = useState(0);
    const [pf, setPf] = useState(0);
    const [freq, setFreq] = useState(0);
    const [vthd, setVTHD] = useState({
        VTHD_Avg: 0,
        VHar_R: 0,
        VHar_Y: 0,
        VHar_B: 0,
    });
    const [ithd, setITHD] = useState({
        ITHD_Avg: 0,
        CuHar_R: 0,
        CuHar_Y: 0,
        CuHar_B: 0,
    });
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [dateselectedDate, setdateSelectedDate] = useState(new Date());
    const [timeselectedDate, settimeSelectedDate] = useState(new Date());
    // const [pfAvg, setPfAvg] = useState(null);
    // const [pfValue, setPfValue] = useState(0);
    // const Machinestatus = mainGroupMeters[0]?.status;
    // const [VLL, setVLL] = useState(0);
    // const [VLN, setVLN] = useState(0);
    // const [IAvg, setIAvg] = useState(0);
    const [kwGauge, setKwGauge] = useState([0, 5000]);
    const [KW, setKW] = useState({
        Total: 0,
        R: 0,
        Y: 0,
        B: 0,
    });
    const [kvaGauge, setKvaGauge] = useState([0, 5000]);
    const [KVA, setKVA] = useState({
        Total: 0,
        R: 0,
        Y: 0,
        B: 0,
    });
    const [chartData, setChartData] = useState({
        selected_day: "",
        graph_title: "",
        x_axis_label: "",
        y_axis_label: "",
        bars: [
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
            {
                name: "",
                data: [],
            },
        ],
        time_intervals: [
        ],
    });
    const [dailyData, setDailyData] = useState({
        categories: [],
        kw: [],
        cost: [],
        co2: []
    });
    // const [categorie, setCategories] = useState([]);
    // const [vtdh, setVtdh] = useState(0);
    // const [itdh, setItdh] = useState(0);
    // const [voltageHarmonic, setVoltageHarmonic] = useState({
    //     VHar_R: 0,
    //     VHar_Y: 0,
    //     VHar_B: 0,
    // });
    // const [currentHarmonic, setCurrentHarmonic] = useState({
    //     CuHar_R: 0,
    //     CuHar_Y: 0,
    //     CuHar_B: 0,
    // });
    const [lineChartOptions, setLineChartOptions] = useState({
            chart: {
              type: 'line',
              height: 350,
              toolbar: {
                tools: {
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                  download: false,
                },
              },
            },
            stroke: {
              curve: 'straight',
            },
            xaxis: {
              categories: [],
              labels: {
                show: false,
              },
            },
            yaxis: {
              tickAmount: 3,
              labels: {
                formatter: (value) => {
                    return new Intl.NumberFormat('en-US').format(value);
                },
              },
            },
            grid: {
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            annotations: {
              yaxis: [
                {
                  y: 0,
                  borderColor: '#000000',
                  label: {
                    style: {
                      color: '#000000',
                      background: '#fff',
                    },
                  },
                },
              ],
            },
            series: [
              { name: 'KWH', data: [] },
              { name: 'KVA', data: [] },
            ],
          });

          const [co2lineChartOptions, setCo2LineChartOptions] = useState({
            chart: {
              type: 'line',
              height: 350,
              toolbar: {
                tools: {
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false,
                  download: false,
                },
              },
            },
            stroke: {
              curve: 'straight',
            },
            xaxis: {
              categories: [],
              labels: {
                show: false,
              },
            },
            yaxis: {
              tickAmount: 3,
              labels: {
                formatter: (value) => {
                    return new Intl.NumberFormat('en-US').format(value);
                },
              },
            },
            grid: {
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            annotations: {
              yaxis: [
                {
                  y: 0,
                  borderColor: '#000000',
                  label: {
                    style: {
                      color: '#000000',
                      background: '#fff',
                    },
                  },
                },
              ],
            },
            series: [
              { name: 'KWH', data: [] },
              { name: 'KVA', data: [] },
            ],
          });

      const [monthlyData, setMonthlyData] = useState({
        categories: [],
        kWh: [],
        cost: [],
        co2: [],
    });
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedNewYear, setSelectedNewYear] = useState(new Date().getFullYear());

    const [chartsData, setChartsData] = useState({
        options: {
            chart: {
                id: "timeslot-chart",
                toolbar: {
                    tools: {
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false,
                        download: false,
                    },
                },
                zoom: {
                    enabled: false,
                },
            },
            xaxis: {
                categories: [],
                tooltip: {
                    enabled: true,
                },
            },
            yaxis: {
                title: {
                    text: chartData.y_axis_label,
                },
                labels: {
                    formatter: (value) => {
                        return new Intl.NumberFormat('en-US').format(value);
                    },
                },
            },
            colors: ['#DE3163', '#40E0D0', '#CCCCFF'],
            dataLabels: {
                enabled: false,
            },
            legend: {
                position: 'top',
                horizontalAlign: 'center',
                offsetX: 0,
                offsetY: 0,
            },
            tooltip: {
                y: {
                    formatter: function (value, { seriesIndex }) {
                        if (seriesIndex === 1) {
                            return `Rs ${value.toLocaleString()}`;
                        }
                        return value.toLocaleString();
                    },
                },
            },
        },
        series: [
            {
                name: "Today's KWh",
                data: [],
            },
            {
                name: "Total Cost",
                data: [],
            },
            {
                name: "CO₂",
                data: [],
            },
        ],
    });

    const changevalue=(value)=>{
        if (value === 0) {
            return 0;
          }
          if (value === 1 || value === -1) {
            return 50;
          }
          if (value >= 0 && value <= 1) {
            if (value === 0) {
              return 100; 
            }
            return Math.round(50 + (1 - value) * 50); 
          }
          if (value >= -1 && value <= 0) {
            // return 50 + Math.abs(value) * 50; // Map -1 to -0 linearly to 50 to 100
            return Math.round(value * 50 *-1); 
          }
          return null; 
        };

        const anotherFunction = (value) => {
            const result = changevalue(value);
            return result
          };
        //   anotherFunction(-0.8);
    

    const [tableData, setTableData] = useState([]);
    const [totalKWhr, setTotalKWhr] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [totalCo2, setTotalCo2] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const indiaTime = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
            const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
            const response = await axios.post(`${BaseURL}data/power-with-timings/`, {
              meter_id: selectedMeterId,
              date: formattedDate,
            });
      
            const data = response.data;
            if (data.length === 0) {
                setLineChartOptions((prevState) => ({
                  ...prevState,
                  xaxis: {
                    categories: [],
                  },
                  series: [
                    { name: 'KW', data: [] },
                    { name: 'KVA', data: [] },
                  ],
                }));
              } else {

            const categories = data.map(item => item[0]);
            const kWh = data.map(item => item[1]);
            const kVa = data.map(item => item[2]);
      
            setLineChartOptions((prevState) => ({
              ...prevState,
              xaxis: {
                categories: categories,
              },
              series: [
                { name: 'KW', data: kWh },
                { name: 'KVA', data: kVa },
              ],
            }));
        }
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };
    
        if (selectedMeterId) {
            fetchData();
            const interval = setInterval(fetchData, 120000);
            return () => clearInterval(interval);
        }
      }, [selectedDate, selectedMeterId]);

    useEffect(() => {
        if (groupName) {
            const today = new Date();
            const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
            const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
    
            const data = {
                date: formattedDate,
                group_name: groupName,
            };
    
            axios.post(`${BaseURL}data/meter-table/`, data)
                .then((response) => {
                    const { meter_id, meter_name } = response.data;
                    setMeterOptions(meter_name);
                    setMeterIds(meter_id);
    
                    if (meter_id.length > 0) {
                        const selectedMeterIndex = meter_id.findIndex(id => id === meterId);
                        const MeterId = selectedMeterIndex !== -1 ? meter_id[selectedMeterIndex] : meter_id[0];
                        const selectedMeterId = MeterId;
                        const selectedMeterName = selectedMeterIndex !== -1 ? meter_name[selectedMeterIndex] : meter_name[0];
    
                        setSelectedMeter(selectedMeterName || meter_name[0]);
                        setSelectedMeterId(selectedMeterId);
                    }
                })
                .catch((error) => {
                    console.error("There was an error fetching the meter data:", error);
                });
        }
    }, [groupName, meterId]);

    const handleMeterChange = (event) => {
        const selectedMeterValue = event.target.value;
        setSelectedMeter(selectedMeterValue);
        const selectedIndex = meterOptions.indexOf(selectedMeterValue);
        setSelectedMeterId(meterIds[selectedIndex]);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!selectedMeterId) {
                console.error('Error: selectedMeterId is not defined.');
                return;
            }
            try {
                const indiaTime = new Date(timeselectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}data/peak-timing-consumption/`, {
                    meter_id: selectedMeterId,
                    date: formattedDate
                });
    
                const data = response.data;

                setChartsData((prevState) => ({
                    ...prevState,
                    options: {
                        ...prevState.options,
                        xaxis: {
                            categories: data.time_intervals,
                        },
                    },
                    series: [
                        { name: "Today's KWh", data: data.bars[0].data },
                        { name: "Total Cost", data: data.bars[1].data },
                        { name: "CO₂", data: data.bars[2].data },
                    ],
                }));

                const updatedTableData = data.time_intervals.map((timeSlot, index) => ({
                    Timings: timeSlot,
                    Rate: data.rates[index],
                    TodaysKWhr: data.bars[0].data[index],
                    TotalCost: data.bars[1].data[index],
                    TotalCo2: data.bars[2].data[index],
                }));
    
                setTableData(updatedTableData);
                setTotalKWhr(updatedTableData.reduce((acc, row) => acc + row.TodaysKWhr, 0));
                setTotalCost(updatedTableData.reduce((acc, row) => acc + row.TotalCost, 0));
                setTotalCo2(updatedTableData.reduce((acc, row) => acc + row.TotalCo2, 0));
    
            } catch (error) {
                console.error('Error fetching data:', error.response?.data || error.message);
            }
        };
        if (selectedMeterId) {
            fetchData();
            const interval = setInterval(fetchData, 180000);
            return () => clearInterval(interval);
        }
    }, [timeselectedDate, selectedMeterId]);

    useEffect(() => {
        const fetchEnergyData = async () => {
            try {
                const today = new Date();
                const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}data/energy-data/`, {
                    meter_id: selectedMeterId,
                    date: formattedDate,
                });
                const data = response.data;
                setEnergyData({
                    today: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.today.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.today.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.today.co2.toFixed(2))),
                    },
                    yesterday: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.yesterday.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.yesterday.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.yesterday.co2.toFixed(2))),
                    },
                    current_month: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.current_month.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.current_month.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.current_month.co2.toFixed(2))),
                    },
                    last_month: {
                        kWh: new Intl.NumberFormat().format(parseFloat(data.last_month.kWh.toFixed(2))),
                        cost: new Intl.NumberFormat().format(parseFloat(data.last_month.cost.toFixed(2))),
                        co2: new Intl.NumberFormat().format(parseFloat(data.last_month.co2.toFixed(2))),
                    },
                });
            } catch (error) {
                console.error('Error fetching energy data:', error);
            }
        };
        if (selectedMeterId) {
            fetchEnergyData();
            const interval = setInterval(fetchEnergyData, 180000);
            return () => clearInterval(interval);
        }
    }, [selectedMeterId]);

    useEffect(() => {
        if (meterId) {
            setSelectedMeterId(meterId);
        }
    }, [meterId]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const today = new Date();
                const indiaTime = new Date(today.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}data/meter-data/`, {
                    meter_id: selectedMeterId,
                    date: formattedDate,
                });
                const data = response.data;
                setDetails(data.details);
                setVpn({
                    VLN_Avg: parseFloat((data.vpn?.VLN_Avg || 0).toFixed(2)),
                    V_R: parseFloat((data.vpn?.V_R || 0).toFixed(2)),
                    V_Y: parseFloat((data.vpn?.V_Y || 0).toFixed(2)),
                    V_B: parseFloat((data.vpn?.V_B || 0).toFixed(2)),
                });
                setVpp({
                    VLL_Avg: parseFloat((data.vpp?.VLL_Avg || 0).toFixed(2)),
                    V_RY: parseFloat((data.vpp?.V_RY || 0).toFixed(2)),
                    V_YB: parseFloat((data.vpp?.V_YB || 0).toFixed(2)),
                    V_BR: parseFloat((data.vpp?.V_BR || 0).toFixed(2)),
                });
                setIavg({
                    Cu_Avg: parseFloat((data.iavg?.Cu_Avg || 0).toFixed(2)),
                    Cu_R: parseFloat((data.iavg?.Cu_R || 0).toFixed(2)),
                    Cu_Y: parseFloat((data.iavg?.Cu_Y || 0).toFixed(2)),
                    Cu_B: parseFloat((data.iavg?.Cu_B || 0).toFixed(2)),
                });
                setPfAvg(data.pf_avg);
                setPf(data.PF);
                setFreq(data.freq);
                setVTHD({
                    VHar_R: parseFloat((data.VTHD?.VHar_R || 0).toFixed(2)),
                    VHar_Y: parseFloat((data.VTHD?.VHar_Y || 0).toFixed(2)),
                    VHar_B: parseFloat((data.VTHD?.VHar_B || 0).toFixed(2)),
                    VTHD_Avg: parseFloat((data.VTHD?.VTHD_Avg || 0).toFixed(2)),
                });
    
                setITHD({
                    CuHar_R: parseFloat((data.ITHD?.CuHar_R || 0).toFixed(2)),
                    CuHar_Y: parseFloat((data.ITHD?.CuHar_Y || 0).toFixed(2)),
                    CuHar_B: parseFloat((data.ITHD?.CuHar_B || 0).toFixed(2)),
                    ITHD_Avg: parseFloat((data.ITHD?.ITHD_Avg || 0).toFixed(2)),
                });
                setKwGauge(data.details.kw_gauge || [0, 5000]);
                setKW(data.KW || { Total: 0, R: 0, Y: 0, B: 0 });
                setKvaGauge(data.details.kva_gauge || [0, 5000]);
                setKVA(data.KVA || { Total: 0, R: 0, Y: 0, B: 0 });
            } catch (error) {
                console.error('Error fetching meter data:', error);
            }
        };
        if (selectedMeterId) {
            fetchData();
            const interval = setInterval(fetchData, 120000);
            return () => clearInterval(interval);
        }
    }, [selectedMeterId]);

    // useEffect(() => {
    //     const fetchPFData = async () => {
    //       try {
    //         const indiaTime = new Date(selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
    //         const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
    //         const response = await axios.post(`${BaseURL}data/powerfactor-with-timings/`, {
    //             date: formattedDate,
    //             meter_id: selectedMeterId,
    //           });
    //         const data = response.data;

    //         const timeLabels = data.map(item => item[0]);
    //         const pRData = data.map(item => item[1]);
    //         const pYData = data.map(item => item[2]);
    //         const pBData = data.map(item => item[3]); 
    //         const pAvgData = data.map(item => item[4]);

    //         setLineChartOptions(prevState => ({
    //             ...prevState,
    //             xaxis: {
    //               categories: timeLabels,
    //             },
    //             series: [
    //               { name: 'P_R', data: pRData },
    //               { name: 'P_Y', data: pYData },
    //               { name: 'P_B', data: pBData },
    //               { name: 'P_Avg', data: pAvgData },
    //             ],
    //           }));
    //       } catch (error) {
    //         console.error("Error fetching power factor data:", error);
    //       }
    //     };
    //     if (selectedMeterId) {
    //         fetchPFData();
    //         const intervalId = setInterval(() => {
    //             fetchPFData();
    //         },  180000);
    //         return () => clearInterval(intervalId);
    //     }
    //   }, [selectedDate, selectedMeterId]);

      useEffect(() => {
        const fetchChartData = async () => {
            try {
                const indiaTime = new Date(dateselectedDate.toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }));
                const formattedDate = `${indiaTime.getFullYear()}-${(indiaTime.getMonth() + 1).toString().padStart(2, '0')}-${indiaTime.getDate().toString().padStart(2, '0')}`;
                const response = await axios.post(`${BaseURL}data/hourly-consumption/`, {
                    meter_id: selectedMeterId,
                    date: formattedDate
                });
    
                const data = response.data;

                setChartData({
                    selected_day: data.selected_day,
                    graph_title: data.graph_title,
                    x_axis_label: data.x_axis_label,
                    y_axis_label: data.y_axis_label,
                    bars: [
                        { name: "KWh", data: data.bars[0].data },
                        { name: "cost", data: data.bars[1].data },
                        { name: "co2", data: data.bars[2].data }
                    ],
                    time_intervals: data.time_intervals
                });
            } catch (error) {
                console.error("Error fetching chart data", error);
            }
        };
        if (selectedMeterId) {
            fetchChartData();
            const intervalId = setInterval(() => {
                fetchChartData();
            },  120000);
            return () => clearInterval(intervalId);
        }
    }, [dateselectedDate, selectedMeterId]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${BaseURL}data/daily-consumption/`, {
                    meter_id: selectedMeterId,
                    month: selectedMonth,
                    year: selectedYear
                });

                const { bars, time_intervals } = response.data;
                const kw = bars.find(bar => bar.name === 'kw').data;
                const cost = bars.find(bar => bar.name === 'cost').data;
                const co2 = bars.find(bar => bar.name === 'co2').data;

                setDailyData({
                    categories: time_intervals,
                    kw: kw,
                    cost: cost,
                    co2: co2
                });
            } catch (error) {
                console.error("Error fetching daily consumption data:", error);
            }
        };
        if (selectedMeterId) {
            fetchData();
            const intervalId = setInterval(fetchData, 180000);
            return () => clearInterval(intervalId);
        }
    }, [selectedMonth, selectedYear, selectedMeterId]);

    const handleMonthChange = (e) => setSelectedMonth(parseInt(e.target.value));
    const handleYearChange = (e) => setSelectedYear(parseInt(e.target.value));
    const handleNewYearChange = (e) => setSelectedNewYear(parseInt(e.target.value));


    useEffect(() => {
        const fetchData = async () => {
            axios.post(`${BaseURL}data/monthly-consumption/`, { meter_id: selectedMeterId, year: selectedNewYear })
                .then((response) => {
                    const data = response.data;
                    setMonthlyData({
                        categories: data.time_intervals,
                        kWh: data.bars.find(bar => bar.name === 'kw').data,
                        cost: data.bars.find(bar => bar.name === 'cost').data,
                        co2: data.bars.find(bar => bar.name === 'co2').data,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching monthly consumption data:', error);
                });
        }
        if (selectedMeterId) {
            fetchData();
            const intervalId = setInterval(() => {
                fetchData();
            },  180000);
            return () => clearInterval(intervalId);
        }
    }, [selectedNewYear, selectedMeterId]);


    const [activeTab, setActiveTab] = useState("Hourly");
    const [selectedreportDate, setSelectedreportDate] = useState("");
    const [selectedfromreportDate, setSelectedfromreportDate] = useState("");
    const [selectedtoreportDate, setSelectedtoreportDate] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [hourlyData, setHourlyData] = useState([]);
    const [monthlyfromDate, setmonthlyFromDate] = useState(null);
    const [monthlytoDate, setmonthlyToDate] = useState(null);
    const [hourlyfromdate, setHourlyfromdate] = useState(0);
    const [hourlytodate, setHourlytodate] = useState(0);
    const [dailyreportData, setDailyreportData] = useState([]);
    const [logData, setLogData] = useState([]);
    const [historicalTrend, setHistoricalTrend] = useState(" ");
    const [chartssData, setChartssData] = useState([]);
    const [currentreportPage, setCurrentreportPage] = useState(1);
    const [currentdailyreportPage, setCurrentdailyreportPage] = useState(1);
    const [currentmonthlyreportPage, setCurrentmonthlyreportPage] = useState(1);
    const [rowsPerPage] = useState(10);
    const [currenthourlyPage, setCurrenthourlyPage] = useState(1);
    const [totalhourlyPages, setTotalhourlyPages] = useState(1);
    const [peakTimingData, setPeakTimingData] = useState([]);
    const chartRef = useRef();
    const [loading, setLoading] = useState(false);
    const [histloading, sethistLoading] = useState(false);
    const [monthsData, setMonthsData] = useState([]);
    const [hourlyloading, setHourlyLoading] = useState(false);
    const [dailyloading, setDailyLoading] = useState(false);
    const [monthlyloading, setMonthlyLoading] = useState(false);
    const [paraloading, setParaLoading] = useState(false);
    const [peakloading, setPeakLoading] = useState(false);


    const tabs = ["Hourly", "Daily", "Monthly", "Parametric","Peak Timing", "Historical Trend"];
    const handleMonthlyFromMonthChange = (date) => {
        setmonthlyFromDate(date);
    };

    const handleMonthlyToMonthChange = (date) => {
        setmonthlyToDate(date);
    };

    const handleMonthlyReport = async () => {
        setMonthlyLoading(true);
        try {
            const formatDate = (date) => {
                const month = date.getMonth() + 1;
                const year = date.getFullYear();
                return `${year}-${month < 10 ? '0' + month : month}`;
            };
    
            const formattedFromDate = formatDate(monthlyfromDate);
            const formattedToDate = formatDate(monthlytoDate);
    
            const response = await axios.post(`${BaseURL}data/monthly-report/`, {
                from_month_year: formattedFromDate,
                to_month_year: formattedToDate,
                meter_id: selectedMeterId
            });
    
            const data = response.data;
    
            const formattedData = data.months.map((month, index) => ({
                month,
                startKwh: data.columns[0].data[index],
                endKwh: data.columns[1].data[index],
                totalKwh: data.columns[2].data[index],
                totalCost: data.columns[4].data[index]
            }));

            setMonthsData(formattedData);
            setMonthlyLoading(false);
        } catch (error) {
            console.error("Error fetching monthly report:", error);
            setMonthlyLoading(false);
        }
    };

    const rowsmonthlyPerPage = 10;

    const totalmonthlyPages = Math.ceil(monthsData.length / rowsmonthlyPerPage);

    const indexOfmonthlyLastRow = currentmonthlyreportPage * rowsmonthlyPerPage;
    const indexOfmonthlyFirstRow = indexOfmonthlyLastRow - rowsmonthlyPerPage;
    const currentMonthlyRows = monthsData.slice(indexOfmonthlyFirstRow, indexOfmonthlyLastRow);

    const handlemonthlyPageChange = (newPage) => {
        setCurrentmonthlyreportPage(newPage);
    };

    const handleHistoricalTrendChange = (event) => {
        setHistoricalTrend(event.target.value);
        setChartssData([]);
    };

    const fetchPeakTimings = async () => {
        setPeakLoading(true);
        try {
          const response = await axios.post(`${BaseURL}data/peak-timing-consumption/`, {
            meter_id: selectedMeterId,
            date: selectedreportDate,
          });
    
          const { time_intervals, rates, bars } = response.data;
    
          const mappedData = time_intervals.map((timing, index) => ({
            timing,
            rate: rates[index],
            todaysWh: bars[0]?.data[index] || 0,
            totalCost: bars[1]?.data[index] || 0,
            totalCo2: bars[2]?.data[index] || 0,
          }));
    
          setPeakTimingData(mappedData);
          setPeakLoading(false);
        } catch (error) {
          console.error("Error fetching peak timings:", error);
          setPeakLoading(false);
        }
      };
    
      const handleGetReport = () => {
        if (selectedreportDate) {
          fetchPeakTimings();
        } else {
          alert("Please select both a meter and a date.");
        }
      };

    useEffect(() => {
        const fetchData = async () => {
            if (historicalTrend && selectedreportDate) {
                sethistLoading(true);
                try {
                    const response = await axios.post(`${BaseURL}data/historical-trend/`, {
                        meter_id: selectedMeterId,
                        date: selectedreportDate,
                        parameter: historicalTrend.toLowerCase(),
                    });

                    const { time_intervals, ...parameterValues } = response.data;

                    if (time_intervals.length === 0 || Object.keys(parameterValues).every(key => parameterValues[key].length === 0)) {
                        setChartssData([]);
                        return;
                    }

                    const formattedData = time_intervals.map((time, index) => {
                        const entry = { time };
                        for (const [key, values] of Object.entries(parameterValues)) {
                            entry[key] = values[index];
                        }
                        return entry;
                    });

                    setChartssData(formattedData);
                } catch (error) {
                    console.error("Error fetching historical trend data:", error);
                    setChartssData([]);
                } finally {
                    sethistLoading(false);
                }
            }
        };

        fetchData();
    }, [historicalTrend, selectedreportDate, selectedMeterId]);
    const downloadChartAsPNG = () => {
        setLoading(true);
        if (chartRef.current) {
          html2canvas(chartRef.current).then((canvas) => {
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
            link.download = 'chart.png';
            link.click();
            setLoading(false); 
          });
        }else {
          setLoading(false); 
        }
      };

      const handleGetHourlyReport = async () => {
        if (!hourlyfromdate || !hourlytodate) {
            alert("Please select a From Date and To Date.");
            return;
        }
    
        try {
            setHourlyLoading(true);
    
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeterId,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };

            const response = await axios.post(`${BaseURL}data/hourly-report-pagination/`, payload);

            const { date, columns, time_intervals } = response.data.results;
            const totalPage = response.data["total page"];

            if (!columns || columns.length === 0) {
                throw new Error("Columns data is missing or invalid");
            }

            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];

            const hourlyData = time_intervals.map((interval, index) => ({
                date: date[index],
                timeInterval: interval,
                startKwh: startKwhData[index],
                endKwh: endKwhData[index],
                totalKwh: totalKwhData[index],
                totalCost: totalCostData[index],
            }));
            setHourlyData(hourlyData);
            setTotalhourlyPages(totalPage);
            setCurrenthourlyPage(1);
            setHourlyLoading(false);
        } catch (error) {
            console.error("Error fetching hourly report:", error);
            setHourlyLoading(false);
        }
    };
    
    const handlehourlyPageChange = async (page) => {
        if (page < 1 || page > totalhourlyPages || page === currenthourlyPage) return;
    
        try {
            setHourlyLoading(true);
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeterId,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };
    
            const pageUrl = `${BaseURL}data/hourly-report-pagination/?page=${page}`;
    
            const response = await axios.post(pageUrl, payload);
            const { date, columns = [], time_intervals = [] } = response.data.results || {};
    
            if (!columns.length) {
                throw new Error("Columns data is missing or invalid");
            }
    
            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];
    
            if (time_intervals.length !== date.length) {
                throw new Error("Mismatch between time_intervals and date length");
            }
    
            const newHourlyData = time_intervals.map((interval, index) => ({
                date: date[index],
                timeInterval: interval,
                startKwh: startKwhData[index],
                endKwh: endKwhData[index],
                totalKwh: totalKwhData[index],
                totalCost: totalCostData[index],
            }));
    
            setHourlyData(newHourlyData);
            setCurrenthourlyPage(page);
            setHourlyLoading(false);
        } catch (error) {
            console.error("Error fetching hourly report on page change:", error);
            setHourlyLoading(false);
        }
    };

    const fetchHourlyReportData = async () => {
        if (!hourlyfromdate || !hourlytodate) {
            alert("Please select a From Date and To Date.");
            return null; 
        }
    
        try {
            const fromDate = hourlyfromdate.split("T")[0];
            const fromTime = hourlyfromdate.split("T")[1];
            const toDate = hourlytodate.split("T")[0];
            const toTime = hourlytodate.split("T")[1];
    
            const payload = {
                meter_id: selectedMeterId,
                from_date: fromDate,
                to_date: toDate,
                from_time: fromTime,
                to_time: toTime,
            };
    
            const response = await axios.post(`${BaseURL}data/hourly-report-excel/`, payload);
    
            const { date, columns, time_intervals } = response.data.results || {};
            if (!columns || columns.length === 0 || !time_intervals || !date) {
                throw new Error("Missing or invalid data in the response.");
            }
    
            const startKwhData = columns.find(column => column.name === "start kwh")?.data || [];
            const endKwhData = columns.find(column => column.name === "end kwh")?.data || [];
            const totalKwhData = columns.find(column => column.name === "total kwh")?.data || [];
            const totalCostData = columns.find(column => column.name === "total cost")?.data || [];
    
            return time_intervals.map((interval, index) => ({
                date: date[index] || '',
                timeInterval: interval,
                startKwh: startKwhData[index] || 0,
                endKwh: endKwhData[index] || 0,
                totalKwh: totalKwhData[index] || 0,
                totalCost: totalCostData[index] || 0,
            }));
        } catch (error) {
            console.error("Error fetching hourly report:", error);
            return null;
        }
    };    

    const handleGetDailyReport = async () => {
            setDailyLoading(true);
        try {
            const fromDateString = fromDate.split("T")[0];
            const fromTime = fromDate.split("T")[1];
            const toDateString = toDate.split("T")[0];
            const toTime = toDate.split("T")[1];
            
            const response = await axios.post(`${BaseURL}data/daily-report/`, {
                meter_id: selectedMeterId,
                start_date: fromDateString,
                end_date: toDateString,
                from_time: fromTime,
                to_time: toTime,
            });
    
            const data = response.data;
    
            const formattedData = data.dates.map((date, index) => {
                const fromDateTime = `${date} / ${data.Times[index].split(" to ")[0]}`;
                const toDateTime = `${date} / ${data.Times[index].split(" to ")[1]}`;
    
                return {
                    date,
                    fromDate: fromDateTime,
                    toDate: toDateTime,
                    startKwh: data.columns[0].data[index],
                    endKwh: data.columns[1].data[index],
                    totalKwh: data.columns[2].data[index],
                    totalCost: data.columns[3].data[index]
                };
            });
    
            setDailyreportData(formattedData);
            setDailyLoading(false);
        } catch (error) {
            console.error("Error fetching daily report:", error);
            setDailyLoading(false);
        }
    };

    const rowsdailyPerPage = 10;

    const totaldailyPages = Math.ceil(dailyreportData.length / rowsdailyPerPage);

    const indexOfdailyLastRow = currentdailyreportPage * rowsdailyPerPage;
    const indexOfdailyFirstRow = indexOfdailyLastRow - rowsdailyPerPage;
    const currentDailyRows = dailyreportData.slice(indexOfdailyFirstRow, indexOfdailyLastRow);

    const handledailyPageChange = (newPage) => {
        setCurrentdailyreportPage(newPage);
    };

    const handleGetLogReport = async () => {
        setParaLoading(true);
        try {
            const fromDateString = selectedfromreportDate.split("T")[0];
            const fromTime = selectedfromreportDate.split("T")[1];
            const toDateString = selectedtoreportDate.split("T")[0];
            const toTime = selectedtoreportDate.split("T")[1];
            const response = await axios.post(`${BaseURL}data/logdata-report/`, {
                meter_id: selectedMeterId,
                from_date: fromDateString,
                to_date: toDateString,
                from_time: fromTime,
                to_time: toTime
            });

            const data = response.data;
            const formattedData = data.time.map((time, index) => ({
                datetime: `${selectedreportDate} ${time}`,
                VR: data.vr_data[index],
                VY: data.vy_data[index],
                VB: data.vb_data[index],
                VRY: data.vry_data[index],
                VYB: data.vyb_data[index],
                VBR: data.vbr_data[index],
                IR: data.cu_r_data[index],
                IY: data.cu_y_data[index],
                IB: data.cu_b_data[index], 
                KVA: data.kva_data[index],
                KW: data.kw_data[index],
                PF: data.pf_avg_data[index],
                KWH: data.kwh_data[index],
            }));

            setLogData(formattedData);
            setParaLoading(false);
        } catch (error) {
            console.error("Error fetching log report:", error);
            setParaLoading(false);
        }
    };

    const totalreportPage = Math.ceil(logData.length / rowsPerPage);
    const indexOfLastRow = currentreportPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = logData.slice(indexOfFirstRow, indexOfLastRow);

    const handlePageChange = (page) => {
        setCurrentreportPage(page);
    };
    
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    
        if (tab !== "Hourly") {
            setHourlyData([]);
            setHourlyfromdate(0);
            setHourlytodate(0);
            setCurrenthourlyPage(1);
        }
        if (tab !== "Daily") {
            setDailyreportData([]);
            setFromDate(0);
            setToDate(0);
            setCurrentdailyreportPage(1);
        }
        if (tab !== "Monthly") {
            setMonthsData([]);
            setmonthlyFromDate(0);
            setmonthlyToDate(0);
            setCurrentmonthlyreportPage(1);
        }
        if (tab !== "Parametric") {
            setLogData([]);
            setSelectedfromreportDate(0);
            setSelectedtoreportDate(0);
            setCurrentreportPage(1);
        }
        if (tab !== "Peak Timing") {
            setPeakTimingData([]);
            setSelectedreportDate(0);
        }
    };

    useEffect(() => {
        setHourlyData([]);
        setHourlyfromdate(0);
        setHourlytodate(0);
        setCurrenthourlyPage(1);
        setDailyreportData([]);
        setFromDate(0);
        setToDate(0);
        setCurrentdailyreportPage(1);
        setCurrentmonthlyreportPage(1);
        setMonthsData([]);
        setLogData([]);
        setSelectedreportDate(0);
        setCurrentreportPage(1);
        setPeakTimingData([]);
    }, [selectedMeterId]);
    
      

    const handleExportToExcel = (data, fileName) => {
              const workbook = new ExcelJS.Workbook();
              const worksheet = workbook.addWorksheet('Report');
          
              worksheet.getColumn(1).width = 30;
              worksheet.getColumn(2).width = 20; 
              worksheet.getColumn(3).width = 15; 
              worksheet.getColumn(4).width = 15; 
              worksheet.getColumn(5).width = 15;
              worksheet.getColumn(6).width = 15; 
              worksheet.getColumn(7).width = 15; 
          
              let dateRow;
          
          if (activeTab === "Parametric") {
              dateRow = worksheet.addRow([`Date and Time: ${selectedDate}`]);
              worksheet.mergeCells(dateRow.number, 1, dateRow.number, 14); 
          
              for (let i = 1; i <= 14; i++) { 
                  dateRow.getCell(i).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFD9EAD3' }, 
                  };
              }
          } else if (activeTab === "Hourly") {
              
              dateRow = worksheet.addRow([`From Date: ${hourlyfromdate}, To Date: ${hourlytodate}`]);
              worksheet.mergeCells(dateRow.number, 1, dateRow.number, 6); 
          
              for (let i = 1; i <= 6; i++) { 
                  dateRow.getCell(i).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFD9EAD3' }, 
                  };
              }
    
            }else if (activeTab === "Monthly") {
          
                const formatDate = (date) => {
                    const month = date.getMonth() + 1;
                    const year = date.getFullYear();
                    return `${year}-${month < 10 ? '0' + month : month}`;
                };
        
                const formattedFromDate = formatDate(monthlyfromDate);
                const formattedToDate = formatDate(monthlytoDate);
                dateRow = worksheet.addRow([`From month: ${formattedFromDate}, To month: ${formattedToDate}`]);
                worksheet.mergeCells(dateRow.number, 1, dateRow.number, 6); 
            
                for (let i = 1; i <= 6; i++) { 
                    dateRow.getCell(i).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFD9EAD3' }, 
                    };
                }
          } else if (activeTab === "Daily") {
             
              dateRow = worksheet.addRow([`From Date: ${fromDate}, To Date: ${toDate}`]);
              worksheet.mergeCells(dateRow.number, 1, dateRow.number, 7); 
          
              for (let i = 1; i <= 7; i++) { 
                  dateRow.getCell(i).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFD9EAD3' }, 
                  };
              }
          }
          
          dateRow.font = { bold: true, size: 14 };
          dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
          
          const branchRow = worksheet.addRow(['Branch: Coimbatore','', `Meter ID:GT1_EMS1`, 'Place of Installation: Group Control']);
          branchRow.font = { bold: true, size: 12 };
          branchRow.getCell(1).alignment = { horizontal: 'left' }; 
          branchRow.getCell(3).alignment = { horizontal: 'center' }; 
      
      worksheet.getColumn(3).width = Math.max(worksheet.getColumn(4).width || 0, `Meter ID: GT1_EMS1`.length + 4); 
      
          
          const branchCellRange = activeTab === "Parametric" ? 14 : (activeTab === "Daily" ? 7 : 6);
          for (let i = 1; i <= branchCellRange; i++) {
              branchRow.getCell(i).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFDDDDDD' }, 
              };
          }
          
          
          const titleRow = worksheet.addRow([`${activeTab}`]);
          titleRow.font = { bold: true, size: 16 };
          titleRow.alignment = { horizontal: 'center' };
          
          worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
          
          for (let i = 1; i <= branchCellRange; i++) {
              titleRow.getCell(i).fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FF1E90FF' }, 
              };
          }
          
              if (data.length > 0) {
                  let headers;
                  if (activeTab === "Hourly") {
                      headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
                  } else if (activeTab === "Daily") {
                      headers = ['Date','From Date', 'To Date', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
                  } else if (activeTab === "Parametric") {
                      headers = ['Date Time', 'VR', 'VY', 'VB', 'VRY', 'VYB', 'VBR','IR', 'IY', 'IB', 'KVA', 'KW', 'PF', 'KWH'];
                  }else if (activeTab === "Monthly") {
                    headers = ['Year', 'Month', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
                }
          
                 
                  const headerRow = worksheet.addRow(headers);
                  headerRow.font = { bold: true };
                  
                  
                  headerRow.eachCell((cell, colIndex) => {
                    if (activeTab === "Parametric" && colIndex >= 1 && colIndex <= 14) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' },
                        };
                    } else if (activeTab === "Daily" && colIndex >= 1 && colIndex <= 7) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' },
                        };
                    } else if (colIndex >= 1 && colIndex <= 6) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' },
                        };
                    }
                });
                  
          
                 
                  data.forEach((row, rowIndex) => {
                    const rowData =
                        activeTab === "Hourly"
                            ? [
                                row.date,
                                row.timeInterval,
                                row.startKwh,
                                row.endKwh,
                                row.totalKwh.toFixed(2),
                                row.totalCost.toFixed(2)
                              ]
                            : activeTab === "Daily"
                            ? [
                                row.date,
                                row.fromDate,
                                row.toDate,
                                row.startKwh,
                                row.endKwh,
                                row.totalKwh.toFixed(2),
                                row.totalCost.toFixed(2)
                              ]
                            : activeTab === "Monthly"
                            ? [
                                row.month.split('-')[0],
                                row.month,
                                row.startKwh,
                                row.endKwh,
                                row.totalKwh.toFixed(2),
                                row.totalCost.toFixed(2)
                              ]
                            : [
                                row.datetime,
                                row.VR,
                                row.VY,
                                row.VB,
                                row.VRY,
                                row.VYB,
                                row.VBR,
                                row.IR,
                                row.IY,
                                row.IB,
                                row.KVA,
                                row.KW,
                                row.PF,
                                row.KWH
                              ];
                
                
                    const dataRow = worksheet.addRow(rowData);
                
                    dataRow.eachCell((cell, colIndex) => {
                        let fillColor;
                        if (activeTab === "Parametric" && colIndex >= 1 && colIndex <= 14) {
                            fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                        } else if (activeTab === "Daily" && colIndex >= 1 && colIndex <= 7) {
                            fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF';
                        } else if (colIndex >= 1 && colIndex <= 6) {
                            fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; 
                        }
                        
                        if (fillColor) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fillColor }, 
                            };
                        }
                    });
                });
                
                if (activeTab !== "Parametric") {
                    const totals = calculateTotals(data);
                    const totalsRow = worksheet.addRow(
                        activeTab === "Parametric"
                            ? ['TOTAL', '', '', '', '', '', '', '', '', '', '', '', ' ', totals.totalKwh.toFixed(2)]
                            : activeTab === "Daily"
                                ? ['', '', 'TOTAL', '', '',  totals.totalKwh.toFixed(2), totals.totalCost.toFixed(2)]
                                : ['', '', 'TOTAL', '', totals.totalKwh.toFixed(2), totals.totalCost.toFixed(2)]
                    );
                
                
                    if (activeTab === "Parametric" || activeTab === "Daily") {
                        worksheet.getColumn(7).width = Math.max(10, totals.totalCost.toFixed(2).length + 5);
                    } else {
                        worksheet.getColumn(6).width = Math.max(10, totals.totalCost.toFixed(2).length + 5);
                    }
                    worksheet.getColumn(14).width = Math.max(10, totals.totalKwh.toFixed(2).length + 5);
                
                    totalsRow.getCell(activeTab === "Daily" ? 7 : 14).numFmt = '0.00';
                    if (totals.totalCost) {
                        totalsRow.getCell(activeTab === "Daily" ? 6 : 6).numFmt = '0.00';
                    }
                
                      
                totalsRow.font = { bold: true };
                totalsRow.eachCell((cell, colIndex) => {
                    if (activeTab === "Parametric") {
                        if (colIndex >= 1 && colIndex <= 15) { 
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' },
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    } else if (activeTab === "Daily") {
                        if (colIndex >= 1 && colIndex <= 7) { 
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' },
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    } else {
                        if (colIndex >= 1 && colIndex <= 6) { 
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: 'FFFFEB3B' }, 
                            };
                            cell.alignment = { horizontal: 'right' };
                            cell.border = {
                                top: { style: 'thin' },
                                left: { style: 'thin' },
                                bottom: { style: 'thin' },
                                right: { style: 'thin' },
                            };
                        }
                    }
                });
                }
                      
                    } else {
                  worksheet.addRow(['No data available']).font = { italic: true };
              }
              const footerRow = worksheet.addRow(['Powered by Innospace']);
              footerRow.font = { bold: true, size: 12 };
              footerRow.alignment = { horizontal: 'center', vertical: 'middle' };
              
              
              const footerCellRange = activeTab === "Parametric" ? 14 : activeTab === "Daily"  ? 7 : 6;
              
              
              worksheet.mergeCells(footerRow.number, 1, footerRow.number, footerCellRange);
              
              
              for (let i = 1; i <= footerCellRange; i++) {
                  footerRow.getCell(i).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFDDDDDD' },
                  };
              }
              
          
          
              workbook.xlsx.writeBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: 'application/octet-stream' });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = fileName;
                  a.click();
                  window.URL.revokeObjectURL(url);
              });
          };

        const handleHourlyExportToExcel = async (fileName) => {
            const data = await fetchHourlyReportData();
        
            if (!data) {
                alert("No data available to export.");
                return;
            }
        
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Report');
        
            worksheet.getColumn(1).width = 30;
            worksheet.getColumn(2).width = 20; 
            worksheet.getColumn(3).width = 15; 
            worksheet.getColumn(4).width = 15; 
            worksheet.getColumn(5).width = 15;
            worksheet.getColumn(6).width = 15; 
            worksheet.getColumn(7).width = 15; 
        
            let dateRow;
        
            if (activeTab === "Hourly") {
                dateRow = worksheet.addRow([`From Date: ${hourlyfromdate}, To Date: ${hourlytodate}`]);
                worksheet.mergeCells(dateRow.number, 1, dateRow.number, 6); 
        
                for (let i = 1; i <= 6; i++) { 
                    dateRow.getCell(i).fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: 'FFD9EAD3' }, 
                    };
                }
            }
        
            dateRow.font = { bold: true, size: 14 };
            dateRow.alignment = { horizontal: 'center', vertical: 'middle' }; 
        
            const branchRow = worksheet.addRow(['Branch: Coimbatore','', `Meter ID: ${selectedMeterId}`, 'Place of Installation: Group Control']);
            branchRow.font = { bold: true, size: 12 };
            branchRow.getCell(1).alignment = { horizontal: 'left' }; 
            branchRow.getCell(3).alignment = { horizontal: 'center' }; 
        
            worksheet.getColumn(3).width = Math.max(worksheet.getColumn(4).width || 0, `Meter ID: ${selectedMeterId}`.length + 4); 
        
            const branchCellRange = activeTab === "Parametric" ? 14 : (activeTab === "Daily" ? 7 : 6);
            for (let i = 1; i <= branchCellRange; i++) {
                branchRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FFDDDDDD' }, 
                };
            }
        
            const titleRow = worksheet.addRow([`${activeTab}`]);
            titleRow.font = { bold: true, size: 16 };
            titleRow.alignment = { horizontal: 'center' };
        
            worksheet.mergeCells(titleRow.number, 1, titleRow.number, branchCellRange);
        
            for (let i = 1; i <= branchCellRange; i++) {
                titleRow.getCell(i).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'FF1E90FF' }, 
                };
            }
        
            if (data.length > 0) {
                let headers = ['Date', 'Hour', 'Start WH', 'End WH', 'Total WH', 'Total Cost'];
                const headerRow = worksheet.addRow(headers);
                headerRow.font = { bold: true };
        
                headerRow.eachCell((cell, colIndex) => {
                    if (colIndex >= 1 && colIndex <= 6) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFA500' },
                        };
                    }
                });
        
                data.forEach((row, rowIndex) => {
                    const rowData = [
                        row.date,
                        row.timeInterval,
                        row.startKwh,
                        row.endKwh,
                        row.totalKwh,
                        row.totalCost
                    ];
        
                    const dataRow = worksheet.addRow(rowData);
        
                    dataRow.eachCell((cell, colIndex) => {
                        let fillColor;
                        if (colIndex >= 1 && colIndex <= 6) {
                            fillColor = rowIndex % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF';
                        }
        
                        if (fillColor) {
                            cell.fill = {
                                type: 'pattern',
                                pattern: 'solid',
                                fgColor: { argb: fillColor }, 
                            };
                        }
                    });
                });
        
                const totals = calculateTotals(data);
                const totalsRow = worksheet.addRow(
                    ['', '', 'TOTAL', '', totals.totalKwh, totals.totalCost]
                );
        
                totalsRow.font = { bold: true };
                totalsRow.eachCell((cell, colIndex) => {
                    if (colIndex >= 1 && colIndex <= 6) {
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: 'FFFFEB3B' },
                        };
                        cell.alignment = { horizontal: 'right' };
                        cell.border = {
                            top: { style: 'thin' },
                            left: { style: 'thin' },
                            bottom: { style: 'thin' },
                            right: { style: 'thin' },
                        };
                    }
                });
            }
        
            workbook.xlsx.writeBuffer().then((buffer) => {
                const blob = new Blob([buffer], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            });
        };        


     const handleExportExcel = (data) => {
              const workbook = new ExcelJS.Workbook();
              const worksheet = workbook.addWorksheet('Peak Timing');
          
             
              worksheet.getColumn(1).width = 30;
              worksheet.getColumn(2).width = 20;
              worksheet.getColumn(3).width = 30;
          
              const date = selectedreportDate || new Date().toISOString().split('T')[0];
              const dateRow = worksheet.addRow([`Report Date: ${date}`]);
              worksheet.mergeCells(dateRow.number, 1, dateRow.number, 6);
              dateRow.font = { bold: true, size: 14 };
              dateRow.alignment = { horizontal: 'center', vertical: 'middle' };
          
              [1, 2, 3,4,5,6].forEach((col) => {
                  worksheet.getCell(dateRow.number, col).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FFD9EAD3' }, 
                  };
              });
          
              const branchRow = worksheet.addRow(['Branch: Coimbatore', ' ', ' ','Place of Installation: Group Control']);
              branchRow.font = { bold: true, size: 12 };
              branchRow.getCell(1).alignment = { horizontal: 'left' }; 
              branchRow.getCell(3).alignment = { horizontal: 'center' }; 
          [1, 2, 3, 4, 5, 6].forEach((col) => {
              const cell = worksheet.getCell(branchRow.number, col);
              cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFD9D9D9' },
              };
          });
          
              // Add title row
              const titleRow = worksheet.addRow(['Peak Timing']);
              worksheet.mergeCells(titleRow.number, 1, titleRow.number, 6);
              titleRow.font = { bold: true, size: 16 };
              titleRow.alignment = { horizontal: 'center', vertical: 'middle' };
          
              [1, 2, 3,4,5,6].forEach((col) => {
                  worksheet.getCell(titleRow.number, col).fill = {
                      type: 'pattern',
                      pattern: 'solid',
                      fgColor: { argb: 'FF1E90FF' }, 
                  };
              });
          
              const headers = ['S.No', 'Timing', 'Rate', 'Today\'s Wh', 'Total Cost', 'Total Co2'];
              const headerRow = worksheet.addRow(headers);
              headerRow.font = { bold: true };
              worksheet.getColumn(3).width = 10;
              worksheet.getColumn(4).width = 30; 
              headerRow.eachCell((cell) => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'FFFFA500' }, 
                };
              });
              if (data.length > 0) {
                  data.forEach((row, index) => {
                    const rowData = [index + 1, row.timing, row.rate, row.todaysWh, row.totalCost, row.totalCo2];
                    const dataRow = worksheet.addRow(rowData);
            
                    dataRow.eachCell((cell, colIndex) => {
                      const fillColor = index % 2 === 0 ? 'FFE8EAF6' : 'FFFFFFFF'; // Alternate rows
                      cell.fill = {
                        type: 'pattern',
                        pattern: 'solid',
                        fgColor: { argb: fillColor },
                      };
                      cell.alignment = { horizontal: 'center', vertical: 'middle' }; // Center text
                    });
                  });
                } else {
                  worksheet.addRow(['No data available']).font = { italic: true };
                }
                  const footerRow = worksheet.addRow(['Powered by Innospace']);
                  footerRow.font = { bold: true, size: 12 };
                  footerRow.alignment = { horizontal: 'center', vertical: 'middle' };
          
                
                  worksheet.mergeCells(footerRow.number, 1, footerRow.number, 6);
          
          
                  for (let i = 1; i <= 6; i++) {
                      footerRow.getCell(i).fill = {
                          type: 'pattern',
                          pattern: 'solid',
                          fgColor: { argb: 'FFDDDDDD' }, 
                      };
                  }
          
                  worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
                  cell.alignment = { horizontal: 'center', vertical: 'middle' };
                  });
          
                const reportName = 'Peak_Timing_Report';
                const fileName = `${reportName}_GT1_EMS1_${selectedreportDate}.xlsx`;
            
                
                workbook.xlsx.writeBuffer().then((buffer) => {
                  const blob = new Blob([buffer], { type: 'application/octet-stream' });
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = fileName;
                  a.click();
                  window.URL.revokeObjectURL(url);
                });
              };
        const calculateTotals = (data) => {
            const totalKwh = data.reduce((total, item) => total + item.totalKwh, 0);
            const totalCost = data.reduce((total, item) => total + item.totalCost, 0);
            return { totalKwh, totalCost };
        };

    const colorMapping = {
        R: "red",
        Y: "yellow",
        B: "blue",
        RY: "red",
        YB: "yellow",
        BR: "blue",
        Avg: "green",
        Tot: "orange",
    };
    
    const getColorForParameter = (key) => {
        const suffix = key.split("_").pop();
        return colorMapping[suffix] || "gray";
    };

    const chartsssData = {
        labels: chartssData.map((item) => item.time), 
        datasets: Object.keys(chartssData[0] || {})
          .filter((key) => key !== "time" && key !== "selected_day" && key !== "meter_id" && key !== "parameter") 
          .map((key) => ({
            label: `${key}`,
            data: chartssData.map((item) => item[key]),
            borderColor: getColorForParameter(key),
            borderWidth: 1,
            pointRadius: 0,
            fill: false,
          })),
      };
      
      const chartsssOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: "top",
            labels: {
              filter: (legendItem) =>
                !["selected_day", "meter_id", "parameter"].includes(legendItem.text),
            },
          },
          tooltip: {
            enabled: true,
            mode: "index",
            intersect: false,
            callbacks: {
              title: (tooltipItems) => {
                if (tooltipItems.length) {
                  return tooltipItems[0].label;
                }
                return "";
              },
              label: (tooltipItem) => {
                const datasetLabel = tooltipItem.dataset.label || "";
                const value = tooltipItem.raw.toFixed(2);
                return `${datasetLabel}: ${value}`;
              },
            },
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true,
              },
              mode: "x",
            },
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: "Time",
            },
          },
          y: {
            title: {
              display: true,
              text: historicalTrend,
            },
          },
        },
      };

    function gaugeOptions(type, value, maxValue, unit) {
        return {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    hollow: {
                        size: '70%',
                    },
                    track: {
                        background: '#e7e7e7',
                        strokeWidth: '100%',
                    },
                    dataLabels: {
                        name: {
                            show: true,
                            fontSize: '16px',
                        },
                        value: {
                            show: true,
                            fontSize: '14px',
                            formatter: () => value,
                        },
                    },
                },
            },
            fill: {
                color: '#007acc',
            },
            stroke: {
                lineCap: 'round',
            },
            labels: [`${type} ${unit}`],
            series: [((value || 0) / (maxValue || 100)) * 100],
        };
    }

    const title = pf.Total >= 1 ? "Lead" : "Lag";

          const handleNewDateChange = (date) => {
            setdateSelectedDate(date);
        };

        const handleTimeDateChange = (date) => {
            settimeSelectedDate(date);
        };


          return (
                <div className="dashboard-container">
                <Header title="INDIVIDUAL METER" />
        
                    <div className="card-container" style={{
                        border: '1px solid #ccc', 
                        borderRadius: '10px', 
                        padding: '20px', 
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                        backgroundColor: '#fff',
                        marginTop: '50px',
                        maxWidth: '1000px',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}>
                        <h3 className="dashboard-subtitle"><span onClick={() => navigate(-1)} title={`Navigate to ${meterId || 'this group'}`} style={{color: '#007bff',cursor: 'pointer'}}>{details.group_name} </span>{' / '}<select
                            onChange={handleMeterChange}
                            value={selectedMeter}
                            title="Change meters"
                            style={{
                            border: 'none',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            cursor: 'pointer',
                            outline: 'none',
                            padding: '5px 10px',
                            position: 'relative',
                            color: '#007bff',
                            appearance: 'none',
                            }}
                        >
                            {meterOptions.map((meter, index) => (
                            <option key={index} value={meter} style={{ color: '#007bff' }}>
                                {meter}
                            </option>
                            ))}
                        </select>
                        </h3>
                        <div className="widgets-container" style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '20px', marginBottom: '20px' }}>
                        <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Today's</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.today.kWh} KWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.today.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px' }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.today.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #4caf50, #2b9e36)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Yesterday</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.yesterday.kWh} KWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.yesterday.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.yesterday.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #2196f3, #0b7dda)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Current Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.current_month.kWh} KWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.current_month.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.current_month.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="widget-container" style={{ width: '100%' }}>
                        <div className="widget" style={{ padding: '10px', border: '1px solid #ccc', background: 'linear-gradient(145deg, #f44336, #d32f2f)', borderRadius: '8px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                            <h3>Last Month</h3>
                            <div style={{ display: 'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Units</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.last_month.kWh} KWh</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Cost</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>Rs {energyData.last_month.cost}</p>
                                </div>
                                <div style={{ display: 'table-row' }}>
                                    <p style={{ display: 'table-cell', paddingRight: '10px', textAlign: 'left', color: '#ffffff', paddingBottom: '15px'  }}>Co2</p>
                                    <p style={{ display: 'table-cell', textAlign: 'center' }}>:</p>
                                    <p style={{ display: 'table-cell', paddingLeft: '10px', textAlign: 'right' }}>{energyData.last_month.co2} Tons</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div
                    className="extra-details-row"
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '20px',
                    }}
                >
                   <div
                        className="container-1"
                        style={{
                            flex: 1,
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                        }}
                    >
                        <h4><strong>Details</strong></h4>
                        <table
                            style={{
                                width: '100%',
                                borderCollapse: 'collapse',
                                marginTop: '20px',
                                fontFamily: "'Roboto', sans-serif",
                                backgroundColor: '#fff',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                borderRadius: '10px',
                                overflow: 'hidden',
                            }}
                        >
                            <tbody>
                                {[
                                    [
                                        { label: 'Meter Name', value: details.meter_name || 'N/A' },
                                        { label: 'Meter ID', value: details.meter_id || 'N/A' },
                                    ],
                                    [
                                        {
                                            label: 'Status',
                                            value: (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '80px',
                                                            height: '20px',
                                                            backgroundColor: getStatusColor(details.status),
                                                            borderRadius: '4px',
                                                            marginBottom: '5px',
                                                        }}
                                                    />
                                                </div>
                                            ),
                                        },
                                        { label: 'Group Name', value: details.group_name || 'N/A' },
                                    ],
                                    [
                                        { label: 'Model', value: details.model || 'N/A' },
                                        { label: 'Last Updated', value: details.last_updated || 'N/A' },
                                    ],
                                ].map((row, rowIndex) => (
                                    <tr key={rowIndex} style={{ borderBottom: '1px solid #ddd' }}>
                                        {row.map((item, cellIndex) => (
                                            <td
                                                key={`${rowIndex}-${cellIndex}`}
                                                style={{
                                                    padding: '15px',
                                                    width: '50%',
                                                    backgroundColor: rowIndex % 2 === 0 ? '#f9f9f9' : '#fff',
                                                    textAlign: 'center',
                                                    verticalAlign: 'top',
                                                    transition: 'background-color 0.3s ease',
                                                }}
                                                onMouseEnter={(e) =>
                                                    (e.currentTarget.style.backgroundColor = '#f0f8ff')
                                                }
                                                onMouseLeave={(e) =>
                                                    (e.currentTarget.style.backgroundColor =
                                                        rowIndex % 2 === 0 ? '#f9f9f9' : '#fff')
                                                }
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '5px',
                                                        fontSize: '14px',
                                                        color: '#333',
                                                    }}
                                                >
                                                    {item.label}
                                                </div>
                                                <div
                                                    style={{
                                                        color: '#555',
                                                        fontSize: '13px',
                                                        wordWrap: 'break-word',
                                                    }}
                                                >
                                                    {item.value}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                                <tr>
                                    <td
                                        colSpan="2"
                                        style={{
                                            padding: '15px',
                                            backgroundColor: '#f0f8ff',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            color: '#333',
                                            fontSize: '14px',
                                            borderTop: '1px solid #ddd',
                                        }}
                                    >
                                        <div>
                                            Live KWh : 
                                            <span
                                                style={{
                                                    fontWeight: 'normal',
                                                    marginLeft: '5px',
                                                }}
                                                title={`${(details.wh_unit || 'N/A').toLocaleString()} KWh`}
                                            >
                                                {details.wh_unit ? (details.wh_unit / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 'N/A'} MW
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                        <div
                            className="container-2"
                            style={{
                                flex: 1,
                                border: '1px solid #ccc',
                                borderRadius: '10px',
                                padding: '20px',
                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                backgroundColor: '#f9f9f9',
                            }}
                        >
                            <h4>Co2</h4>
                            <p><MdFactory size={150} color="#007acc" /></p>
                            <p style={{ fontWeight: 'bold' }}>{energyData.today.co2} Tons</p>
                        </div>
    
                        <div
                        className="container-3"
                        style={{
                            flex: 1,
                            border: '1px solid #ccc',
                            borderRadius: '10px',
                            padding: '20px',
                            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#f9f9f9',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                    >
                       <h4>PF Avg</h4>
                       <div
                            style={{
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '-20px',
                                    width: '60px',
                                    height: '20px',
                                    border: '2px solid #4caf50',
                                    borderRadius: '10px',
                                    backgroundColor: '#e8f5e9',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    color: '#4caf50',
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                    marginTop:'5px',
                                }}
                            >
                                {pfAvg.toFixed(2)}
                            </div>
                        <div
                            style={{
                                height: '150px',
                                width: '80px',
                                border: '1px solid #ccc',
                                position: 'relative',
                                backgroundColor: '#f0f0f0',
                                overflow: 'hidden',
                                borderRadius: '15px',
                                marginTop: '20px'
                            }}
                        >
                            <span
                                style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    left: '50%', 
                                    transform: 'translateX(-50%)',
                                    fontSize: '12px',
                                    color: '#333',
                                    zIndex: 2,
                                }}
                            >
                                <p>1.0</p>
                            </span>
                            <div
                                style={{
                                    width: '100%',
                                    height: Math.abs(pfAvg) > 1 ? '100%' : `${(Math.abs(pfAvg) / 1) * 100}%`,
                                    backgroundColor: '#4caf50',
                                    position: 'absolute',
                                    bottom: 0,
                                    transition: 'height 0.3s ease',
                                }}
                            />
                            <span
                                style={{
                                    position: 'absolute',
                                    bottom: '-10px', 
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    fontSize: '12px',
                                    color: '#333',
                                }}
                            >
                                <p>0.0</p>
                            </span>
                        </div>
                    </div>
                            {/* <div
                                style={{
                                    position: 'absolute',
                                    left: '80px',
                                    top: '50%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: '12px',
                                        color: '#333',
                                        marginBottom: '5px',
                                        whiteSpace: 'nowrap',
                                    }}
                                >
                                    {pfAvg}
                                </span>
                                <div
                                    style={{
                                        width: 0,
                                        height: 0,
                                        borderTop: '5px solid transparent',
                                        borderBottom: '5px solid transparent',
                                        borderRight: '10px solid #333',
                                    }}
                                />
                            </div> */}
                            <p><strong>{pfAvg >= 1 ? 'Lead' : 'Lag'}</strong></p>
                        </div>
                    </div>
    
                <div className="gauge-chart-container" >
                    <div className="gauge-card">
                        <h4>Voltage Phase to Neutral (V<sub>PN</sub>)</h4>
                        {vpn?.VLN_Avg !== undefined && (
                            <Chart
                                options={gaugeOptions('VPN', vpn.VLN_Avg.toLocaleString(), details.vpn_gauge?.[1] || 0, 'V')}
                                series={[((vpn.VLN_Avg || 0) / (details.vpn_gauge?.[1] || 100)) * 100]}
                                type="radialBar"
                                height={200}
                            />
                        )}
                        <div className="table-container">
                            <table className="table">
                                <tbody>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>LN</sub></td>
                                        <td className="table-cell">{(vpn.VLN_Avg || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>R</sub></td>
                                        <td className="table-cell">{(vpn.V_R || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>Y</sub></td>
                                        <td className="table-cell">{(vpn.V_Y || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>B</sub></td>
                                        <td className="table-cell">{(vpn.V_B || 0).toLocaleString()} V</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
    
                    <div className="gauge-card">
                        <h4>Voltage Phase to Phase (V<sub>PP</sub>)</h4>
                        {vpp?.VLL_Avg !== undefined && (
                        <Chart
                            options={gaugeOptions('VPP', vpp.VLL_Avg.toLocaleString(), details.vpp_gauge?.[1] || 0, 'V')}
                            series={[((vpp.VLL_Avg || 0) / (details.vpp_gauge?.[1] || 100)) * 100]}
                            type="radialBar"
                            height={200}
                        />
                        )}
                        <div className="table-container">
                            <table className="table">
                                <tbody>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>LL</sub></td>
                                        <td className="table-cell">{(vpp.VLL_Avg || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>RY</sub></td>
                                        <td className="table-cell">{(vpp.V_RY || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>YB</sub></td>
                                        <td className="table-cell">{(vpp.V_YB || 0).toLocaleString()} V</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">V<sub>BR</sub></td>
                                        <td className="table-cell">{(vpp.V_BR || 0).toLocaleString()} V</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
    
                    <div className="gauge-card">
                        <h4>Average Current (I<sub>Avg</sub>)</h4>
                        {iavg?.Cu_Avg !== undefined && (
                        <Chart
                            options={gaugeOptions('IAvg', iavg.Cu_Avg.toLocaleString(), details.i_gauge?.[1] || 0, 'A')}
                            series={[((iavg.Cu_Avg || 0) / (details.i_gauge?.[1] || 100)) * 100]}
                            type="radialBar"
                            height={200}
                        />
                        )}
                        <div className="table-container">
                            <table className="table">
                                <tbody>
                                    <tr className="table-row">
                                        <td className="table-cell">I</td>
                                        <td className="table-cell">{(iavg.Cu_Avg || 0).toLocaleString()} A</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">I<sub>R</sub></td>
                                        <td className="table-cell">{(iavg.Cu_R || 0).toLocaleString()} A</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">I<sub>Y</sub></td>
                                        <td className="table-cell">{(iavg.Cu_Y || 0).toLocaleString()} A</td>
                                    </tr>
                                    <tr className="table-row">
                                        <td className="table-cell">I<sub>B</sub></td>
                                        <td className="table-cell">{(iavg.Cu_B || 0).toLocaleString()} A</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
    
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="gauge-container" style={{ width: '30%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9',marginLeft:'3px' }}>
                        <h4>KW</h4>
                        <div className="speedometer">
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={Math.min(
                                (Math.abs(KW.Total || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                100
                              )}
                            ringWidth={20}
                            customSegmentStops={[
                                0,
                                10,
                                20,
                                30,
                                40,
                                50,
                                60,
                                70,
                                80,
                                90,
                                100,
                              ]}
                            segmentColors={[
                                "#00ff00",
                                "#32cd32",
                                "#adff2f",
                                "#ffff00",
                                "#ffd700",
                                "#ffa500",
                                "#ff8c00",
                                "#ff4500",
                                "#ff0000",
                                "#8b0000",
                            ]}
                            needleColor="#000000"
                            needleTransitionDuration={5000}
                            needleTransition="easeLinear"
                            currentValueText={`${(KW.Total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KW`}
                            textColor="#000000"
                            labelFontSize="0px"
                        />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <div style={{ width: '30%', marginLeft: '-20px' }}>
                                <p>KW<sub>R</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.R || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.R || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KW`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KW<sub>Y</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.Y || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"      
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.Y || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KW`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KW<sub>B</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KW.B || 0) / (kwGauge[1] - kwGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KW.B || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KW`}
                                    textColor="#000000"
                                   labelFontSize="0px"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div className="gauge-container" style={{ width: '30%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9', marginLeft: '5px' }}>
                        <h4>KVA</h4>
                        <div className="speedometer">
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            value={Math.min(
                                (Math.abs(KVA.Total || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                100
                              )}
                            ringWidth={20}
                            customSegmentStops={[
                                0,
                                10,
                                20,
                                30,
                                40,
                                50,
                                60,
                                70,
                                80,
                                90,
                                100,
                              ]}
                            segmentColors={[
                                "#00ff00",
                                "#32cd32",
                                "#adff2f",
                                "#ffff00",
                                "#ffd700",
                                "#ffa500",
                                "#ff8c00",
                                "#ff4500",
                                "#ff0000",
                                "#8b0000",
                            ]}
                            needleColor="#000000"
                            needleTransitionDuration={5000}
                            needleTransition="easeLinear"
                            currentValueText={`${(KVA.Total || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KVA`}
                            textColor="#000000"
                            labelFontSize="0px"
                        />
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                            <div style={{ width: '30%', marginLeft: '-20px' }}>
                                <p>KVA<sub>R</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.R|| 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                        "#ff3d3d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.R || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KVA<sub>Y</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.Y || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                        "#fff03d",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.Y || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                            <div style={{ width: '30%' }}>
                                <p>KVA<sub>B</sub></p>
                                <div className="speedometer1">
                                <ReactSpeedometer
                                    minValue={0}
                                    maxValue={100}
                                    value={Math.min(
                                        (Math.abs(KVA.B || 0) / (kvaGauge[1] - kvaGauge[0])) * 100,
                                        100
                                      )}
                                    ringWidth={10}
                                    width={115}
                                    needleHeightRatio={0.5}
                                    customSegmentStops={[
                                        0,
                                        10,
                                        20,
                                        30,
                                        40,
                                        50,
                                        60,
                                        70,
                                        80,
                                        90,
                                        100,
                                      ]}
                                    segmentColors={[
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                        "#3db3ff",
                                    ]}
                                    needleColor="#000000"
                                    needleTransitionDuration={5000}
                                    needleTransition="easeLinear"
                                    valueTextFontSize="12px"
                                    currentValueText={`${(KVA.B || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KVA`}
                                    textColor="#000000"
                                    labelFontSize="0px"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gauge-container" style={{ width: '30%', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9', marginLeft: '5px', height: '450px' }}>
                        <h4 style={{ paddingBottom: '0px', textAlign: 'center', marginTop: '20px', marginBottom: '50px' }}>PF</h4>
                            <span style={{ position: 'absolute',top : '80px', transform: 'translateX(-50%)' }}><strong>1</strong></span>
                            <div className="speedometer">
                            <ReactSpeedometer
                                id={`speedometer-kwh`}
                                minValue={0}
                                maxValue={100}
                                value={anotherFunction(pf.Total)} 
                                needleColor="#000000"
                                customSegmentStops={[
                                    0,
                                    15,
                                    30,
                                    47.5,
                                    50,
                                    52.5,
                                    70,
                                    85,
                                    100
                                ]}
                                segmentColors={[
                                    "#ff0000",
                                    "#ff8c00",
                                    "#ffd700",
                                    "#00ff00",
                                    "#00ff00",
                                    "#ffd700",
                                    "#ff8c00",
                                    "#ff0000",
                                ]}
                                segments={8}
                                ringWidth={20}
                                width={260}
                                needleHeightRatio={0.95}
                                needleTransitionDuration={1000} 
                                needleTransition="easeQuadInOut" 
                                textColor="#000000"
                                currentValueText={`${pf.Total || 0}`}  
                                valueTextFontSize="14px"
                                labelFontSize="0px"
                            />
                        </div>
                        <div style={{ position: 'absolute', top: '45%', left: '50%', width: '100%', fontSize: '14px', fontWeight: 'bold', transform: 'translateX(-50%)' }}>
                            <span style={{
                                position: 'absolute',
                                left: '10%',
                                transform: 'translateX(-50%) rotate(-80deg)',
                                transformOrigin: 'left center',
                            }}>-0.1</span>
                            <span style={{
                                position: 'absolute',
                                right: '10%',
                                transform: 'translateX(50%) rotate(80deg)',
                                transformOrigin: 'right center',
                            }}>0.1</span>
                        </div>
                        <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', marginTop: '10px' }}>
                            <p><strong>{title}</strong></p>
                        </div>
                    </div>
                </div>
                <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%', gap: '10px' }}>
                                <div 
                                    className="line-chart-card" 
                                    style={{
                                    border: '1px solid #ccc', 
                                    borderRadius: '8px', 
                                    width: '61.5%',  
                                    maxWidth: '600%',  
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                                    backgroundColor: 'white',
                                    // marginLeft:'-40px'
                                    }}
                                >
                                    <h4>Power</h4>
                                    <div
                                    style={{
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    }}
                                >
                                    <label
                                    htmlFor="date-picker"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        color: "#333",
                                        marginRight: "20px",
                                    }}
                                    >
                                    Date:
                                    </label>
                                    <DatePicker
                                    id="date-picker"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="yyyy/MM/dd"
                                    customInput={
                                        <input
                                        style={{
                                            fontSize: "12px",
                                            width: '80px',
                                            padding: "5px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            marginRight: "20px",
                                        }}
                                        />
                                    }
                                    />
                                </div>
                                    <Chart
                                    options={lineChartOptions}
                                    series={lineChartOptions.series}
                                    type="line"
                                    height={250}
                                    width="100%"  
                                    />
                                </div>
                                <div 
                                    className="line-chart-card" 
                                    style={{
                                    border: '1px solid #ccc', 
                                    borderRadius: '8px', 
                                    width: '61.5%',  
                                    maxWidth: '600%',  
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', 
                                    backgroundColor: 'white',
                                    }}
                                >
                                    <h4>Co2</h4>
                                    <div
                                    style={{
                                    alignItems: "center",
                                    marginBottom: "20px",
                                    }}
                                >
                                    <label
                                    htmlFor="date-picker"
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        color: "#333",
                                        marginRight: "20px",
                                    }}
                                    >
                                    Date:
                                    </label>
                                    <DatePicker
                                    id="date-picker"
                                    selected={selectedDate}
                                    onChange={(date) => setSelectedDate(date)}
                                    dateFormat="yyyy/MM/dd"
                                    customInput={
                                        <input
                                        style={{
                                            fontSize: "12px",
                                            width: '80px',
                                            padding: "5px",
                                            border: "1px solid #ccc",
                                            borderRadius: "4px",
                                            marginRight: "20px",
                                        }}
                                        />
                                    }
                                    />
                                </div>
                                    <Chart
                                    options={co2lineChartOptions}
                                    series={co2lineChartOptions.series}
                                    type="line"
                                    height={250}
                                    width="100%"  
                                    />
                                </div>
                            </div>
                <div className="charts-container" style={{ marginTop: '20px', display: 'flex'}}>
                <div
                        style={{
                            width: '100%',
                            // border: '1px solid #ccc',
                            // borderRadius: '10px',
                            // padding: '15px',
                            // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            // backgroundColor: '#f9f9f9',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                        >
                    <div
                        className="chart"
                        style={{
                        width: '88%',
                        height: '210px',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent:'center',
                        position: 'relative',
                        marginLeft: '15px',
                        marginRight: '15px'
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                top: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: '#333',
                                backgroundColor: '#f9f9f9',
                                padding: '5px',
                                borderRadius: '5px',
                            
                            }}
                        >
                            V<sub>THD</sub>
                        </div>
                        <div
                        style={{
                            height: '150px',
                            width: '80px',
                            border: '1px solid #ccc',
                            position: 'relative',
                            backgroundColor: '#f0f0f0',
                            overflow: 'hidden',
                            marginRight: '20px',
                            borderRadius: '15px',
                        }}
                        >
                        <span
                            style={{
                            position: 'absolute',
                            top: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '12px',
                            color: '#333',
                            }}
                        >
                            <p>{vthd.VTHD_Avg}%</p>
                        </span>
                        <div
                            style={{
                            width: '100%',
                            height: `${vthd.VTHD_Avg}%`,
                            backgroundColor: '#4caf50',
                            position: 'absolute',
                            bottom: 0,
                            transition: 'height 0.3s ease',
                            }}
                        />
                        <span
                            style={{
                            position: 'absolute',
                            bottom: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '12px',
                            color: '#333',
                            }}
                        >
                            <p>0%</p>
                        </span>
                        </div>
    
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '35px', marginRight: '5px' }}>V<sub>R</sub></div>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '25px' }}>V<sub>Y</sub></div>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>V<sub>B</sub></div>
                        </div>
    
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '170px', marginBottom: '20px' }}>
                        <p><strong>{vthd.VTHD_Avg}%</strong></p>
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                marginBottom: '5px',
                                borderRadius: '10px',
                            }}
                        >
                            <div
                                style={{
                                    width: `${vthd.VHar_R || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {vthd.VHar_R || 0}%
                            </span>
                        </div>
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                marginBottom: '5px',
                                borderRadius: '10px',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${vthd.VHar_Y || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {vthd.VHar_Y || 0}%
                            </span>
                        </div>
    
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${vthd.VHar_B || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {vthd.VHar_B || 0}%
                            </span>
                        </div>
                    </div>
                    </div>
                    </div>
                    <div className="charts-container" style={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between', marginRight: '10px'}}>
                        <div className="gauge-container" style={{ width: '80%',height: '200px', position: 'relative', padding: '20px', border: '1px solid #ccc', borderRadius: '10px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', backgroundColor: '#f9f9f9', marginLeft: '5px'}}>
                        <h4
                            style={{
                                paddingBottom: '0px', textAlign: 'center', marginTop: '-5px' 
                            }}
                            >
                            Frequency
                            </h4>
                            <div className="speedometer">
                            <ReactSpeedometer
                                id={`speedometer-kwh`}
                                minValue={0}  
                                maxValue={80}   
                                value={freq || 0}  
                                needleColor="#000000"
                                segmentColors={[
                                    "#3dadff",
                                    "#3dadff",
                                    "#3dadff", 
                                    "#3dadff", 
                                    "#3dadff", 
                                ]}
                                ringWidth={20}
                                width={260}
                                needleHeightRatio={0.95}
                                needleTransitionDuration={5000}
                                needleTransition="easeLinear"
                                textColor="#000000"
                                currentValueText={`${freq || 0} Hz`}  
                                valueTextFontSize="14px"
                                labelFontSize="0px"
                                />
                        </div>
                    </div>
                </div>
                    <div
                        style={{
                            width: '90%',
                            // border: '1px solid #ccc',
                            // borderRadius: '10px',
                            padding: '15px',
                            // boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                            // backgroundColor: '#f9f9f9',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', 
                            justifyContent: 'center',
                            position: 'relative',
                        }}
                        >
                    <div
                        className="chart"
                        style={{
                        width: '100%',
                        height: '210px',
                        border: '1px solid #ccc',
                        borderRadius: '10px',
                        padding: '15px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent:'center',
                        position: 'relative',
                        marginLeft: '-20px'
                        }}
                    >
                    <div
                            style={{
                                position: 'absolute',
                                top: '10px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontSize: '18px',
                                fontWeight: 'bold',
                                color: '#333',
                                backgroundColor: '#f9f9f9',
                                padding: '5px',
                                borderRadius: '5px',
                            
                            }}
                        >
                            I<sub>THD</sub>
                        </div>
                        <div
                        style={{
                            height: '150px',
                            width: '80px',
                            border: '1px solid #ccc',
                            position: 'relative',
                            backgroundColor: '#f0f0f0',
                            overflow: 'hidden',
                            marginRight: '20px',
                            borderRadius: '15px',
                        }}
                        >
                        <span
                            style={{
                            position: 'absolute',
                            top: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '12px',
                            color: '#333',
                            }}
                        >
                            <p>{ithd.ITHD_Avg}%</p>
                        </span>
                        <div
                            style={{
                            width: '100%',
                            height: `${ithd.ITHD_Avg}%`,
                            backgroundColor: '#4caf50',
                            position: 'absolute',
                            bottom: 0,
                            transition: 'height 0.3s ease',
                            }}
                        />
                        <span
                            style={{
                            position: 'absolute',
                            bottom: '-10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            fontSize: '12px',
                            color: '#333',
                            }}
                        >
                            <p>0%</p>
                        </span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px' }}>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '35px', marginRight: '5px' }}>I<sub>R</sub></div>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '25px' }}>I<sub>Y</sub></div>
                            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#333', marginTop: '20px' }}>I<sub>B</sub></div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '170px', marginBottom: '20px' }}>
                            <p><strong>{ithd.ITHD_Avg}%</strong></p>
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                marginBottom: '5px',
                                borderRadius: '10px',
                            }}
                        >
                            <div
                                style={{
                                    width: `${ithd.CuHar_R || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {ithd.CuHar_R || 0}%
                            </span>
                        </div>
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                marginBottom: '5px',
                                borderRadius: '10px',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${ithd.CuHar_Y || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {ithd.CuHar_Y || 0}%
                            </span>
                        </div>
    
                        <div
                            style={{
                                height: '50px',
                                width: '150px',
                                border: '1px solid #ccc',
                                backgroundColor: '#f0f0f0',
                                position: 'relative',
                                overflow: 'hidden',
                                borderRadius: '10px',
                                marginTop: '10px'
                            }}
                        >
                            <div
                                style={{
                                    width: `${ithd.CuHar_B || 0}%`,
                                    height: '100%',
                                    backgroundColor: '#4caf50',
                                    transition: 'width 0.3s ease',
                                }}
                            />
                            <span style={{ 
                                position: 'absolute', 
                                right: '5px', 
                                top: '50%', 
                                transform: 'translateY(-50%)', 
                                color: '#000' 
                            }}>
                                {ithd.CuHar_B || 0}%
                            </span>
                        </div>
                    </div>
                    </div>
                    </div>
                </div>
            <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>{chartData.graph_title}</h4>
                <div style={{ marginBottom: "20px" }}>
                    <label
                    htmlFor="date-picker"
                    style={{
                        flexDirection: "row",
                        alignItems: "center",
                        color: "#333",
                        fontWeight: "bold",
                        marginLeft: '40px',
                        fontSize: "14px",
                    }}
                    >
                    Date:
                    </label>
                    <DatePicker
                    id="date-picker"
                    selected={dateselectedDate}
                    onChange={(date) => handleNewDateChange(date)}
                    dateFormat="yyyy/MM/dd"
                    customInput={
                        <input
                        style={{
                            fontSize: "12px",
                            width:'55%',
                            padding: '5px',
                            borderRadius: '5px',
                            marginLeft: '-35px'
                        }}
                        />
                    }
                    />
                </div>
                <Chart
                options={{
                    chart: {
                        type: 'bar',
                        height: 350,
                        background: '#faffee',
                        toolbar: {
                            tools: { download: false },
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '50%',
                            endingShape: 'rounded',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: chartData.time_intervals,
                        title: {
                            text: chartData.x_axis_label,
                        },
                    },
                    yaxis: {
                        title: {
                            text: chartData.y_axis_label,
                        },
                        labels: {
                            formatter: (value) => {
                                return new Intl.NumberFormat('en-US').format(value);
                            },
                        },
                    },
                    tooltip: {
                        y: {
                            formatter: (value, { seriesIndex }) => {
                                if (chartData.bars[seriesIndex]?.name === 'cost') {
                                    return `Rs ${new Intl.NumberFormat('en-US').format(value)}`;
                                }
                                return new Intl.NumberFormat('en-US').format(value);
                            },
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'center',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }}
                series={chartData.bars.map(bar => ({
                    name: bar.name,
                    data: bar.data,
                }))}
                type="bar"
                height={350}
                width={950}
            />
            </div>
        </div>

         <div
            className="charts-container"
            style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}
        >
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Day's Consumption</h4>
                <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "30px",
                }}
            >
                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Month:
                </label>
                <select
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    style={{
                        padding: "5px",
                        marginRight: "20px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {monthNames.map((month, index) => (
                        <option key={index + 1} value={index + 1}>
                            {month}
                        </option>
                    ))}
                </select>

                <label
                    style={{
                        fontSize: "14px",
                        color: "#333",
                        fontWeight: "bold",
                        marginRight: "10px",
                    }}
                >
                    Year:
                </label>
                <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{
                        padding: "5px",
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                    }}
                >
                    {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>
            <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            background: '#fff3f3',
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        colors: ['#4B0082', '#FF8C00', '#00CED1'],
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: dailyData.categories,
                            title: {
                                text: 'Dates',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'KWh',
                            data: dailyData.kw,
                        },
                        {
                            name: 'Cost',
                            data: dailyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: dailyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>

        <div className="charts-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div
                className="container-4"
                style={{
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                    padding: '20px',
                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#f9f9f9',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <h4>Monthly Consumption</h4>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: "30px",
                    }}
                >
                    <label
                        style={{
                            fontSize: "14px",
                            color: "#333",
                            fontWeight: "bold",
                            marginRight: "10px",
                        }}
                    >
                        Year:
                    </label>
                    <select
                        value={selectedNewYear}
                        onChange={handleNewYearChange}
                        style={{
                            padding: "5px",
                            borderRadius: "5px",
                            border: "1px solid #ccc",
                        }}
                    >
                        {Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i).map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            height: 350,
                            background: '#dbf4fe',
                            toolbar: {
                                tools: {
                                    zoom: false,
                                    zoomin: false,
                                    zoomout: false,
                                    pan: false,
                                    reset: false,
                                    download: false,
                                },
                            },
                            zoom: {
                                enabled: true,
                            },
                        },
                        plotOptions: {
                            bar: {
                                horizontal: false,
                                columnWidth: '50%',
                                endingShape: 'rounded',
                            },
                        },
                        colors: ['#FF4500', '#32CD32', '#1E90FF'],
                        dataLabels: {
                            enabled: false,
                        },
                        xaxis: {
                            categories: monthlyData.categories,
                            title: {
                                text: 'Months',
                            },
                        },
                        yaxis: {
                            title: {
                                text: 'Value',
                            },
                            labels: {
                                formatter: (value) => {
                                    return new Intl.NumberFormat('en-US').format(value);
                                },
                            },
                        },
                        legend: {
                            position: 'top',
                            horizontalAlign: 'center',
                            offsetX: 0,
                            offsetY: 0,
                        },
                        tooltip: {
                            y: {
                                formatter: function (value, { seriesIndex }) {
                                    if (seriesIndex === 1) {
                                        return `Rs ${value.toLocaleString()}`;
                                    }
                                    return value.toLocaleString();
                                },
                            },
                        },
                    }}
                    series={[
                        {
                            name: 'KWh',
                            data: monthlyData.kWh,
                        },
                        {
                            name: 'Cost',
                            data: monthlyData.cost,
                        },
                        {
                            name: 'CO2',
                            data: monthlyData.co2,
                        },
                    ]}
                    type="bar"
                    height={350}
                    width={950}
                />
            </div>
        </div>

        <div
            style={{
                width: "96%",
                margin: "20px auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
            }}
            >
            <h4>
                Time Slots
            </h4>

            <div
        style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "30px",
            marginLeft: "150px"
        }}
      >
       
        <label
          style={{
            fontSize: "14px",
            color: "#333",
            marginBottom: "5px", 
            fontWeight: "bold",
            marginLeft: '40px'
          }}
        >
         Date:
        </label>
        <DatePicker
          selected={timeselectedDate}
          onChange={handleTimeDateChange}
          dateFormat="yyyy/MM/dd"
          customInput={
            <input
              style={{
                fontSize: "12px",
                width:'35%',
                marginRight:'140px',
                marginLeft: '-20px',
                padding: '5px',
                borderRadius: '5px',
              }}
            />
          }
        />
      </div>

            <div
                style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "30px",
                }}
                
            >
                <Chart
                    options={chartsData.options}
                    series={chartsData.series}
                    type="bar"
                    height={350}
                    width={800}
                />
            </div>

            <table
                style={{
                width: "100%",
                borderCollapse: "collapse",
                textAlign: "center",
                }}
            >
                <thead>
                <tr>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Timings
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Rate
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Today's KWh
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Cost
                    </th>
                    <th
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        backgroundColor: "#f4f4f4",
                    }}
                    >
                    Total Co2
                    </th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        {row.Timings}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        }}
                    >
                        RS.{row.Rate}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        {row.TodaysKWhr.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        Rs {row.TotalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                        style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "right",
                        }}
                    >
                        {row.TotalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons 
                    </td>
                    </tr>
                ))}
                <tr>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                    }}
                    colSpan={2}
                    >
                    Total
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    {totalKWhr.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    Rs {totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </td>
                    <td
                    style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        fontWeight: "bold",
                        backgroundColor: "#f9f9f9",
                        textAlign: "right",
                    }}
                    >
                    {totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
            <div className="dashboard">
            <div className="main-container">
                <h4>Reports</h4>
            <div className="nav-bar">
                {tabs.map((tab) => (
                <button
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => handleTabChange(tab)} 
                >
              {tab}
            </button>
            ))}
             </div>
                {activeTab === "Hourly" && (
                <div className="hourly-report">
                    <div className="card controls-card">
                    <input
                        type="datetime-local"
                        value={hourlyfromdate}
                        onChange={(e) => setHourlyfromdate(e.target.value)}
                        placeholder="Select From Date"
                    />
                    <input
                        type="datetime-local"
                        value={hourlytodate}
                        onChange={(e) => setHourlytodate(e.target.value)}
                        placeholder="Select To Date"
                    />
                    <button onClick={handleGetHourlyReport}>Get Report</button>
                    <button onClick={() => handleHourlyExportToExcel('Hourly_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>
                    {hourlyloading ? (
                        <span className="spinner" />
                    ) : (
                    <div className="table-container">
                    <h4 className="table-title">
                        Hourly Log View from {hourlyfromdate || "Selected from Date"} to {hourlytodate || "Selected to date"}
                    </h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Hour</th>
                            <th>Start KWh</th>
                            <th>End KWh</th>
                            <th>Total KWh</th>
                            <th>Total Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {hourlyData.length > 0 ? (
                            hourlyData.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.timeInterval}</td>
                                    <td>{row.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                            <td>{hourlyData.reduce((total, row) => total + row.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td>{hourlyData.reduce((total, row) => total + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
            )}

                    <div className="pagination-controls">
                        <button
                            disabled={currenthourlyPage === 1}
                            onClick={() => handlehourlyPageChange(currenthourlyPage - 1)}
                        >
                            Previous
                        </button>
                        <span> {currenthourlyPage} of {totalhourlyPages}</span>
                        <button
                            disabled={currenthourlyPage === totalhourlyPages}
                            onClick={() => handlehourlyPageChange(currenthourlyPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
                )}
                {activeTab === "Daily" && (
                <div className="daily-report">
                    <div className="card controls-card">
                    <input
                        type="datetime-local"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        placeholder="From Date"
                    />
                    <input
                        type="datetime-local"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        placeholder="To Date"
                    />
                    <button onClick={handleGetDailyReport}>Get Report</button>
                    <button onClick={() => handleExportToExcel(dailyreportData, 'Daily_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>
                    {dailyloading ? (
                        <span className="spinner" />
                    ) : (
                    <div className="table-container">
                    <h4 className="table-title">
                        Daily Log View from {fromDate} to {toDate}
                    </h4>
                    <table>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>Start KWH</th>
                            <th>End KWH</th>
                            <th>Total KWH</th>
                            <th>Total Cost</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentDailyRows.length > 0 ? (
                            currentDailyRows.map((row, index) => (
                                <tr key={index}>
                                    <td>{row.date}</td>
                                    <td>{row.fromDate}</td>
                                    <td>{row.toDate}</td>
                                    <td>{row.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" style={{ textAlign: "center" }}>
                                    No Data Available
                                </td>
                            </tr>
                        )}
                        </tbody>
                        <tfoot>
                        <tr>
                            <td colSpan="5" style={{ textAlign: "right" }}>TOTAL</td>
                            <td>{dailyreportData.reduce((total, row) => total + row.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td>{dailyreportData.reduce((total, row) => total + row.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                        </tfoot>
                    </table>
                    </div>
                    )}
                    <div className="pagination-controls">
                        <button
                            disabled={currentdailyreportPage === 1 || currentDailyRows.length === 0}
                            onClick={() => handledailyPageChange(currentdailyreportPage - 1)}
                        >
                            Previous
                        </button>
                        <span>  {currentdailyreportPage}   of   {totaldailyPages}  </span>
                        <button
                            disabled={currentdailyreportPage === totaldailyPages || currentDailyRows.length === 0}
                            onClick={() => handledailyPageChange(currentdailyreportPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
                )}
                {activeTab === "Parametric" && (
                <div className="Parametric-Report">
                    <div className="card controls-card">
                    <input
                        type="datetime-local"
                        value={selectedfromreportDate}
                        onChange={(e) => setSelectedfromreportDate(e.target.value)}
                        placeholder="Select Date"
                    />
                    <input
                        type="datetime-local"
                        value={selectedtoreportDate}
                        onChange={(e) => setSelectedtoreportDate(e.target.value)}
                        placeholder="Select Date"
                    />
                    <button onClick={handleGetLogReport}>Get Report</button>
                    <button onClick={() => handleExportToExcel(logData, 'Meter_Log_Report.xlsx')}>
                        <i className="excel-icon">📊</i> Excel
                    </button>
                    </div>
                    {paraloading ? (
                        <div>
                            <span className="spinner"></span>
                        </div>
                    ) : (
                    <div className="table-container">
                    <h4 className="table-title">
                        Meter Log View on {selectedreportDate || "Selected Date"}
                    </h4>
                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>DateTime</th>
                                    <th>VR</th>
                                    <th>VY</th>
                                    <th>VB</th>
                                    <th>VRY</th>
                                    <th>VYB</th>
                                    <th>VBR</th>
                                    <th>IR</th>
                                    <th>IY</th>
                                    <th>IB</th>
                                    <th>KVA</th>
                                    <th>KW</th>
                                    <th>PF</th>
                                    <th>KWH</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentRows.length > 0 ? (
                                    currentRows.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.datetime}</td>
                                            <td>{row.VR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VRY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VYB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.VBR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IR.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IY.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.IB.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KVA.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KW.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.PF.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            <td>{row.KWH.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="14" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
                    )}

                    <div className="pagination-controls">
                        <button
                            disabled={currentreportPage === 1 || currentRows.length === 0}
                            onClick={() => handlePageChange(currentreportPage - 1)}
                        >
                            Previous
                        </button>
                        <span>  {currentreportPage}   of   {totalreportPage}  </span>
                        <button
                            disabled={currentreportPage === totalreportPage || currentRows.length === 0}
                            onClick={() => handlePageChange(currentreportPage + 1)}
                        >
                            Next
                        </button>
                    </div>
                </div>
                )}

        {activeTab === "Historical Trend" && (
        <div className="historical-trend">
            <div className="card controls-card">
            <select
                value={historicalTrend}
                onChange={handleHistoricalTrendChange}
            >
                <option>Select Parameters</option>
                <option value="voltage_phase_to_phase">Voltage Phase to Phase</option>
                <option value="voltage_phase_to_neutral">Voltage Phase to Neutral</option>
                <option value="frequency">Frequency</option>
                <option value="current">Current</option>
                <option value="current_harmonics">Current Harmonics</option>
                <option value="voltage_harmonics">Voltage Harmonics</option>
                <option value="power_factor">PF</option>
                <option value="KWH">KWH</option>
                <option value="kvah">KVAH</option>
                <option value="kw">KW</option>
                <option value="va">KVA</option>
                <option value="power">Power</option>
            </select>
            <input
                type="date"
                value={selectedreportDate}
                onChange={(e) => setSelectedreportDate(e.target.value)}
                placeholder="Select Date"
            />
             <button onClick={downloadChartAsPNG} disabled={loading}>
                {loading ? (
                <span>
                    <span className="spinner" /> 
                </span>
                ) : (
                "Download"
                )}
            </button>
            </div>

            <div className="trend-container" ref={chartRef}>
                            <h3 className="trend-title">{historicalTrend} Trend Report</h3>
                            {histloading ? (
                                                <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                                                    <div className="spinner"></div>
                                                </div>
                                            ) : chartssData.length > 0 ? (
                                                            <Line data={chartsssData} options={chartsssOptions} />
                                                        ) : (
                                                            <p>No data available for the selected parameter or date.</p>
                                                        )}
                        </div>
                        </div>
        )}
         {activeTab === "Peak Timing" && (
                    <div className="peak-timing-report">
                        <div className="card controls-card">
                        <input
                            type="date"
                            value={selectedreportDate}
                            onChange={(e) => setSelectedreportDate(e.target.value)}
                            placeholder="Select Date"
                        />
                        <button onClick={handleGetReport}>Get Report</button>
                        <button onClick={() => handleExportExcel(peakTimingData)}>
                            <i className="excel-icon">📊</i> Excel
                        </button>
                        </div>
                        {peakloading ? (
                            <div>
                                <span className="spinner"></span>
                            </div>
                        ) : (
                        <div className="table-container">
                            <h4 className="table-title">Peak Timing Report</h4>
                            <table>
                            <thead>
                                <tr>
                                <th>S.No</th>
                                <th>Timings</th>
                                <th>Rate</th>
                                <th>Today's Wh</th>
                                <th>Total Cost</th>
                                <th>Total Co2</th>
                                </tr>
                            </thead>
                            <tbody>
                    {peakTimingData.length > 0 ? (
                    peakTimingData.map((row, index) => (
                        <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row.timing}</td>
                        <td>{row.rate}</td>
                        <td>{row.todaysWh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>{row.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        <td>{row.totalCo2.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                    ))
                    ) : (
                    <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                        No Data Available
                        </td>
                    </tr>
                    )}
                </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {activeTab === "Monthly" && (
            <div className="Monthly-Report">
                        <div className="card controls-card">
                            <DatePicker
                                selected={monthlyfromDate}
                                onChange={handleMonthlyFromMonthChange}
                                dateFormat="yyyy-MM"
                                showMonthYearPicker
                                placeholderText="Select From Month"
                                className="custom-date-picker"
                            />
            
                            <DatePicker
                                selected={monthlytoDate}
                                onChange={handleMonthlyToMonthChange}
                                dateFormat="yyyy-MM"
                                showMonthYearPicker
                                placeholderText="Select To Month"
                                className="custom-date-picker"
                            />
                            <button onClick={handleMonthlyReport}>Get Report</button>
                            <button onClick={() => handleExportToExcel(monthsData,'Monthly_Report.xlsx')}>
                                <i className="excel-icon">📊</i> Excel
                            </button>
                        </div>
                        {monthlyloading ? (
                            <div>
                                <span className="spinner"></span>
                            </div>
                        ) : (
                            <div className="table-container">
                                <h4 className="table-title">Monthly Report from {monthlyfromDate ? monthlyfromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected from Month"} to {monthlytoDate ? monthlytoDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long' }) : "Selected to Month"}</h4>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>Month</th>
                                            <th>Start KWh</th>
                                            <th>End KWh</th>
                                            <th>Total KWh</th>
                                            <th>Total Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {currentMonthlyRows.length > 0 ? (
                                        currentMonthlyRows.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{item.month}</td>
                                                <td>{item.startKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{item.endKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{item.totalKwh.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                                <td>{item.totalCost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" style={{ textAlign: "center" }}>
                                                No Data Available
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: "right" }}>TOTAL</td>
                                        <td>{monthsData.reduce((total, item) => total + item.totalKwh, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                        <td>{monthsData.reduce((total, item) => total + item.totalCost, 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                    </tr>
                                </tfoot>
                                </table>
                            </div>
                        )}
                        <div className="pagination-controls">
                            <button
                                disabled={currentmonthlyreportPage === 1 || currentMonthlyRows.length === 0}
                                onClick={() => handlemonthlyPageChange(currentmonthlyreportPage - 1)}
                            >
                                Previous
                            </button>
                            <span>  {currentmonthlyreportPage}   of   {totalmonthlyPages}  </span>
                            <button
                                disabled={currentmonthlyreportPage === totalmonthlyPages || currentMonthlyRows.length === 0}
                                onClick={() => handlemonthlyPageChange(currentmonthlyreportPage + 1)}
                            >
                                Next
                            </button>
                        </div>
                    </div>
            )}
        </div>
        </div>
                </div>
                </div>
            );
        };
    
    export default EmsSub;
    