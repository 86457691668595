import React, {useState, useEffect,useRef} from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus,faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';

const EnergyMeter = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [fullData, setFullData] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [newMeter, setNewMeter] = useState({ id: '', name: '', cost: ''});
  const [editMeter, setEditMeter] = useState({ id: '', meter_id: '', name: '', cost: '', model: '', peak_timing: '' ,
    vpn_gauge_max:'', vpn_gauge_min : '',vpp_gauge_max:'',vpp_gauge_min:'',i_gauge_max:'',i_gauge_min:'',kw_gauge_max:'',
    kw_gauge_min:'',kva_gauge_max:'',kva_gauge_min:'', freq_gauge_max:'',freq_gauge_min:'', part: '', modelnumber: '', location: ''});

  const [isModalOpen, setModalOpen] = useState(false);
  const [dataToDisplay, setDataToDisplay] = useState(fullData);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [timings, setTimings] = useState([]);
  const [unitSelected, setUnitSelected] = useState("COST");
  const [successMessage, setSuccessMessage] = useState('');

  const [isEditModalOpen, setEditModalOpen] = useState(false)
  const[isDeleteModalOpen,setDeleteModalOpen] =useState(false)
  const addModalRef = useRef(null);
  const editModalRef = useRef(null);
  const deleteModalRef = useRef(null);
  
  
  const totalPages = Math.ceil(dataToDisplay.length / itemsPerPage);
  const paginatedData = dataToDisplay.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const showSuccessMessage = (msg) => {
    setSuccessMessage(msg);
    setTimeout(() => {
      setSuccessMessage('');
    }, 3000);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/energymeters/`);
      // console.log("API Response:", response.data); 
      setFullData(response.data);
      setDataToDisplay(response.data); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // const fetchGroups = async () => {
  //   try {
  //     const response = await axios.get(`${BaseURL}meters/emsgroups/`);
  //     // console.log("Groups Response:", response.data); 
  //     // setGroups(response.data); 
  //   } catch (error) {
  //     console.error("Error fetching groups:", error);
  //   }
  // };

  const fetchPeakTimings = async () => {
    try {
      const response = await axios.get(`${BaseURL}meters/peaktimings/`);
      setTimings(response.data);

      // console.log(response.data);
    } catch (error) {
      console.error("Error fetching peak timings:", error);
    }
  };


  useEffect(() => {
    fetchData(); 
    // fetchGroups();
    fetchPeakTimings();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (addModalRef.current && !addModalRef.current.contains(event.target)) {
        setModalOpen(false);
      }
      if (editModalRef.current && !editModalRef.current.contains(event.target)) {
        setEditModalOpen(false);
      }
      if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
        setDeleteModalOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(e.target.value);
    setCurrentPage(1); 
    if (searchTerm === '') {
      setDataToDisplay(fullData);
    } else {
      const filteredData = fullData.filter((item) => 
        item.meter_name.toLowerCase().includes(searchTerm) ||
        item.meter_id.toLowerCase().includes(searchTerm) ||
        item.model.toLowerCase().includes(searchTerm)
      );
      setDataToDisplay(filteredData.length > 0 ? filteredData : []);
    }
  
    setCheckedItems({});
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newCheckedItems = {};
    dataToDisplay.forEach(item => {
      newCheckedItems[item.id] = isChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  const handleAddMeter = async () => {
    if (!newMeter.id || !newMeter.name || !unitSelected) {
      alert("Please fill in all required fields: Meter ID, Meter Name, and Unit Type.");
      return;
    }
  
    const meterData = {
      meter_id: newMeter.id,
      meter_name: newMeter.name,
      cost_per_unit: unitSelected === 'Cost' ? Number(newMeter.cost) : null,
      peak_timing: unitSelected === 'Peak Hours Timings' && newMeter.peak_timing ? newMeter.peak_timing : null,
      unit_type: unitSelected === 'Cost' ? 'Cost' : unitSelected === 'Peak Hours Timings' ? 'Peak Timing' : '',
      model: newMeter.model,
      meter_location: newMeter.location,
      model_number: newMeter.model_number,
      part_number: newMeter.part,
      vpn_gauge_max: newMeter.vpn_max,
      vpn_gauge_min: newMeter.vpn_min,
      vpp_gauge_max: newMeter.vpp_max,
      vpp_gauge_min: newMeter.vpp_min,
      i_gauge_max: newMeter.i_max,
      i_gauge_min: newMeter.i_min,
      kw_gauge_max: newMeter.kw_max,
      kw_gauge_min: newMeter.kw_min,
      kva_gauge_max: newMeter.kva_max,
      kva_gauge_min: newMeter.kva_min,
      freq_gauge_max: newMeter.freq_max,
      freq_gauge_min: newMeter.freq_min,
    };
  
    try {
      const response = await axios.get(`${BaseURL}meters/energymeters/`);
      const existingMeter = response.data.find(meter => 
        meter.meter_id === newMeter.id || meter.meter_name === newMeter.name
      );
      
      if (existingMeter) {
        alert("Meter ID or Meter Name already exists. Please choose a unique Meter ID or Name.");
        return;
      }

      await axios.post(`${BaseURL}meters/energymeters/`, meterData);
      fetchData(); 
      setNewMeter({ id: '', name: '', cost: '' });
      setModalOpen(false);
      showSuccessMessage('Meter added successfully!');
    } catch (error) {
      if (error.response) {
        console.error("Error adding meter:", error.response.data);
        alert(`An error occurred while adding the meter. Error Code: ${error.response.status}, ${error.response.data.message || error.response.data}`);
      } else if (error.request) {
        console.error("Error: No response from the server.");
        alert("No response from the server. Please try again later.");
      } else {
        console.error("Error:", error.message);
        alert(`An unexpected error occurred: ${error.message}`);
      }
    }
  };
  
  
  

  const handleDeleteMeters = async () => {
    try {
      await Promise.all(
        Object.keys(checkedItems)
          .filter((id) => checkedItems[id])
          .map((id) =>
            axios.delete(`${BaseURL}meters/energymeters/${id}/`)
          )
      );
      fetchData();
      setCheckedItems({});
      showSuccessMessage('Selected meters deleted successfully!');
    } catch (error) {
      console.error("Error deleting meters:", error);
    } finally {
      setDeleteModalOpen(false); // Close modal after completion
    }
  };

  
  const handleEditMeter = async () => {
    
    const unitTypeValue =
    unitSelected === "Cost" ? "Cost" : 
    unitSelected === "Peak Timing" ? "Peak Timing" : "";

    const meterData = { 
      id: editMeter.id, 
      meter_id: editMeter.meter_id, 
      meter_name: editMeter.name,
      cost_per_unit: unitSelected === 'Cost' ? Number(editMeter.cost) : null,
      peak_timing: unitSelected === 'Peak Hours Timings' && editMeter.peak_timing ? editMeter.peak_timing : null,
      // status: editMeter.status,
      unit_type: unitTypeValue,
      meter_location: editMeter.location,
      model_number: editMeter.modelnumber,
      part_number: editMeter.part,
      model: editMeter.model,
      vpn_gauge_max:editMeter.vpn_gauge_max,
      vpn_gauge_min:editMeter.vpn_gauge_min,
      vpp_gauge_max:editMeter.vpp_gauge_max,
      vpp_gauge_min:editMeter.vpp_gauge_min,
      i_gauge_max:editMeter.i_gauge_max,
      i_gauge_min:editMeter.i_gauge_min,
      kw_gauge_max:editMeter.kw_gauge_max,
      kw_gauge_min:editMeter.kw_gauge_min,
      kva_gauge_max:editMeter.kva_gauge_max,
      kva_gauge_min:editMeter.kva_gauge_min,
      freq_gauge_max:editMeter.freq_gauge_max,
      freq_gauge_min:editMeter.freq_gauge_min,
    };
  
  
    try {
    
      // console.log("Updating meter:", meterData);
  
      await axios.put(`${BaseURL}meters/energymeters/${editMeter.id}/`, meterData);
  
      
      const updatedData = dataToDisplay.map((item) =>
        item.id === editMeter.id ? { ...item, ...meterData } : item
      );
  
      setDataToDisplay(updatedData);
      setFullData((prevFullData) =>
        prevFullData.map((item) =>
          item.id === editMeter.id ? { ...item, ...meterData } : item
        )
      );
  
      setEditMeter({ id: '', meter_id: '', name: '', cost: '', model: ''});
      setEditModalOpen(false);
      showSuccessMessage('Meter updated successfully!');
    } catch (error) {
      
      console.error("Error editing meter:", error.response ? error.response.data : error.message);
      alert("An error occurred while editing the meter. Please try again.");
    }
  };
  
  

  const handleEditClick = (meter) => {
    const unitType = meter.unit_type || '';

    setUnitSelected(unitType);
    
    setEditMeter({
      id: meter.id,
      meter_id: meter.meter_id,
      name: meter.meter_name,
      cost: meter.cost_per_unit,
      // status: meter.status,
      model: meter.model || "",
      location: meter.meter_location,
      modelnumber: meter.model_number,
      part: meter.part_number,
      peak_timing: meter.peak_timing,
      vpn_gauge_max: meter.vpn_gauge_max,
      vpn_gauge_min: meter.vpn_gauge_min,
      vpp_gauge_max: meter.vpp_gauge_max,
      vpp_gauge_min: meter.vpp_gauge_min,
      i_gauge_max: meter.i_gauge_max,
      i_gauge_min: meter.i_gauge_min,
      kw_gauge_max: meter.kw_gauge_max,
      kw_gauge_min: meter.kw_gauge_min,
      kva_gauge_max: meter.kva_gauge_max,
      kva_gauge_min: meter.kva_gauge_min,
      freq_gauge_max: meter.freq_gauge_max,
      freq_gauge_min: meter.freq_gauge_min,
    });
    
    setUnitSelected(unitType);
    setEditModalOpen(true);
  };
  
  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
       backgroundColor: '#007acc',
       color: 'white',
       alignContent: 'center',
       fontSize: '14px',
       height:'40px'
      }}>
       <Header title="METER LIST" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',  
        marginTop: '20px', 
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto', 
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
              onChange={handleSearch}
            />
            <FontAwesomeIcon
              icon={ faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
            />
          </div>

          <div style={{ marginLeft: "auto" }}>
      <button
        style={{
          padding: "10px 15px",
          marginRight: "10px",
          backgroundColor: "#f44336",
          color: "white",
          border: "none",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={() => {
          const selectedMeters = Object.keys(checkedItems).filter((id) => checkedItems[id]);
          if (selectedMeters.length > 0) {
            setDeleteModalOpen(true);
          }
        }}
      >
        <FontAwesomeIcon icon={faTrash} style={{ marginRight: "5px" }} />
        Delete
      </button>

            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>

      <div style={{
         marginTop: '20px', 
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px', 
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '5px' }}>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={
                  Object.keys(checkedItems).length ===
                    paginatedData.filter(item => item.meter_name !== 'Main').length &&
                  Object.values(checkedItems).every((value, key) => 
                    (paginatedData.find(item => item.id === key)?.meter_name !== 'Main') ? value : true
                  )
                }
              />
              </th>
              <th style={{ padding: '10px' }}>S.No</th>
              <th style={{ padding: '10px' }}>Meter Name</th>
              <th style={{ padding: '10px' }}>Meter ID</th>
              <th style={{ padding: '10px' }}>Model</th>
              <th style={{ padding: '10px' }}>Unit Type</th>
              <th style={{padding:'10px'}}>Action</th>
             
            </tr>
          </thead>
          <tbody>
  {paginatedData.length > 0 ? (
    paginatedData.map((item, index) => {
      
      return (
        <tr
          key={item.id}
          style={{
            backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
          }}
        >
          <td style={{ padding: '10px' }}>
          {item.meter_name !== 'Main' && (
                  <input
                    type="checkbox"
                    checked={checkedItems[item.id] || false}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                )}
          </td>
          <td style={{ padding: '15px' }}>
            {(currentPage - 1) * itemsPerPage + index + 1}
          </td>
          <td style={{ padding: '15px' }}>{item.meter_name}</td>
          <td style={{ padding: '15px' }}>{item.meter_id}</td>
          <td style={{ padding: '15px' }}>{item.model}</td>
          <td style={{ padding: '15px' }}>{item.unit_type}</td>
           <td
                style={{ padding: "15px", color: "blue", cursor: "pointer" }}
                onClick={() => handleEditClick(item)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="7" style={{ padding: '10px', textAlign: 'center' }}>
        No results found.
      </td>
    </tr>
  )}
</tbody>
        </table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Previous
          </button>

          {currentPage > 3 && (
            <>
              <button
                onClick={() => handlePageChange(1)}
                style={{
                  margin: '0 5px',
                  padding: '5px 10px',
                  background: '#f7f7f7',
                  color: '#333333',
                  border: '1px solid #ddd',
                  borderRadius: '25px',
                  cursor: 'pointer',
                  fontSize: '12px',
                }}
              >
                1
              </button>
              <span style={{ margin: '0 5px', fontSize: '14px' }}>...</span>
            </>
          )}

          {Array.from({ length: 5 }, (_, i) => {
            const pageNumber = currentPage - 2 + i;
            if (pageNumber > 0 && pageNumber <= totalPages) {
              return (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  style={{
                    margin: '0 5px',
                    padding: '5px 10px',
                    background: currentPage === pageNumber ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                    color: currentPage === pageNumber ? '#ffffff' : '#333333',
                    border: '1px solid #ddd',
                    borderRadius: '25px',
                    cursor: 'pointer',
                    fontSize: '12px',
                  }}
                >
                  {pageNumber}
                </button>
              );
            }
            return null;
          })}
          {currentPage < totalPages - 2 && (
            <>
              <span style={{ margin: '0 5px', fontSize: '14px' }}>...</span>
              <button
                onClick={() => handlePageChange(totalPages)}
                style={{
                  margin: '0 5px',
                  padding: '5px 10px',
                  background: '#f7f7f7',
                  color: '#333333',
                  border: '1px solid #ddd',
                  borderRadius: '25px',
                  cursor: 'pointer',
                  fontSize: '12px',
                }}
              >
                {totalPages}
              </button>
            </>
          )}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Next
          </button>
        </div>
      </div>

{isModalOpen && (
  <div style={{
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}>
    <div ref={addModalRef} style={{
      backgroundColor: 'white',
      padding: '30px',
      borderRadius: '8px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)', 
      width: '60%', 
      maxWidth: '900px',
      maxHeight: '70vh',
      transition: 'all 0.3s ease',
      overflowY: 'auto',
    }}>
      <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Add New Meter</h4>
      <div style={{ display: 'flex', gap: '30px' }}>
        {/* Left Column */}
        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter Name</label>
            <input
              type="text"
              placeholder="Enter Meter Name"
              value={newMeter.name}
              onChange={(e) => setNewMeter({ ...newMeter, name: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter ID</label>
            <input
              type="text"
              placeholder="Enter Meter ID"
              value={newMeter.id}
                  onChange={(e) => setNewMeter({ ...newMeter, id: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter Location</label>
            <input
              type="text"
              placeholder="Enter Meter Location"
              value={newMeter.location}
              onChange={(e) => setNewMeter({ ...newMeter, location: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Part Number</label>
            <input
              type="text"
              placeholder="Enter Part Number"
              value={newMeter.part}
              onChange={(e) => setNewMeter({ ...newMeter, part: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          {unitSelected === 'Cost' && (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Cost</label>
            <input
              type="number"
              placeholder="Enter Cost"
              value={newMeter.cost}
              onChange={(e) => setNewMeter({ ...newMeter, cost: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Vpn max</label>
            <input
              type="text"
              placeholder="Enter Vpn max"
              value={newMeter.vpn_max}
              onChange={(e) => setNewMeter({ ...newMeter, vpn_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Vpp max</label>
            <input
              type="text"
              placeholder="Enter Vpp max"
              value={newMeter.vpp_max}
              onChange={(e) => setNewMeter({ ...newMeter, vpp_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>I max</label>
            <input
              type="text"
              placeholder="Enter I max"
              value={newMeter.i_max}
              onChange={(e) => setNewMeter({ ...newMeter, i_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Kw max</label>
            <input
              type="text"
              placeholder="Enter Kw max"
              value={newMeter.kw_max}
              onChange={(e) => setNewMeter({ ...newMeter, kw_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Kva max</label>
            <input
              type="text"
              placeholder="Enter Kva max"
              value={newMeter.kva_max}
              onChange={(e) => setNewMeter({ ...newMeter, kva_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Freq max </label>
            <input
              type="text"
              placeholder="Enter Freq max "
              value={newMeter.freq_max}
              onChange={(e) => setNewMeter({ ...newMeter, freq_max: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
        </div>

        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Meter Model</label>
              <select
                value={newMeter.model}
                onChange={(e) => setNewMeter({ ...newMeter, model: e.target.value })}
                style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
              >
                <option value="">Select Meter Model</option>
                <option> ElMeasure</option>
                <option> Selec</option>
              </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Model Number</label>
            <input
              type="text"
              placeholder="Enter Model Number"
              value={newMeter.model_number}
              onChange={(e) => setNewMeter({ ...newMeter, model_number: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Unit select</label>
              <select
                style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                onChange={(e) => setUnitSelected(e.target.value)}
                value={unitSelected}
              >
                <option value="">Select Unit</option>
                <option value="Cost">Cost</option>
                <option value="Peak Hours Timings">Peak Timings</option>
              </select>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Vpn min</label>
            <input
              type="text"
              placeholder="Enter Vpn min"
              value={newMeter.vpn_min}
              onChange={(e) => setNewMeter({ ...newMeter, vpn_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Vpp min</label>
            <input
              type="text"
              placeholder="Enter Vpp min"
              value={newMeter.vpp_min}
              onChange={(e) => setNewMeter({ ...newMeter, vpp_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>I min</label>
            <input
              type="text"
              placeholder="Enter I min"
              value={newMeter.i_min}
              onChange={(e) => setNewMeter({ ...newMeter, i_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Kw min</label>
            <input
              type="text"
              placeholder="Enter Kw min"
              value={newMeter.kw_min}
              onChange={(e) => setNewMeter({ ...newMeter, kw_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Kva min</label>
            <input
              type="text"
              placeholder="Enter Kva min"
              value={newMeter.kva_min}
              onChange={(e) => setNewMeter({ ...newMeter, kva_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <label style={{ flex: '1', marginRight: '10px' }}>Freq min </label>
            <input
              type="text"
              placeholder="Enter Freq min"
              value={newMeter.freq_min}
              onChange={(e) => setNewMeter({ ...newMeter, freq_min: e.target.value })}
              style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
            />
          </div>
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '40%', marginLeft: '200px' }}>
        <button
          onClick={handleAddMeter}
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
            marginRight: '10px',
          }}
        >
          Add Meter
        </button>
        <button
          onClick={() => setModalOpen(false)}
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 15px',
            borderRadius: '4px',
            cursor: 'pointer',
            flex: '1',
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
)}

      {/* Edit Modal */}
      {isEditModalOpen && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <div ref={editModalRef}  style={{
            backgroundColor: 'white',
            padding: '30px',
            borderRadius: '8px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
            width: '60%',
            maxWidth: '900px',
            transition: 'all 0.3s ease',
            overflowY: 'auto',
            maxHeight: '70vh',
          }}>
            <h4 style={{ marginBottom: '20px', textAlign: 'center' }}>Edit Meter</h4>
            <div style={{ display: 'flex', gap: '30px' }}>
              {/* Left Column */}
              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter Name</label>
                  <input
                    type="text"
                    placeholder="Enter Meter Name"
                    value={editMeter.name}
                    onChange={(e) => setEditMeter({ ...editMeter, name: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter ID</label>
                  <input
                    type="text"
                    placeholder="Enter Meter ID" 
                    value={editMeter.meter_id}
                    onChange={(e) => setEditMeter({ ...editMeter, meter_id: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter Location</label>
                  <input
                    type="text"
                    placeholder="Enter Meter Location"
                    value={editMeter.location}
                    onChange={(e) => setEditMeter({ ...editMeter, location: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Part Number</label>
                  <input
                    type="text"
                    placeholder="Enter Part Number"
                    value={editMeter.part}
                    onChange={(e) => setEditMeter({ ...editMeter, part: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                {unitSelected === 'Cost' && (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <label style={{ flex: '1', marginRight: '10px' }}>Cost</label>
                    <input
                      type="number"
                      placeholder="Enter Cost"
                      value={editMeter.cost}
                      onChange={(e) => setEditMeter({ ...editMeter, cost: e.target.value })}
                      style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    />
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Vpn max</label>
                  <input
                    type="text"
                    placeholder="Enter Vpn max"
                    value={editMeter.vpn_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, vpn_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Vpp max</label>
                  <input
                    type="text"
                    placeholder="Enter Vpp max"
                    value={editMeter.vpp_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, vpp_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>I max</label>
                  <input
                    type="text"
                    placeholder="Enter I max"
                    value={editMeter.i_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, i_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Kw max</label>
                  <input
                    type="text"
                    placeholder="Enter Kw max"
                    value={editMeter.kw_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, kw_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Kva max</label>
                  <input
                    type="text"
                    placeholder="Enter MKva max"
                    value={editMeter.kva_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, kva_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Freq max</label>
                  <input
                    type="text"
                    placeholder="Enter Freq max"
                    value={editMeter.freq_gauge_max}
                    onChange={(e) => setEditMeter({ ...editMeter, freq_gauge_max: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
              </div>

              <div style={{ flex: '1', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Meter Model</label>
                  <select
                    value={editMeter.model}
                    onChange={(e) => setEditMeter({ ...editMeter, model: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                  >
                    <option value="">Select Meter Model</option>
                    <option value="ElMeasure">ElMeasure</option>
                    <option value="Selec">Selec</option>
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Model Number</label>
                  <input
                    type="text"
                    placeholder="Enter Model Number"
                    value={editMeter.modelnumber}
                    onChange={(e) => setEditMeter({ ...editMeter, modelnumber: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Unit select</label>
                  <select
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    value={unitSelected} 
                    onChange={(e) => setUnitSelected(e.target.value)}>
                    <option value="">Select Unit</option>
                    <option value="Cost">Cost</option>
                    <option value="Peak Timing">Peak Timings</option>
                  </select>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Vpn min</label>
                  <input
                    type="text"
                    placeholder="Enter Vpn min"
                    value={editMeter.vpn_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, vpn_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Vpp min</label>
                  <input
                    type="text"
                    placeholder="Enter Vpp min"
                    value={editMeter.vpp_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, vpp_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>I min</label>
                  <input
                    type="text"
                    placeholder="Enter I min"
                    value={editMeter.i_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, i_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Kw min</label>
                  <input
                    type="text"
                    placeholder="Enter Kw min"
                    value={editMeter.kw_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, kw_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Kva min</label>
                  <input
                    type="text"
                    placeholder="Enter Kva min"
                    value={editMeter.kva_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, kva_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <label style={{ flex: '1', marginRight: '10px' }}>Freq min</label>
                  <input
                    type="text"
                    placeholder="Enter Meter Name"
                    value={editMeter.freq_gauge_min}
                    onChange={(e) => setEditMeter({ ...editMeter, freq_gauge_min: e.target.value })}
                    style={{ flex: '2', padding: '10px', border: '1px solid #ccc', borderRadius: '8px' }}
                    disabled={editMeter.name === 'Main'}
                  />
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', width: '40%', marginLeft: '200px' }}>
              <button
                onClick={handleEditMeter}
                style={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                  marginRight: '10px',
                }}
              >
                Save Changes
              </button>
              <button
                onClick={() => setEditModalOpen(false)}
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 15px',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  flex: '1',
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            ref={deleteModalRef}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "400px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected meters?</p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
             <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor: "#4CAF50",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={handleDeleteMeters}
              >
                Yes, Delete
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor: "#f44336",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
            </div>

            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EnergyMeter;
