import React, { useState, useEffect,useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus} from '@fortawesome/free-solid-svg-icons';
import Header from '../components/header';
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';

const ProductionDetails = () => {
    const [productionData, setProductionData] = useState([]);
    const [machinesData, setMachinesData] = useState([]);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    // const [isEditModalVisible, setIsEditModalVisible] = useState(false);
      const[isDeleteModalOpen,setDeleteModalOpen] =useState(false)
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [formData, setFormData] = useState({
      date: '',
      time: '',
      machine: '',
      pouredTons: '',
      meltedTons: ''
    });
    const modalRef = useRef();
      const deleteModalRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
              closeAddModal();
          }
      };

      if (isAddModalVisible) {
          document.addEventListener('mousedown', handleClickOutside);
      } else {
          document.removeEventListener('mousedown', handleClickOutside);
      }

      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [isAddModalVisible]);


  useEffect(() => {
    const handleClickOutside = (event) => {
        if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
            setDeleteModalOpen(false);
        }
    };

    if (isDeleteModalOpen) {
        document.addEventListener('mousedown', handleClickOutside);
    } else {
        document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [isDeleteModalOpen]);


    useEffect(() => {
      axios.get(`${BaseURL}meters/production-details/`)
          .then(response => {
              setProductionData(response.data);
          })
          .catch(error => {
              console.error('Error fetching production details:', error);
          });
  }, []);

  useEffect(() => {
    axios.get(`${BaseURL}meters/machine/`)
        .then(response => {
            setMachinesData(response.data);
        })
        .catch(error => {
            console.error('Error fetching machine details:', error);
        });
}, []);

const getMachineName = (machineId) => {
  const machine = machinesData.find(item => item.id === machineId);
  return machine ? machine.machine_name : 'Unknown Machine';
};
    
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    const productionDataToSend = {
      machine: formData.machine,
      date: formData.date,
      time: formData.time,
      poured_tons: formData.pouredTons,
      melted_tons: formData.meltedTons,
    };
    
    axios.post(`${BaseURL}meters/production-details/`, productionDataToSend)
      .then(response => {
        setProductionData(prevData => [...prevData, response.data]);
        setFormData({
          date: '',
          time: '',
          machine: '',
          pouredTons: '',
          meltedTons: '',
        });
        setIsAddModalVisible(false);
        setSuccessMessage('Production details added successfully!'); // Set success message
        setTimeout(() => setSuccessMessage(''), 3000);
      })
      .catch(error => {
        console.error('Error adding production details:', error);
      });
};

const closeAddModal = () => {
    setIsAddModalVisible(false);
    setFormData({
        date: '',
        time: '',
        machine: '',
        pouredTons: '',
        meltedTons: ''
    });
};

  
  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  // const showEditModal = () => {
  //   setIsEditModalVisible(true);
  // };
  
  // const closeEditModal = () => {
  //   setIsEditModalVisible(false);
  //   setFormData({ date: '', time: '', machine: '', pouredTons: '', meltedTons: '' }); 
  // };

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelected) =>
        prevSelected.includes(id)
            ? prevSelected.filter(rowId => rowId !== id)
            : [...prevSelected, id]
    );
};

const handleSelectAll = (e) => {
    if (e.target.checked) {
        const allIds = productionData.map(item => item.id);
        setSelectedRows(allIds);
    } else {
        setSelectedRows([]);
    }
};

const handleDelete = () => {
  setDeleteModalOpen(true);
};

const confirmDelete = () => {
  Promise.all(
      selectedRows.map(id =>
          axios.delete(`${BaseURL}meters/production-details/${id}/`)
      )
  )
      .then(() => {
          setProductionData(prevData => prevData.filter(item => !selectedRows.includes(item.id)));
          setSelectedRows([]);
          setDeleteModalOpen(false); 
          setSuccessMessage('Production details deleted successfully!'); 
        setTimeout(() => setSuccessMessage(''), 3000);
      })
      .catch(error => {
          console.error("Error deleting rows:", error);
          alert("Failed to delete some rows.");
          setDeleteModalOpen(false);
      });
};


  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredData = productionData.filter(item => {
    const machineName = getMachineName(item.machine).toLowerCase();
    return (
        item.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.time.toLowerCase().includes(searchQuery.toLowerCase()) ||
        machineName.includes(searchQuery.toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; 

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="PRODUCTION DETAILS"/>
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
            />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (selectedRows.length > 0) {
                  handleDelete(); 
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={showAddModal}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>

      <div style={{
        marginTop: '20px',
        padding: '10px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        maxWidth: '1000px',
        margin: '20px auto',
        marginBottom: '70px',
      }}>
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
            <tr>
              <th style={{ padding: '10px' }}>
              <input
                type="checkbox"
                onChange={handleSelectAll}
                checked={selectedRows.length === productionData.length}
              />
              </th>
              <th style={{ padding: '10px' }}>Date</th>
              <th style={{ padding: '10px' }}>Time</th>
              <th style={{ padding: '10px' }}>Machine Name</th>
              <th style={{ padding: '10px' }}>Poured Tons</th>
              <th style={{ padding: '10px' }}>Melted Tons</th>

            </tr>
          </thead>
          <tbody>
          {paginatedData.map((item, index) => (
            <tr key={item.id} style={{
                backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
            }}>
                <td style={{ padding: '10px' }}>
                <input
                  type="checkbox"
                  checked={selectedRows.includes(item.id)}
                  onChange={() => handleCheckboxChange(item.id)}
                />
                </td>
                <td style={{ padding: '10px' }}>{item.date}</td>
                <td style={{ padding: '10px' }}>{item.time}</td>
                <td style={{ padding: '10px' }}>{getMachineName(item.machine)}</td>
                <td style={{ padding: '10px' }}>{item.poured_tons}</td>
                <td style={{ padding: '10px' }}>{item.melted_tons}</td>
            </tr>
          ))}
          </tbody>
        </table>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === 1 ? '#007acc' : '#007acc',
              color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === 1 ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Previous
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handlePageChange(i + 1)}
              style={{
                margin: '0 5px',
                padding: '5px 10px',
                background: currentPage === i + 1 ? '#007acc' : 'linear-gradient(to right, #f7f7f7, #e0e0e0)',
                color: currentPage === i + 1 ? '#ffffff' : '#333333',
                border: '1px solid #ddd',
                borderRadius: '25px',
                cursor: 'pointer',
                fontSize: '12px',
              }}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            style={{
              margin: '0 5px',
              padding: '5px 10px',
              background: currentPage === totalPages ? '#007acc' : '#007acc',
              color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
              border: 'none',
              borderRadius: '25px',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              boxShadow: currentPage === totalPages ? 'none' : '0px 4px 8px rgba(0, 0, 0, 0.2)',
              fontSize: '12px',
            }}
          >
            Next
          </button>
        </div>
      </div>
    
      {isAddModalVisible && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 999,
                    animation: 'fadeIn 0.3s ease-out',
                }}>
                    <div ref={modalRef} style={{
                        backgroundColor: 'white',
                        padding: '30px',
                        borderRadius: '12px',
                        width: '650px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transform: 'scale(1.05)',
                        animation: 'zoomIn 0.4s ease-in-out',
                    }}>
                        <h3 style={{
                            fontSize: '24px',
                            color: '#333',
                            fontFamily: 'Arial, sans-serif',
                            marginBottom: '20px',
                            textAlign: 'center',
                        }}>
                            Add New Production
                        </h3>
                        <form>
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gap: '20px',
                            }}>
                                <div>
                                    <label style={{
                                        fontWeight: '600',
                                        color: '#444',
                                        marginBottom: '8px',
                                    }}>Date</label>
                                    <input
                                        type="date"
                                        name="date"
                                        value={formData.date}
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            boxSizing: 'border-box',
                                            fontSize: '16px',
                                        }}
                                    />
                                </div>
                                <div>
                                    <label style={{
                                        fontWeight: '600',
                                        color: '#444',
                                        marginBottom: '8px',
                                    }}>Time</label>
                                    <input
                                        type="time"
                                        name="time"
                                        value={formData.time}
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            boxSizing: 'border-box',
                                            fontSize: '16px',
                                        }}
                                    />
                                </div>
                                <div>
                                    <label style={{
                                        fontWeight: '600',
                                        color: '#444',
                                        marginBottom: '8px',
                                    }}>Machine</label>
                                    <select
                                        name="machine"
                                        value={formData.machine}
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            fontSize: '16px',
                                        }}
                                    >
                                        <option value="">Select a Machine</option>
                                        {machinesData.map((machine) => (
                                            <option key={machine.id} value={machine.id}>
                                                {machine.machine_name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label style={{
                                        fontWeight: '600',
                                        color: '#444',
                                        marginBottom: '8px',
                                    }}>Poured Tons</label>
                                    <input
                                        type="number"
                                        name="pouredTons"
                                        value={formData.pouredTons}
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            boxSizing: 'border-box',
                                            fontSize: '16px',
                                        }}
                                    />
                                </div>
                                <div>
                                    <label style={{
                                        fontWeight: '600',
                                        color: '#444',
                                        marginBottom: '8px',
                                    }}>Melted Tons</label>
                                    <input
                                        type="number"
                                        name="meltedTons"
                                        value={formData.meltedTons}
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: '1px solid #ddd',
                                            boxSizing: 'border-box',
                                            fontSize: '16px',
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}>
                                <button
                                    onClick={handleSubmit}
                                    style={{
                                        backgroundColor: '#4CAF50',
                                        color: 'white',
                                        border: 'none',
                                        padding: '12px 25px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        transition: 'background-color 0.3s',
                                        width: '20%',
                                        marginLeft:'210px'
                                    }}
                                >
                                    Save
                                </button>
                                <button
                                    onClick={closeAddModal}
                                    style={{
                                        backgroundColor: '#ff4d4d',
                                        color: 'white',
                                        border: 'none',
                                        padding: '12px 25px',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        fontSize: '16px',
                                        transition: 'background-color 0.3s',
                                        width: '20%',
                                        marginRight:'150px'
                                    }}
                                    onMouseOver={(e) => e.target.style.backgroundColor = '#e04040'}
                                    onMouseOut={(e) => e.target.style.backgroundColor = '#ff4d4d'}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
 {isDeleteModalOpen && (
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 1000,
                }}
            >
                <div ref={deleteModalRef}
                    style={{
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "10px",
                        width: "400px",
                        textAlign: "center",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    }}
                >
                    <h3>Confirm Deletion</h3>
                    <p>Are you sure you want to delete the selected ProductionDetails?</p>
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                       <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            style={{
                                padding: "10px 15px",
                                color: "white",
                                backgroundColor:"#4CAF50",
                                border: "none",
                                borderRadius: "8px",
                                cursor: "pointer",
                            }}
                            onClick={confirmDelete} 
                        >
                            Yes, Delete
                        </button>
                        <button
                            style={{
                                padding: "10px 15px",
                                color: "white",
                                backgroundColor:"#f44336",
                                border: "none",
                                borderRadius: "8px",
                                cursor: "pointer",
                            }}
                            onClick={() => setDeleteModalOpen(false)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  );
};

export default ProductionDetails;
