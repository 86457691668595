import React, { useState,useEffect,useRef } from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../scss/header.scss';
import axios from 'axios'; 
import BaseURL from '../assets/contants/BaseURL';

const Header = ({ title }) => {
    const username = sessionStorage.getItem('username') || 'Admin';
    const navigate = useNavigate(); 
    const [firstName, setFirstName] = useState('');
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const token = sessionStorage.getItem('token');
        if (token) {
            axios.get(`${BaseURL}/Userauth/userdetail/`, {
                headers: { Authorization: `Token ${token}` },
            })
            .then(response => {
                const user = response.data.find(user => user.usermod.email === username);
                const userFirstName = user?.usermod?.first_name || 'Admin';
                setFirstName(userFirstName);
            })
            .catch(error => {
                console.error('Error fetching user details:', error);
            });
        }
    }, [username]);



    const handleLogout = () => {
        sessionStorage.removeItem('token'); 
        sessionStorage.removeItem('username'); 
        navigate('/login');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="header">
            <div className="header__heading">
                <h1>{title}</h1>
            </div>
            <div className="header__icons">
                <i className="fas fa-bell header__icon"></i>
                <i className="fas fa-envelope header__icon"></i>
                <div className="header__profile-section" onClick={toggleDropdown} ref={dropdownRef}>
                <div className="header__avatar-circle">{firstName.charAt(0).toUpperCase()}</div>
                <div className="header__dropdown-username">{firstName}</div>
                    {/* <div 
                        className="header__clickable"
                        onClick={toggleDropdown}
                    >
                    </div> */}
                    {isDropdownOpen && (
                        <div className="header__dropdown">
                            <div className="header__dropdown-item header__user-info">
                            <div className="header__avatar-circle">{firstName.charAt(0).toUpperCase()}</div>
                            <div className="header__dropdown-username">{firstName}</div>
                            </div>
                            <hr />
                            <div 
                                className="header__dropdown-item header__profile"
                                onClick={() => navigate('/EMS/settings')}
                            >
                                <i className="fas fa-user header__icon"></i> 
                                <span>Profile</span>
                            </div>
                            <div 
                                className="header__dropdown-item header__logout"
                                onClick={handleLogout}
                            >
                                <i className="fas fa-sign-out-alt header__icon"></i> 
                                <span>Logout</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Header;