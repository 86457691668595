import React,{useState,useEffect,useRef} from 'react';
import Header from '../components/header';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload,faSearch } from '@fortawesome/free-solid-svg-icons';
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import BaseURL from '../assets/contants/BaseURL';
import axios from 'axios';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Papa from 'papaparse';


const AlertReports = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [alertReports, setAlertReports] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchMeter, setSearchMeter] = useState('');
  const [filteredMeters, setFilteredMeters] = useState([]);
  const [allMeters, setAllMeters] = useState([]); 
  const [selectedMeter, setSelectedMeter] = useState("Select Meter");
  const [downloadFormat, setDownloadFormat] = useState('pdf');
  const dropdownRef = useRef(null);
  const [alertDetails, setAlertDetails] = useState({
    message: '',
    user: '',
    minValue: '',
    maxValue: '',
    meterId: '',
    parameterName:''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;


  useEffect(() => {
    const fetchMeters = async () => {
      try {
        const metersResponse = await axios.get(`${BaseURL}meters/energymeters/`);
        const meters = metersResponse.data; 
        setAllMeters(meters); 
        setFilteredMeters(meters);
      } catch (error) {
        console.error("Error fetching meter data:", error);
      }
    };
  
    fetchMeters();
  }, []);
  
  
   const handleSearch = (query) => {
    setSearchMeter(query);
    if (query.trim() === '') {
      setFilteredMeters(allMeters);
    } else {
      const filtered = allMeters.filter((meter) =>
        meter.meter_name.toLowerCase().includes(query.toLowerCase())||
        meter.meter_id.toLowerCase().includes(query.toLowerCase()) 
      );
      setFilteredMeters(filtered);
    }
  };

  const handleMeterSelect = (meter) => {
    setSelectedMeter(`${meter.meter_id} - ${meter.meter_name}`);
    setIsOpen(false);
  };

  const handleDownload = () => {
    if (downloadFormat === 'pdf') {
      downloadPDF();
    } else if (downloadFormat === 'csv') {
      downloadCSV();
    }
  };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
          setSearchMeter(''); 
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const handleRowClick = (message, minValue, maxValue, user, parameterName, meterId) => {
      setAlertDetails({ message, minValue, maxValue, user, parameterName, meterId });
      setShowModal(true);
  };
  
    const handleClose = () => {
      setShowModal(false);
    };

    const modalRef = useRef(null);


    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowModal(false);
      }
    };

    useEffect(() => {
      if (showModal) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [showModal]);
    
    const totalPages = Math.ceil(alertReports.length / itemsPerPage);
    const handlePageChange = (page) => {
      if (page >= 1 && page <= totalPages) {
        setCurrentPage(page);
      }
    };
  
    const pageData = alertReports.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    const handleSearchClick = async () => {
      if (!selectedMeter || selectedMeter === "Select Meter" || !fromDate || !toDate) {
        alert("Please select a meter and date range.");
        return;
      }
    
      const meterId = selectedMeter.split(" - ")[0];
      const payload = {
        meter_id: meterId,
        from_date: fromDate.toISOString().split("T")[0],
        to_date: toDate.toISOString().split("T")[0],
      };

      const token = sessionStorage.getItem('token');
    
      if (!token) {
        alert("User is not authenticated.");
        return;
      }
    
      try {
        const response = await axios.post(`${BaseURL}alerts/alert-reports/`, payload, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json'
          }
        });
    
        const formattedReports = response.data.dates_list.map((date, index) => ({
          date,
          meter_id: meterId,
          time: response.data.time_list[index],
          msg: response.data.msg[index],
          trigger: response.data.trigger[index],
          actual_value: response.data.actual_values[index],
          max_value: response.data.max_values[index],
          min_value: response.data.min_values[index],
          parameter_name: response.data.parameter_names[index],
          users: response.data.users.map(user => user.username)[index],
        }));
        setAlertReports(formattedReports);
      } catch (err) {
        console.error("Error fetching alert reports:", err);
      }
    };
    const downloadPDF = () => {
      const doc = new jsPDF();
  
  // Set font size for the title
  doc.setFontSize(18);
  
  // Get the width of the title to center it
  const title = "Alert Reports";
  const titleWidth = doc.getTextWidth(title);
  const pageWidth = doc.internal.pageSize.getWidth();
  
  const xPosition = (pageWidth - titleWidth) / 2;

  

  doc.text(title, xPosition, 7);
  
  
  doc.setFontSize(12);
      autoTable(doc, {
        head: [['S.No', 'Date', 'Time', 'Meter ID', 'Parameter Name', 'Min Value', 'Max Value', 'Actual Value']],
        body: alertReports.map((report, index) => [
          index + 1,
          report.date,
          report.time,
          report.meter_id,
          report.parameter_name,
          report.min_value,
          report.max_value,
          report.actual_value,
        ]),
      });
      doc.save('alert_reports.pdf');
    };
    
    const downloadCSV = () => {
      const csvData = alertReports.map((report, index) => ({
        S_No: index + 1,
        Date: report.date,
        Time: report.time,
        Meter_ID: report.meter_id,
        Parameter_Name: report.parameter_name,
        Min_Value: report.min_value,
        Max_Value: report.max_value,
        Actual_Value: report.actual_value,
      }));
    
      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'alert_reports.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <div style={{
           backgroundColor: '#007acc',
           color: 'white',
           alignContent: 'center',
           fontSize: '14px',
           height:'40px'
          }}>
           <Header title="ALERT REPORTS" />
          </div>
          <div style={{
            border: '1px solid #ccc',
            padding: '10px',
            marginTop: '20px',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
            backgroundColor: 'white',
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            borderRadius: '8px'
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
         <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
             <label style={{ fontWeight: "bold" }}>From Date:</label>
             <Datetime
               selected={fromDate}
               onChange={(date) => setFromDate(date)}
              inputProps={{
                placeholder: "Select From Date Time",
                style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
              }}
            />

            <label style={{ fontWeight: "bold" }}>To Date:</label>
            <Datetime
              selected={toDate}
              onChange={(date) => setToDate(date)}
              inputProps={{
                placeholder: "Select To Date Time",
                style: { borderRadius: "10px", border: "1px solid #ccc", padding: "10px", width: "80%" }
              }}
            />
          </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
  <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
    <div style={{ flex: 1 }}>
      <div
        style={{
          width: '95%',
          padding: '6px',
          borderRadius: '8px',
          border: '1px solid #ccc',
          position: 'relative',
          marginBottom: '10px',
        }}
      >
        <div 
  onClick={() => setIsOpen(!isOpen)}
  style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      cursor: 'pointer',
      width: '160px', 
      fontSize:'12px',
      padding: "5px"
  }}
>
  <span style={{ flex: 1,textAlign: 'left' }}>{selectedMeter}</span>
  <span style={{ fontSize: '10px' }}>
    <i className="fa-solid fa-angle-down"></i>
  </span>
</div>

        {isOpen && (
          <div   ref={dropdownRef} 
            style={{
              position: 'absolute',
              top: '110%',
              left: 0,
              right: 0,
              backgroundColor: 'white',
              borderRadius: '5px',
              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
              zIndex: 10,
              maxHeight: '330px',
              overflowY: 'auto',
              border: '1px solid #000',
            }}
          >
            <div
              style={{
                padding: '10px',
                borderBottom: '1px solid #ccc',
              }}
            >
              <input
                type="text"
                placeholder="Search Meter ID"
                value={searchMeter}
                onChange={(e) => {
                  setSearchMeter(e.target.value); 
                  handleSearch(e.target.value);  
                }}
                style={{
                  width: '90%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                }}
              />
            </div>
            {filteredMeters.length > 0 ? (
              filteredMeters.map((meter) => (
                <div
                key={`${meter.meter_id}-${meter.meter_name}`}
                  style={{
                    padding: '5px',
                    cursor: 'pointer',
                    textAlign: 'left',
                    fontSize: '12px',
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = '#006ec5';
                    e.target.style.color = 'white';
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = 'transparent';
                    e.target.style.color = 'black';
                  }}
                  onClick={() => handleMeterSelect(meter)} 
                >
                  {meter.meter_id} - {meter.meter_name}
                </div>
              ))
            ) : (
              <div style={{ padding: '10px' }}>No results found</div>
            )}
          </div>
        )}
      </div>
    </div>
  </div>

            </div>
            <button
            style={{
              padding: '8px 12px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px',
            }}
            onClick={handleSearchClick}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <button style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px 10px',
              backgroundColor: '#007acc',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              borderRadius: '8px',
              width:'95px'
            }}  onClick={handleDownload}
            >
              <span>Download</span>
              <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }} />
            </button>
            <select
             value={downloadFormat}
             onChange={(e) => setDownloadFormat(e.target.value)}
            style={{
              padding: '5px 10px',
              fontSize: '16px',
              borderRadius: '8px',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              color: '#333',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              outline: 'none',
              fontSize:'12px'
               

            }}
          >
          <option value="pdf">PDF</option>
          <option value="csv">CSV</option>
        </select>
        </div>
        </div>
        </div>
        <div
        style={{
          marginTop: '20px',
          marginBottom: '100px',
          padding: '10px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: 'white',
          maxWidth: '1000px',
          margin: '20px auto',
          borderRadius: '8px',
        }}
    >
  <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
    <thead>
      <tr style={{ backgroundColor: '#007acc', color: 'white' }}>
        <th style={{ padding: '10px' }}>S.No</th>
        <th style={{ padding: '10px' }}>Date</th>
        <th style={{ padding: '10px' }}>Time</th>
        <th style={{ padding: '10px' }}>Meter ID</th>
        <th style={{ padding: '10px' }}>Parameter Name</th>
        <th style={{ padding: '10px' }}>Min Value</th>
        <th style={{ padding: '10px' }}>Max Value</th>
        <th style={{ padding: '10px' }}>Actual Value</th>
      </tr>
    </thead>
    <tbody>
    {pageData.map((alert, index) => (
    <tr key={`${alert.date}-${alert.time}`} onClick={() => handleRowClick(alert.msg, alert.min_value, alert.max_value, alert.users, alert.parameter_name, alert.meter_id)} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff', cursor: 'pointer' }}>
        <td style={{ padding: '10px' }}>{index + 1}</td>
                <td style={{ padding: '10px' }}>{alert.date}</td>
                <td style={{ padding: '10px' }}>{alert.time}</td>
                <td style={{ padding: '10px' }}>{alert.meter_id}</td>
                <td style={{ padding: '10px' }}>{alert.parameter_name}</td>
                <td style={{ padding: '10px' }}>{alert.min_value}</td>
                <td style={{ padding: '10px' }}>{alert.max_value}</td>
                <td style={{ padding: '10px' }}>{alert.actual_value}</td>
              </tr>
            ))}
            {pageData.length === 0 && (
              <tr>
                <td colSpan="8" style={{ padding: '10px', textAlign: 'center' }}>
                  No data available
                </td>
              </tr>
            )}
    </tbody>
  </table>
  <div style={{ textAlign: 'center', marginTop: '20px' }}>
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
    style={{
      margin: '0 5px',
      padding: '5px 10px',
      background: '#007acc',
      color: currentPage === 1 ? '#9e9e9e' : '#ffffff',
      border: 'none',
      borderRadius: '25px',
      cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
      fontSize: '12px',
    }}
  >
    Previous
  </button>

  {currentPage > 3 && (
    <button
      onClick={() => handlePageChange(1)}
      style={{
        margin: '0 5px',
        padding: '5px 10px',
        background: currentPage === 1 ? '#007acc' : '#e0e0e0',
        color: currentPage === 1 ? '#ffffff' : '#333333',
        border: '1px solid #ddd',
        borderRadius: '25px',
        cursor: 'pointer',
        fontSize: '12px',
      }}
    >
      1
    </button>
  )}

  {currentPage > 3 && <span style={{ margin: '0 5px', fontSize: '14px', color: '#333' }}>......</span>}
  {Array.from({ length: totalPages }, (_, i) => {
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    if (i + 1 >= startPage && i + 1 <= endPage) {
      return (
        <button
          key={i}
          onClick={() => handlePageChange(i + 1)}
          style={{
            margin: '0 5px',
            padding: '5px 10px',
            background: currentPage === i + 1 ? '#007acc' : '#e0e0e0',
            color: currentPage === i + 1 ? '#ffffff' : '#333333',
            border: '1px solid #ddd',
            borderRadius: '25px',
            cursor: 'pointer',
            fontSize: '12px',
          }}
        >
          {i + 1}
        </button>
      );
    }
    return null;
  })}

  {currentPage < totalPages - 2 && (
    <span style={{ margin: '0 5px', fontSize: '14px', color: '#333' }}>......</span>
  )}

  {currentPage < totalPages - 2 && (
    <button
      onClick={() => handlePageChange(totalPages)}
      style={{
        margin: '0 5px',
        padding: '5px 10px',
        background: currentPage === totalPages ? '#007acc' : '#e0e0e0',
        color: currentPage === totalPages ? '#ffffff' : '#333333',
        border: '1px solid #ddd',
        borderRadius: '25px',
        cursor: 'pointer',
        fontSize: '12px',
      }}
    >
      {totalPages}
    </button>
  )}

  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
    style={{
      margin: '0 5px',
      padding: '5px 10px',
      background: '#007acc',
      color: currentPage === totalPages ? '#9e9e9e' : '#ffffff',
      border: 'none',
      borderRadius: '25px',
      cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
      fontSize: '12px',
    }}
  >
    Next
  </button>
</div>
</div>

  {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            ref={modalRef}
            style={{
              backgroundColor: 'white',
              padding: '25px',
              borderRadius: '10px',
              boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
              maxWidth: '600px',
              maxHeight: '70vh',
              overflowY: 'auto',
              width: '100%',
            }}
          >
            <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>
            Alert Details
            </h3>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
            <div style={{ flex: '1', marginRight: '10px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Meter ID
                  </label>
                  <div
                    style={{
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    {alertDetails.meterId || 'N/A'}
                  </div>
                </div>
              </div>
              <div style={{ flex: '1', marginLeft: '10px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Parameter Name
                  </label>
                  <div
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    {alertDetails.parameterName || 'N/A'}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
              <div style={{ flex: '1', marginRight: '10px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Min Value
                  </label>
                  <div
                    style={{
                      padding: '10px',
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    {alertDetails.minValue || 'N/A'}
                  </div>
                </div>
              </div>
              <div style={{ flex: '1', marginLeft: '10px' }}>
                <div style={{ marginBottom: '15px' }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Max Value
                  </label>
                  <div
                    style={{
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                    }}
                  >
                    {alertDetails.maxValue || 'N/A'}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ width: '48%', marginRight: '4%' }}>
          <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
            User select
          </label>
          <div
            style={{
              width: '95%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '100px',
              overflowY: 'auto',
              backgroundColor: '#f9f9f9',
            }}
          >
        <select
          multiple
          style={{
            width: '100%', 
            height: '100%', 
            border: 'none', 
            outline: 'none',
            backgroundColor: 'inherit', 
          }}
        >
          
            <option value={alertDetails.user || 'N/A'}>
              {alertDetails.user || 'N/A'}
            </option>
        </select>
      </div>
    </div>

    {/* Message Field */}
    <div style={{ width: '48%' }}>
      <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
        Message
      </label>
      <div
        style={{
          width: '92%',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          minHeight: '100px',
          whiteSpace: 'pre-wrap',
        }}
      >
        {alertDetails.message || 'No message entered'}
      </div>
    </div>
  </div>



            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '15px' }}>
              <button
                style={{
                  backgroundColor: '#f44336',
                  color: 'white',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  width: '20%',
                }}
                onClick={handleClose}
              >
                Close
              </button>
              </div>
              </div>
              
</div>
  )}

</div>
);
};

export default AlertReports;