import { createStore } from 'redux';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SIDEBAR_SHOW':
      return { ...state, sidebarShow: action.payload };
    case 'SET_SIDEBAR_UNFOLDABLE':
      return { ...state, sidebarUnfoldable: action.payload };
    case 'RESET_SIDEBAR':
      return initialState;
    default:
      return state;
  }
};

const store = createStore(changeState);

export default store;
