import React, { useState, useEffect,useRef, useCallback } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header';

const Alerts = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [addsearchTerm, setAddSearchTerm] = useState('');
  const [editsearchTerm, setEditSearchTerm] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [alertsData, setAlertsData] = useState([]);
  const [parameters, setParameters] = useState({});
  const [meters, setMeters] = useState({});
  const [usersData, setUsersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedAlerts, setSelectedAlerts] = useState({});
  const [searchMeter, setSearchMeter] = useState('');
  const [filteredAlerts, setFilteredAlerts] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [newAlert, setNewAlert] = useState({
    parameterName: '',
    meterId: '',
    minValue: '',
    maxValue: '',
    users: [],
    emailNotification: false,
    message: ''
  });
  const [errorModalOpen, setErrorModalOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastAlert = currentPage * itemsPerPage;
  const indexOfFirstAlert = indexOfLastAlert - itemsPerPage;
  const currentAlerts = filteredAlerts.slice(indexOfFirstAlert, indexOfLastAlert);
  const totalPages = Math.ceil(filteredAlerts.length / itemsPerPage);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
   const[isDeleteModalOpen,setDeleteModalOpen] =useState(false);
  const [alertToEdit, setAlertToEdit] = useState(null);
  const [alertsToDelete, setAlertsToDelete] = useState([]);
  const [searchParameter, setSearchParameter] = useState('');
  const [openDropdown, setOpenDropdown] = useState(null);
   const deleteModalRef=useRef();

  const handleMeterDropdownToggle = () => {
    if (openDropdown === 'parameter') {
      setOpenDropdown(null);
    }

    setOpenDropdown(openDropdown === 'meter' ? null : 'meter');
  };
  
  const handleParameterDropdownToggle = () => {
    if (openDropdown === 'meter') {
      setOpenDropdown(null);
    }
  
    setOpenDropdown(openDropdown === 'parameter' ? null : 'parameter');
  };
  

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const handleEditAlert = (alert) => {
    setAlertToEdit(alert);
    setEditModalOpen(true);
  };

  const modalRef = useRef();
  const editModalRef = useRef();

  const fetchAlerts = async () => {
    try {
      const alertsResponse = await axios.get(`${BaseURL}alerts/alerts/`);
      setAlertsData(alertsResponse.data);
      setFilteredAlerts(alertsResponse.data);
  
      const parametersResponse = await axios.get(`${BaseURL}alerts/electrical-parameters/`);
      const parametersMap = parametersResponse.data.reduce((map, obj) => {
        map[obj.id] = obj.parameter;
        return map;
      }, {});
      setParameters(parametersMap);
  
      const metersResponse = await axios.get(`${BaseURL}meters/energymeters/`);
      const metersMap = metersResponse.data.reduce((map, obj) => {
        map[obj.id] = `${obj.meter_id} - ${obj.meter_name}`;
        return map;
      }, {});
      setMeters(metersMap);
  
      const token = sessionStorage.getItem('token');
      const usersResponse = await axios.get(`${BaseURL}Userauth/userdetail/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
  
      const usersMap = usersResponse.data.reduce((map, obj) => {
        if (obj.usermod && obj.usermod.username) {
          map[obj.user_id] = obj.usermod.username;
        } else {
          console.error('Missing usermod or username:', obj);
        }
        return map;
      }, {});
  
      setUsersData(usersMap);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchAlerts();
  }, []);
  
  

  const handleSearch = (term) => {
    if (term === '') {
      setFilteredAlerts(alertsData);
    } else {
      const filteredData = alertsData.filter(alert => {
        const parameterMatch = parameters[alert.parameter_name]?.toLowerCase().includes(term.toLowerCase());
        const meterMatch = meters[alert.meter_id]?.toLowerCase().includes(term.toLowerCase());
        const minValueMatch = alert.min_value.toString().includes(term);
        const maxValueMatch = alert.max_value.toString().includes(term);
        const usersMatch = alert.users && alert.users.length > 0
          ? alert.users.map(userId => usersData[userId] || 'Unknown').join(', ').toLowerCase().includes(term.toLowerCase())
          : false;
  
        return parameterMatch || meterMatch || minValueMatch || maxValueMatch || usersMatch;
      });
      setFilteredAlerts(filteredData);
    }
    setCurrentPage(1);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleAddAlert = async () => {
    try {
      const alertWithEmailAction = {
        min_value: newAlert.minValue,
          max_value: newAlert.maxValue,
          action: newAlert.emailNotification ? 'email' : 'none',
          message: newAlert.message,
          parameter_name: newAlert.parameterName,
          meter_id: newAlert.meterId,
          users: newAlert.users,
      };

      const response = await axios.post(`${BaseURL}alerts/alerts/`, alertWithEmailAction);
      // console.log('Alert Added:', response.data);
  
      setAlertsData((prevData) => [...prevData, response.data]);
      resetNewAlert();
      setModalOpen(false);
      setSuccessMessage('Alert added successfully!'); // Set success message
      setTimeout(() => setSuccessMessage(''), 3000); // Clear message after 3 seconds
      await fetchAlerts();
    } catch (error) {
      console.error('Error adding alert:', error);
      setErrorMessage(
        "An error occurred while adding the alert. Check whether the email toggle is selected. Please try again."
      );
      setErrorModalOpen(true);
    }
  };

  const closeerrorModal = () => {
    setErrorModalOpen(false);
  };

  const ErrorModal = ({ errorModalOpen, errorMessage, closeModal }) => (
    <div
      style={{
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        display: errorModalOpen ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          width: '300px',
          textAlign: 'center',
        }}
      >
        <h3 style={{ color: 'red', marginBottom: '15px' }}>Error</h3>
        <p style={{ marginBottom: '20px' }}>{errorMessage}</p>
        <button
          style={{
            padding: '10px 15px',
            backgroundColor: '#007acc',
            color: 'white',
            border: 'none',
            borderRadius: '8px',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );

  const resetNewAlert = useCallback(() => {
    setNewAlert({
      parameterName: '',
      meterId: '',
      minValue: '',
      maxValue: '',
      users: [],
      emailNotification: false,
      message: ''
    });
  }, []);

  
  const closeModal = useCallback(() => {
    setModalOpen(false);
    resetNewAlert(); 
    setAddSearchTerm('');
    setOpenDropdown(null);
  }, [resetNewAlert]); 


  const handleParameterChange = (event) => {
    const selectedParameterId = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      parameterName: selectedParameterId,
    }));
    setOpenDropdown(null);
  };

  const handleMeterChange = (event) => {
    const selectedMeterId = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      meterId: selectedMeterId,
    }));
    setOpenDropdown(null);
  };

  const handleMinValueChange = (event) => {
    const minValue = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      minValue: minValue,
    }));
  };

  const handleMaxValueChange = (event) => {
    const maxValue = event.target.value;
    setNewAlert((prevAlert) => ({
      ...prevAlert,
      maxValue: maxValue,
    }));
  };

  const toggleEmailNotification = () => {
    setNewAlert(prevState => ({
      ...prevState,
      emailNotification: !prevState.emailNotification,
    }));
  };

  const handleUserSelectChange = (e) => {
    const selectedUsers = Array.from(e.target.selectedOptions, option => option.value);
    setNewAlert(prevState => ({
      ...prevState,
      users: selectedUsers,
    }));
  };

  const handleRemoveUser = (userId) => {
    setNewAlert(prevState => ({
      ...prevState,
      users: prevState.users.filter(id => id !== userId),
    }));
  };

  const handleMessageChange = (event) => {
    setNewAlert({
      ...newAlert,
      message: event.target.value,
    });
  };

  const handleDeleteSelectedAlerts = () => {
    setDeleteModalOpen(true); 
  };

  const confirmDelete = async () => {
    try {
      await Promise.all(alertsToDelete.map(alertId => 
        axios.delete(`${BaseURL}/alerts/alerts/${alertId}/`)
      ));
      
      setAlertsData(prevData => prevData.filter(alert => !alertsToDelete.includes(alert.id.toString())));
      
      setSelectedAlerts({});
      setSuccessMessage('Selected alerts deleted successfully!');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
      await fetchAlerts();
    } catch (error) {
      console.error('Error deleting alerts:', error);
      alert("An error occurred while deleting alerts. Please try again.");
    } finally {
      setDeleteModalOpen(false); 
    }
  };
 
  const handleCheckboxChange = (alertId) => {
    setSelectedAlerts((prevSelected) => {
      const newSelected = { ...prevSelected, [alertId]: !prevSelected[alertId] };
      
      const newAlertsToDelete = Object.keys(newSelected).filter(id => newSelected[id]);
      setAlertsToDelete(newAlertsToDelete);
      
      return newSelected;
    });
  };
  
  const handleSelectAllCheckbox = (event) => {
    const isChecked = event.target.checked;
    const newSelectedAlerts = {};
    currentAlerts.forEach(alert => {
      newSelectedAlerts[alert.id] = isChecked; 
    });
    setSelectedAlerts(newSelectedAlerts);
  };

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal(); 
      }
    };
  
    document.addEventListener('mousedown', handleMouseDown);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [closeModal]);

  const handleEditModalClose = useCallback(() => {
    setEditModalOpen(false);
    setEditSearchTerm('');
    setAlertToEdit(null);
    setOpenDropdown(null);
  }, []);
  
  useEffect(() => {
    const handleMouseDown = (event) => {
        if (deleteModalRef.current && !deleteModalRef.current.contains(event.target)) {
            setDeleteModalOpen(false); 
        }
    };

    if (isDeleteModalOpen) {
        document.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
        document.removeEventListener('mousedown', handleMouseDown);
    };
}, [isDeleteModalOpen]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (editModalRef.current && !editModalRef.current.contains(event.target)) {
        handleEditModalClose(); 
      }
    };
  
    if (isEditModalOpen) {
      document.addEventListener('mousedown', handleMouseDown);
    }
  
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [isEditModalOpen, handleEditModalClose]);

  const handleMeterSearch = (event) => {
    setSearchMeter(event.target.value);
  };
  
  const filteredMeters = Object.keys(meters).filter((meterId) =>
    meters[meterId]?.toLowerCase().includes(searchMeter.toLowerCase())
  );

  const filteredParameters = Object.keys(parameters).filter((parameterId) =>
    parameters[parameterId].toLowerCase().includes(searchParameter.toLowerCase())
  );


  const handleParameterSearch = (event) => {
    setSearchParameter(event.target.value);
  };

  const handleAddUser = (userId) => {
    if (!newAlert.users.includes(userId)) {
      setNewAlert((prevAlert) => ({
        ...prevAlert,
        users: [...prevAlert.users, userId],
      }));
    }
  };

  const filteredUsers = Object.entries(usersData).filter(([userId, username]) =>
    username.toLowerCase().includes(addsearchTerm.toLowerCase())
  );

  const filterededitUsers = Object.entries(usersData).filter(([userId, username]) =>
    username.toLowerCase().includes(editsearchTerm.toLowerCase())
  );


  return (
    <div style={{ textAlign: 'center', width: '100%' }}>
      <div style={{
        backgroundColor: '#007acc',
        color: 'white',
        alignContent: 'center',
        fontSize: '14px',
        height: '40px'
      }}>
        <Header title="ALERTS" />
      </div>

      <div style={{
        border: '1px solid #ccc',
        padding: '10px',
        marginTop: '20px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        margin: '20px auto',
        maxWidth: '1000px',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '30%' }}>
            <input
              type="text"
              placeholder="Search"
              style={{
                padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '8px',
                flex: '1',
                marginRight: '10px',
              }}
              value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              handleSearch(e.target.value); 
            }}
            onKeyPress={handleEnterKeyPress}
          />
            <FontAwesomeIcon
              icon={faSearch}
              style={{ color: '#007acc', cursor: 'pointer' }}
              onClick={handleSearch}
            />
          </div>

          <div style={{ marginLeft: 'auto' }}>
        
            <button
              style={{
                padding: '10px 15px',
                marginRight: '10px',
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (alertsToDelete.length > 0) {
                  handleDeleteSelectedAlerts(); 
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ marginRight: '5px' }} />
              Delete
            </button>
            <button
              style={{
                padding: '10px 15px',
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
              onClick={() => setModalOpen(true)}
            >
              <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
              Add
            </button>
          </div>
        </div>
      </div>
      <div style={{ textAlign: 'center', margin: '20px 0' }}>
        {successMessage && (
          <div style={{
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#4CAF50', 
            color: 'white',
            margin: '10px auto',
            width: 'fit-content',
          }}>
            {successMessage}
          </div>
        )}
          </div>

      <div style={{ marginTop: '20px', padding: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', backgroundColor: 'white', maxWidth: '1000px', margin: '20px auto' }}>
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div>
            <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
              <thead style={{ backgroundColor: '#007acc', color: 'white' }}>
                <tr>
                  <th style={{ padding: '10px' }}>
                    <input type="checkbox"  onChange={handleSelectAllCheckbox} />
                  </th>
                  <th style={{ padding: '10px' }}>S.No</th>
                  <th style={{ padding: '10px' }}>Parameter Name</th>
                  <th style={{ padding: '10px' }}>Meter ID</th>
                  <th style={{ padding: '10px' }}>Min Value</th>
                  <th style={{ padding: '10px' }}>Max Value</th>
                  <th style={{ padding: '10px' }}>Send to User</th>
                  <th style={{ padding: '10px' }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentAlerts.length > 0 ? (
                  currentAlerts.map((alert, index) => (
                    <tr
                    key={`${alert.id}-${index}`}
                      style={{
                        backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#ffffff',
                      }}
                    >
                      <td style={{ padding: '10px' }}>
                        <input
                          type="checkbox"
                          checked={!!selectedAlerts[alert.id]}
                          onChange={() => handleCheckboxChange(alert.id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </td>
                      <td style={{ padding: '10px' }}>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                      <td style={{ padding: '10px' }}>{parameters[alert.parameter_name]}</td>
                      <td style={{ padding: '10px' }}> {meters[alert.meter_id]?.split(' - ')[0]}</td>
                      <td style={{ padding: '10px' }}>{alert.min_value}</td>
                      <td style={{ padding: '10px' }}>{alert.max_value}</td>
                      <td style={{ padding: '10px' }}>
                        {alert.users && alert.users.length > 0
                          ? alert.users.map((userId) => usersData[userId] || 'Unknown').join(', ')
                          : 'No users'}
                      </td>
                      <td
                        style={{ padding: "5px", color: "blue", cursor: "pointer" }}
                        onClick={() => handleEditAlert(alert)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                       </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" style={{ padding: '10px', textAlign: 'center', backgroundColor: '#f2f2f2' }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                style={{
                  padding: '5px 10px',
                  background: currentPage === 1 ? '#007acc' : '#007acc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '25px',
                  margin: '0 5px',
                  cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
                  transition: 'all 0.3s ease',
                  fontSize: '12px',
                }}
              >
                Previous
              </button>
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageClick(index + 1)}
                  style={{
                    margin: '0 8px',
                    padding: '5px 10px',
                    background: currentPage === index + 1 ? '#007acc' : '#f7f7f7',
                    color: currentPage === index + 1 ? '#ffffff' : '#333333',
                    border: currentPage === index + 1 ? 'none' : '1px solid #ddd',
                    borderRadius: '25px',
                    cursor: 'pointer',
                    boxShadow: currentPage === index + 1 ? '0px 4px 8px rgba(0, 0, 0, 0.3)' : 'none',
                    fontSize: '12px',
                    transition: 'all 0.3s ease',
                  }}
                  onMouseEnter={(e) => {
                    if (currentPage !== index + 1) e.target.style.background = 'linear-gradient(to right, #eeeeee, #f5f5f5)';
                  }}
                  onMouseLeave={(e) => {
                    if (currentPage !== index + 1) e.target.style.background = '#f7f7f7';
                  }}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                style={{
                  padding: '5px 10px',
                  background: currentPage === totalPages ? '#007acc' : '#007acc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '25px',
                  margin: '0 5px',
                  cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
                  transition: 'all 0.3s ease',
                  fontSize: '12px',
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>
      {isModalOpen && (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
         ref={modalRef} 
         style={{
            backgroundColor: 'white',
            padding: '25px',
            borderRadius: '10px',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
            maxWidth: '600px',
            maxHeight: '70vh',
            overflowY: 'auto',
            width: '100%',
          }}
        >
          <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>
            Add New Alerts
          </h3>

          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
          <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <label
                style={{
                  marginBottom: '8px',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                Meter ID
              </label>
              <div
                style={{
                  width: '95%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  position: 'relative',
                }}
              >
                <div
                 onClick={handleMeterDropdownToggle} 
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize:'12px',
                  }}
                >
                  <span>{newAlert.meterId ? meters[newAlert.meterId] : 'Select Meter'}</span>
                  <span style={{ fontSize: '13px' }}><i class="fa-solid fa-angle-down"></i></span>
                </div>

                {openDropdown === 'meter' && ( 
                  <div
                    style={{
                      position: 'absolute',
                      top: '110%',
                      left: 0,
                      right: 0,
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      zIndex: 10,
                      maxHeight: '330px',
                      overflowY: 'auto',
                      border: '1px solid #000',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Meter ID"
                        value={searchMeter}
                        onChange={handleMeterSearch}
                        style={{
                          width: '95%',
                          padding: '8px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      />
                    </div>
                    {filteredMeters.length > 0 ? (
                      filteredMeters.map((meterId) => (
                        <div
                          key={meterId}
                          onClick={() => handleMeterChange({ target: { value: meterId } })}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            textAlign: 'left',
                            fontSize: '12px',
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#006ec5';
                            e.target.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = 'black';
                          }}
                        >
                          {meters[meterId]}
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: '10px' }}>No results found</div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <label
                style={{
                  marginBottom: '8px',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                Parameter Name
              </label>
              <div
                style={{
                  width: '95%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  position: 'relative',
                }}
              >
                <div
                  onClick={handleParameterDropdownToggle} 
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize:'12px',
                  }}
                >
                  <span>{newAlert.parameterName ?parameters[newAlert.parameterName] : 'Select Parameter'}</span>
                  <span style={{ fontSize: '13px' }}><i class="fa-solid fa-angle-down"></i></span>
                </div>

                {openDropdown === 'parameter' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '110%',
                      left: 0,
                      right: 0,
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      zIndex: 10,
                      maxHeight: '330px',
                      overflowY: 'auto',
                      border: '1px solid #000',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Parameter"
                        value={searchParameter}
                        onChange={handleParameterSearch}
                        style={{
                          width: '95%',
                          padding: '8px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      />
                    </div>
                    {filteredParameters.length > 0 ? (
                    filteredParameters.map((parameterId) => (
                      <div
                        key={parameterId}
                        onClick={() => {
                          handleParameterChange({ target: { value: parameterId } });
                        }}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            textAlign: 'left',
                            fontSize: '12px',
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#006ec5';
                            e.target.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = 'black';
                          }}
                        >
                           {parameters[parameterId]}
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: '10px' }}>No results found</div>
                    )}
                  </div>
                )}
              </div>
            </div>  
          </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
            <div style={{ flex: 1 }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Min Value
                  </label>
                  <input
                    type="number"
                    placeholder="Min Value"
                    value={newAlert.minValue}
                    onChange={handleMinValueChange}
                    style={{
                      width: '95%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>

                <div style={{ flex: 1 }}>
                <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Max Value
                  </label>
                  <input
                    type="number"
                    placeholder="Max Value"
                    value={newAlert.maxValue}
                    onChange={handleMaxValueChange}
                    style={{
                      width: '95%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>
              </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label
                style={{
                  marginRight: '10px',
                  fontWeight: 'bold',
                }}
              >
                Email:
              </label>
              <button
                style={{
                  width: '40px',
                  height: '25px',
                  backgroundColor: newAlert.emailNotification ? 'green' : 'red',
                  border: 'none',
                  borderRadius: '15px',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: newAlert.emailNotification ? 'flex-end' : 'flex-start',
                  padding: '2px',
                  marginRight: '10px',
                }}
                aria-label="Toggle Email"
                onClick={toggleEmailNotification}
              >
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                  }}
                ></div>
              </button>
              <p style={{ fontSize: '12px', color: '#555', margin: 0 }}>
                *Toggle to enable or disable email notifications.
              </p>
            </div>
          </div>
          <div style={{ display: 'flex', marginBottom: '20px' }}>
            <div style={{ width: '48%', marginRight: '4%' }}>
              <label style={{ marginBottom: '8px', display: 'flex', fontWeight: 'bold' }}>
                User Select
              </label>
              <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                backgroundColor: '#f9f9f9',
                height: '150px',
                overflowY: 'auto',
                position: 'relative',
              }}
            >
              <input
                type="text"
                value={addsearchTerm}
                onChange={(e) => setAddSearchTerm(e.target.value)}
                placeholder="Search users..."
                style={{
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  marginBottom: '10px',
                  width: '90%',
                }}
              />

              <div>
                {filteredUsers.map(([userId, username]) => {
                  const isUserAdded = newAlert.users.includes(userId);
                  return (
                    <div
                      key={userId}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        backgroundColor: '#e0e0e0',
                        alignItems: 'center',
                        padding: '5px',
                        borderRadius: '5px',
                        marginBottom: '8px',
                        fontSize: '12px',
                      }}
                    >
                      <span style={{ color: isUserAdded ? 'gray' : 'black' }}>{username}</span>
                      <button
                        onClick={() => handleAddUser(userId)}
                        style={{
                          backgroundColor: isUserAdded ? 'gray' : '#4CAF50',
                          color: 'white',
                          border: 'none',
                          padding: '5px 10px',
                          borderRadius: '5px',
                          cursor: isUserAdded ? 'not-allowed' : 'pointer',
                          fontSize: '10px',
                          marginLeft: '10px',
                        }}
                        disabled={isUserAdded}
                      >
                        {isUserAdded ? 'Added' : 'Add'}
                      </button>
                    </div>
                  );
                })}
              </div>
              </div>
            </div>
            <div style={{ width: '48%' }}>
              <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                Added Users
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  backgroundColor: '#f9f9f9',
                  height: '150px',
                  overflowY: 'auto',
                }}
              >
                {newAlert.users.map(userId => (
                  <div
                    key={userId}
                    style={{
                      backgroundColor: '#e0e0e0',
                      marginBottom: '5px',
                      padding: '2px',
                      borderRadius: '3px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      fontSize: '12px'
                    }}
                  >
                    <span>{usersData[userId]}</span>
                    <button
                      onClick={() => handleRemoveUser(userId)}
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        padding: '5px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '10px'
                      }}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: '20px' }}>
            <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
              Message
            </label>
            <textarea
              value={newAlert.message}
              onChange={handleMessageChange}
              placeholder="Enter your message"
              style={{
                width: '50%',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                resize: 'none',
                minHeight: '100px',
              }}
            />
          </div>

          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '15px' }}>
            <button
              style={{
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '20%',
              }}
              onClick={handleAddAlert}
            >
              Add
            </button>
            <button
              style={{
                backgroundColor: '#f44336',
                color: 'white',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
                width: '20%',
              }}
              onClick={() => {
                closeModal(); 
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    )}
{isEditModalOpen && alertToEdit && (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
     ref={editModalRef} 
      style={{
        backgroundColor: 'white',
        padding: '25px',
        borderRadius: '10px',
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
        maxWidth: '600px',
        maxHeight: '70vh',
        overflowY: 'auto',
        width: '100%',
      }}
    >
      <h3 style={{ marginBottom: '20px', textAlign: 'center', color: '#333' }}>
        Edit Alert
      </h3>

      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '-10px' }}>
          <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
            <div style={{ flex: 1 }}>
              <label
                style={{
                  marginBottom: '8px',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                Meter ID
              </label>
              <div
                style={{
                  width: '95%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  position: 'relative',
                }}
              >
                <div
                 onClick={handleMeterDropdownToggle} 
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize:'12px',
                  }}
                >
                  <span>{alertToEdit.meter_id ? meters[alertToEdit.meter_id] : 'Select Meter'}</span>
                  <span style={{ fontSize: '13px' }}><i class="fa-solid fa-angle-down"></i></span>
                </div>

                {openDropdown === 'meter' && ( 
                  <div
                    style={{
                      position: 'absolute',
                      top: '110%',
                      left: 0,
                      right: 0,
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      zIndex: 10,
                      maxHeight: '330px',
                      overflowY: 'auto',
                      border: '1px solid #000',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Meter ID"
                        value={searchMeter}
                        onChange={handleMeterSearch}
                        style={{
                          width: '95%',
                          padding: '8px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      />
                    </div>
                    {filteredMeters.length > 0 ? (
                      filteredMeters.map((meterId) => (
                        <div
                          key={meterId}
                          onClick={() => {
                            
                            setAlertToEdit((prevState) => ({
                              ...prevState,
                              meter_id: meterId,
                            }));
                            setOpenDropdown(null); 
                          }}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            textAlign: 'left',
                            fontSize: '12px',
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#006ec5';
                            e.target.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = 'black';
                          }}
                        >
                          {meters[meterId]}
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: '10px' }}>No results found</div>
                    )}
                  </div>
                )}
              </div>
            </div>
 

            <div style={{ flex: 1 }}>
              <label
                style={{
                  marginBottom: '8px',
                  display: 'block',
                  fontWeight: 'bold',
                }}
              >
                Parameter Name
              </label>
              <div
                style={{
                  width: '95%',
                  padding: '8px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  position: 'relative',
                }}
              >
                <div
                  onClick={handleParameterDropdownToggle} 
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize:'12px',
                  }}
                >
                  <span>{alertToEdit.parameter_name ?parameters[alertToEdit.parameter_name] : 'Select Parameter'}</span>
                  <span style={{ fontSize: '13px' }}><i class="fa-solid fa-angle-down"></i></span>
                </div>

                {openDropdown === 'parameter' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '110%',
                      left: 0,
                      right: 0,
                      backgroundColor: 'white',
                      borderRadius: '5px',
                      boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                      zIndex: 10,
                      maxHeight: '330px',
                      overflowY: 'auto',
                      border: '1px solid #000',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Search Parameter"
                        value={searchParameter}
                        onChange={handleParameterSearch}
                        style={{
                          width: '95%',
                          padding: '8px',
                          borderRadius: '5px',
                          border: '1px solid #ccc',
                        }}
                      />
                    </div>
                    {filteredParameters.length > 0 ? (
                    filteredParameters.map((parameterId) => (
                      <div
                        key={parameterId}
                        onClick={() => {
                          setAlertToEdit((prevState) => ({
                            ...prevState,
                            parameter_name: parameterId,
                          }));
                          setOpenDropdown(null); 
                        }}
                          style={{
                            padding: '5px',
                            cursor: 'pointer',
                            textAlign: 'left',
                            fontSize: '12px',
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = '#006ec5';
                            e.target.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = 'transparent';
                            e.target.style.color = 'black';
                          }}
                        >
                           {parameters[parameterId]}
                        </div>
                      ))
                    ) : (
                      <div style={{ padding: '10px' }}>No results found</div>
                    )}
                  </div>
                )}
              </div>
            </div>  
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between',  marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
            <div style={{ flex: 1 }}>
                  <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Min Value
                  </label>
                  <input
                    type="number"
                    placeholder="Min Value"
                    value={alertToEdit.min_value}
                    onChange={(e) => setAlertToEdit({ ...alertToEdit, min_value: e.target.value })}
                    style={{
                      width: '95%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>

                <div style={{ flex: 1 }}>
                <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
                    Max Value
                  </label>
                  <input
                    type="number"
                    placeholder="Max Value"
                    value={alertToEdit.max_value}
                    onChange={(e) => setAlertToEdit({ ...alertToEdit, max_value: e.target.value })}
                    style={{
                      width: '95%',
                      padding: '10px',
                      borderRadius: '5px',
                      border: '1px solid #ccc',
                    }}
                  />
                </div>
              </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
              <label
                style={{
                  marginRight: '10px',
                  fontWeight: 'bold',
                }}
              >
                Email:
              </label>
              <button
                style={{
                  width: '40px',
                  height: '25px',
                  backgroundColor: alertToEdit.action ? 'green' : 'red',
                  border: 'none',
                  borderRadius: '15px',
                  color: 'white',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: alertToEdit.action ? 'flex-end' : 'flex-start',
                  padding: '2px',
                  marginRight: '10px',
                }}
                aria-label="Toggle Email"
                onClick={() => setAlertToEdit({ ...alertToEdit, action: alertToEdit.action === 'email' ? '' : 'email' })}
              >
                <div
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: 'white',
                  }}
                ></div>
              </button>
              <p style={{ fontSize: '12px', color: '#555', margin: 0 }}>
                *Toggle to enable or disable email notifications.
              </p>
            </div>
          </div>

      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ width: '48%', marginRight: '4%' }}>
          <label style={{ marginBottom: '8px', display: 'flex', fontWeight: 'bold' }}>
            User Select
          </label>
          <div style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                backgroundColor: '#f9f9f9',
                height: '150px',
                overflowY: 'auto',
              }}>
            <input
              type="text"
              value={editsearchTerm}
              onChange={(e) => setEditSearchTerm(e.target.value)}
              placeholder="Search users..."
              style={{
                padding: '8px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                marginBottom: '10px',
                width: '90%',
              }}
            />
            <div>
              {filterededitUsers.map(([userId, username]) => {
                const isAdded = alertToEdit.users.includes(parseInt(userId));
                return (
                  <div
                    key={userId}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '8px',
                      backgroundColor: '#e0e0e0',
                      padding: '5px',
                      borderRadius: '5px',
                      fontSize: '12px',
                    }}
                  >
                    <span style={{ color: isAdded ? 'gray' : 'black' }}>{username}</span>
                    <button
                      onClick={() => {
                        if (!isAdded) {
                          setAlertToEdit({
                            ...alertToEdit,
                            users: [...alertToEdit.users, parseInt(userId)],
                          });
                        }
                      }}
                      style={{
                        backgroundColor: isAdded ? 'gray' : '#4CAF50',
                        color: 'white',
                        border: 'none',
                        padding: '5px 10px',
                        borderRadius: '5px',
                        cursor: isAdded ? 'not-allowed' : 'pointer',
                        fontSize: '10px',
                        marginLeft: '10px',
                      }}
                      disabled={isAdded}
                    >
                      {isAdded ? 'Added' : 'Add'}
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div style={{ width: '48%' }}>
          <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
            Added Users
          </label>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              backgroundColor: '#f9f9f9',
              height: '150px',
              overflowY: 'auto',
            }}
          >
            {alertToEdit.users.map(userId => (
              <div
                key={userId}
                style={{
                  backgroundColor: '#e0e0e0',
                  marginBottom: '5px',
                  padding: '2px',
                  borderRadius: '3px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  fontSize: '12px'
                }}
              >
                <span>{usersData[userId]}</span>
                <button
                  onClick={() => setAlertToEdit({ ...alertToEdit, users: alertToEdit.users.filter(id => id !== userId) })}
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: 'none',
                    padding: '5px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontSize: '10px'
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginBottom: '8px', display: 'block', fontWeight: 'bold' }}>
          Message
        </label>
        < textarea
          value={alertToEdit.message}
          onChange={(e) => setAlertToEdit({ ...alertToEdit, message: e.target.value })}
          placeholder="Enter your message"
          style={{
            width: '50%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            resize: 'none',
            minHeight: '100px',
          }}
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '15px' }}>
        <button
          style={{
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '20%',
          }}
          onClick={async () => {
            try {
              await axios.put(`${BaseURL}/alerts/alerts/${alertToEdit.id}/`, alertToEdit);
              setAlertsData(prevData => prevData.map(alert => alert.id === alertToEdit.id ? alertToEdit : alert));
              setEditModalOpen(false);
              setSuccessMessage('Alert updated successfully!'); 
              setTimeout(() => setSuccessMessage(''), 3000); 
              await fetchAlerts();
            } catch (error) {
              console.error('Error updating alert:', error);
            }
          }}
        >
          Save
        </button>
        <button
          style={{
            backgroundColor: '#f44336',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            width: '20%',
          }}
          onClick={handleEditModalClose}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
 {isDeleteModalOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            ref={deleteModalRef}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "10px",
              width: "400px",
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          >
            <h3>Confirm Deletion</h3>
            <p>Are you sure you want to delete the selected  Alerts?</p>
            <div
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
              }}
            >
               <div style={{ display: "flex", gap: "10px" }}>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#4CAF50",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={confirmDelete} 
              >
                Yes, Delete
              </button>
              <button
                style={{
                  padding: "10px 15px",
                  color: "white",
                  backgroundColor:"#f44336",
                  border: "none",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteModalOpen(false)}
              >
                Cancel
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
<ErrorModal
    errorModalOpen={errorModalOpen}
    errorMessage={errorMessage}
    closeModal={closeerrorModal}
  />
</div>
  );
};

export default Alerts;