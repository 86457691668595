import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseURL from '../assets/contants/BaseURL';
import Header from '../components/header'; 

const Alertssettings = () => {
    const [smtpSettings, setSmtpSettings] = useState({
        id: null,
        host: '',
        port: '',
        username: '',
        password: '',
        status: false,
    });
    const [fromEmail, setFromEmail] = useState(''); 
    const [toEmail, setToEmail] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailSubject, setEmailSubject] = useState('');
    const [emailBody, setEmailBody] = useState(''); 
 

    useEffect(() => {
        const fetchSmtpSettings = async () => {
            try {
                const response = await axios.get(`${BaseURL}alerts/smtp/`);
                const data = response.data[0];
                setSmtpSettings({
                    id: data.id,
                    host: data.smtp_host,
                    port: data.smtp_port,
                    username: data.smtp_username,
                    password: data.smtp_password,
                    status: data.use_ssl,
                });
                 setFromEmail(data.from_email);
            } catch (error) {
                alert('Error fetching SMTP settings');
            }
        };

        fetchSmtpSettings();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSmtpSettings(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleToggleChange = () => {
        setSmtpSettings(prevState => ({
            ...prevState,
            status: !prevState.status
        }));
    };


      const handleFromEmailChange = (e) => {
        setFromEmail(e.target.value);
    };

    const handleToEmailChange = (e) => {
        setToEmail(e.target.value);
    };

    const handleUpdate = () => {
        
        const updateData = {
            smtp_host: smtpSettings.host,
            smtp_port: smtpSettings.port,
            smtp_username: smtpSettings.username,
            smtp_password: smtpSettings.password,
            from_email: fromEmail,
            use_ssl: smtpSettings.status, 
            use_tls: false 
        };
    
        console.log(updateData); 
    
        axios.put(`${BaseURL}alerts/smtp/${smtpSettings.id}/`, updateData)
            .then(response => {
                alert('Settings updated successfully!');
            })
            .catch(error => {
                console.error('Error updating settings:', error.response ? error.response.data : error);
                alert('Error updating settings');
            });
    };


    const handleTestEmail = () => {
      const testEmailData = {
          email_id: toEmail 
      };
  
          axios.post(`${BaseURL}/alerts/test-email/`, testEmailData)
          .then(response => {
              
              setEmailSubject("Test Email");
              setEmailBody("This is a test email sent from the system.");
              setIsModalOpen(true); 
          })
          .catch(error => {
              console.error('Error sending test email:', error.response ? error.response.data : error);
              setEmailSubject("Error");
              setEmailBody("Error sending test email");
              setIsModalOpen(true); 
          });
  };

  const closeModal = () => {
      setIsModalOpen(false); 
  };
    return (
        <div style={{ textAlign: 'center', width: '100%' }}>
            <div style={{
                color: 'white',
                alignContent: 'center',
                fontSize: '14px',
                height: '40px',
                marginTop: '50px'
            }}>
                <Header title="ALERTS SETTINGS" />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    maxHeight: '100vh', 
                  }}
                >
                  <div
                    style={{
                      backgroundColor: '#fff',
                      borderRadius: '12px',
                      boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                      padding: '30px',
                      width: '70%', 
                      maxWidth: '500px',
                      maxHeight: '90vh', 
                      // overflowY: 'auto',
                      marginBottom: '30px',
                    }}
                  >
                    <h3
                      style={{
                        marginBottom: '5px',
                        textAlign: 'center',
                        fontSize: '1.5rem',
                        color: '#333',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        marginTop:'5px'
                      }}
                    >
                      SMTP Settings
                    </h3>

                    <div
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '20px',
                        marginBottom: '10px',
                      }}
                    >
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '5px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          SMTP Host
                        </label>
                        <input
                          type="text"
                          name="host"
                          value={smtpSettings.host}
                          onChange={handleInputChange}
                          placeholder="Enter SMTP Host"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.8rem',
                          }}
                        />
                      </div>

                      
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '5px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          SMTP Port
                        </label>
                        <input
                          type="text"
                          name="port"
                          value={smtpSettings.port}
                          onChange={handleInputChange}
                          placeholder="Enter SMTP Port"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.8rem',
                          }}
                        />
                      </div>

                     
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '5px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          Username
                        </label>
                        <input
                          type="text"
                          name="username"
                          value={smtpSettings.username}
                          onChange={handleInputChange}
                          placeholder="Enter Username"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.8rem',
                          }}
                        />
                      </div>

                     
                      <div>
                        <label
                          style={{
                            fontWeight: 'bold',
                            display: 'block',
                            marginBottom: '5px',
                            fontSize: '0.9rem',
                            color: '#555',
                          }}
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          value={smtpSettings.password}
                          onChange={handleInputChange}
                          placeholder="Enter Password"
                          style={{
                            width: '80%',
                            padding: '12px',
                            borderRadius: '8px',
                            border: '1px solid #ccc',
                            boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                            fontSize: '0.8rem',
                          }}
                        />
                      </div>
                    </div>

                    
                    <div style={{ marginBottom: '10px' }}>
                      <label
                        style={{
                          fontWeight: 'bold',
                          display: 'block',
                          marginBottom: '5px',
                          fontSize: '0.9rem',
                          color: '#555',
                        }}
                      >
                        From Email
                      </label>
                      <input
                        type="text"
                        name="from_email"
                        value={fromEmail}
                        onChange={handleFromEmailChange}
                        placeholder="Enter From Email"
                        style={{
                          width: '60%',
                          padding: '12px',
                          borderRadius: '8px',
                          border: '1px solid #ccc',
                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                          fontSize: '0.8rem',
                        }}
                      />
                    </div>
                  <div style={{ marginBottom: '10px' }}>
                    <label
                      style={{
                        fontWeight: 'bold',
                        display: 'block',
                        marginBottom: '5px',
                        fontSize: '0.9rem',
                        color: '#555',
                      }}
                    >
                      To Email
                    </label>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <input
                        type="text"
                        name="to_email"
                        value={toEmail}
                        onChange={handleToEmailChange}
                        placeholder="Enter To Email"
                        style={{
                          width: '60%',
                          padding: '12px',
                          borderRadius: '8px',
                          border: '1px solid #ccc',
                          boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)',
                          fontSize: '0.8rem',
                          marginLeft:"85px"
                        }}
                      />
                      <button
                          onClick={handleTestEmail}
                        style={{
                          padding: '10px 15px',
                          borderRadius: '8px',
                          border: 'none',
                          backgroundColor: '#007bff',
                          color: '#fff',
                          width: '20%',
                          cursor: 'pointer',
                          fontSize: '0.9rem', 
                          fontWeight: 'bold',
                        }}
                      >
                        Test
                      </button>
                    </div>
                  </div>


                    <div style={{ marginBottom: '10px', textAlign: 'center' }}>
                      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <label
                          style={{
                            fontWeight: '600',
                            color: '#555',
                            fontSize: '14px',
                            marginRight: '10px',
                          }}
                        >
                          Status:
                        </label>
                        <input
                          type="checkbox"
                          id="status-toggle"
                          style={{ display: 'none' }}
                          checked={smtpSettings.status}
                          onChange={handleToggleChange}
                        />
                        <label
                          htmlFor="status-toggle"
                          style={{
                            width: '50px',
                            height: '24px',
                            backgroundColor: smtpSettings.status ? '#4caf50' : '#ccc',
                            borderRadius: '50px',
                            position: 'relative',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              top: '4px',
                              left: smtpSettings.status ? '26px' : '4px',
                              width: '16px',
                              height: '16px',
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              transition: '0.3s',
                            }}
                          />
                        </label>
                      </div>
                    </div>

                    <button
                      onClick={handleUpdate}
                      style={{
                        backgroundColor: '#007acc',
                        color: 'white',
                        padding: '12px 30px',
                        border: 'none',
                        borderRadius: '8px',
                        cursor: 'pointer',
                        width: '100%',
                        fontSize: '1rem',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              {isModalOpen && (
                <div style={modalOverlayStyle}>
                    <div style={modalStyle}>
                        <h2>{emailSubject}</h2>
                        <p>{emailBody}</p>
                        <button onClick={closeModal} style={buttonStyle}>Close</button>
                    </div>
                </div>
            )}
            </div>

    );
  };
  
  const modalOverlayStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
  };
  
  const modalStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      width: '400px',
      textAlign: 'center',
  };
  
  const buttonStyle = {
      padding: '10px 20px',
      borderRadius: '5px',
      border: 'none',
      backgroundColor: ' #f44336',
      color: 'white',
      cursor: 'pointer',
  };
  
  export default Alertssettings;