import React, { Suspense } from 'react';
import {Routes, Route,Navigate} from 'react-router-dom';
import DefaultLayout from './Layout/Defaultlayout';
import LoginForm from './pages/Login';
import Emsmain from './EMS/EmsMain';
import RegisterPage from './pages/Registerpage';
import VerificationPage from './pages/Verificationpage';
import Pendingverification from './pages/Pendingverificationpage';
import ResetTokenForm from './pages/Forgetpassword';
import Signinpage from './pages/Signinpage';
import { HashRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  return (
      <Suspense fallback={loading}>
        <HashRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgot" element={<ResetTokenForm/>}/>
          <Route path="/resetpassword" element={<Signinpage/>}/>
          <Route path="/register" element={<RegisterPage/>} />
          <Route path="/verification" element={<VerificationPage />} />
          <Route path="/pending-verification" element={<Pendingverification />} />
          
          <Route element={<ProtectedRoute />}>
            <Route path="/dashboard" element={<DefaultLayout><Emsmain /></DefaultLayout>} />
          </Route>
          <Route path="/*" element={<DefaultLayout />} />
        </Routes>
        </HashRouter>
      </Suspense>
  );
};

export default App;
