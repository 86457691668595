import './scss/Nav.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';

const navigation = [
  {
    name: 'Dashboard',
    icon: <i className="fas fa-tachometer-alt"></i>,
    submenu: [
      {
        name: 'Main Panel',
        path: '/EMS/dashboard',
        icon: <i className="fas fa-columns"></i>,
      },
      {
        name: 'Group Portal',
        path: '/EMS/group-portal',
        icon: <i className="fas fa-users-cog"></i>,
      },
    ],
  },
  {
    name: ' Meter Config',
    icon: <i className="fas fa-bolt"></i>,
    submenu: [
      {
        name: 'Meter List',
        path: '/EMS/energymeter',
        
      },
      {
        name: 'EMS Groups',
        path: '/EMS/emsgroups',
       
      },
      {
        name: 'Peak Timings',
        path: '/EMS/peaktimings',
        
      },
      { 
        name:'Energy Data',
        path:'/EMS/energydata'

      },
    ],
  },

  {
    name: 'EnPI',
    icon: <i className="fas fa-tools"></i>,
    submenu: [
      {
        name: 'Machine List',
        path: '/EMS/machine',
       
      },
      {
        name: 'Machine Groups',
        path: '/EMS/groups',
       
      },
      {
        name: 'Production Details',
        path: '/EMS/productiondetails',
        
      },
    ],
  },
  {
    name: 'Reports',
    icon: <i className="fas fa-chart-line"></i>,
    submenu: [
      {
        name: 'Meter Reports',
        path: '/EMS/reports/',
        
      },
      {
        name: 'Production Reports',
        path: '/EMS/productionreports',
        
      },
      {
        name: 'Alert Reports',
        path: '/EMS/alertreports',
        
      },
    ],
  },
  {
    name: 'Alerts',
    icon: <i className="fas fa-exclamation-circle"></i>,
    submenu: [
      {
        name: 'Alert List',
        path: '/EMS/alerts',
      
      },
      {
        name: 'Settings',
        path: '/EMS/alertssettings',
      
      },
    ],
  },
  

  {
    name: 'User List',
    path: '/EMS/usersgroups',
    icon: <i className="fas fa-user"></i>,
  },
  // {
  //   name: 'Settings',
  //   path: '/EMS/settings',
  //   icon: <i className="fas fa-gear"></i>,
  // },
  
];

export default navigation;
