import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BaseURL from '../assets/contants/BaseURL';
import '../scss/Resetform.scss';

function Signinpage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (newPassword !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    const token = sessionStorage.getItem('reset_token');

    if (!token) {
      alert('Token not found! Please log in again.');
      return;
    }

    const requestBody = {
      email,
      token,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
  
    try {
      const response = await fetch(`${BaseURL}Userauth/user-change-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.error || 'Reset failed');
        return;
      }
  
      navigate('/login'); 
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const navigateToSignIn = () => { 
    navigate('/login');
  };

  const containerStyle = {
    maxWidth: '800px',
    width:'500px',
    margin: '50px auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    boxShadow: '0 2px 15px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    backgroundColor: '#fff',
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#0073e6',
    marginBottom: '20px',
  };

  const errorStyle = {
    color: 'red',
    marginTop: '10px',
    fontSize: '14px',
  };

  const inputStyle = {
    width: '70%',
    padding: '12px 12px 12px 35px',
    margin: '10px 0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    outline: 'none',
    position: 'relative',
  };

  const submitBtnStyle = {
    width: '50%',
    padding: '12px',
    backgroundColor: '#f0c14b',
    color: '#111',
    border: '1px solid #a88734',
    borderRadius: '50px',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    marginTop: '20px',
  };

  const switchScreenStyle = {
    marginTop: '20px',
    fontSize: '14px',
    color: '#555',
  };

  const signInTextStyle = {
    color: '#0073e6',
    cursor: 'pointer',
    textDecoration: 'underline',
  };

  return (
    <div className="live-background">
      <div style={containerStyle}>
        <h2 style={headerStyle}>Reset Password</h2>

        {errorMessage && <div style={errorStyle}>{errorMessage}</div>}

        <form onSubmit={handleSubmit}>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="email"
              placeholder="Enter your email"
              style={inputStyle}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <i className="fa fa-user" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="text"
              placeholder="Enter your name"
              style={inputStyle}
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <i className="fa fa-user" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="password"
              placeholder="Enter new password"
              style={inputStyle}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <i className="fa fa-lock" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>
          <div style={{ position: 'relative', width: '100%' }}>
            <input
              type="password"
              placeholder="Confirm new password"
              style={inputStyle}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <i className="fa fa-lock" style={{ position: 'absolute', top: '50%', left: '60px', transform: 'translateY(-50%)' }}></i>
          </div>

          <button type="button" style={submitBtnStyle} onClick={handleSubmit}>Reset</button>

        </form>

        <div style={switchScreenStyle}>
          <span onClick={navigateToSignIn} style={signInTextStyle}>Sign In</span>
        </div>
      </div>
    </div>
  );
}

export default Signinpage;
