import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const token = sessionStorage.getItem('token');
    const isAuthenticated = Boolean(token);
  
    if (!isAuthenticated) {
      return <Navigate to="/login" />;
    }
  
    return children;
  };

export default ProtectedRoute;
